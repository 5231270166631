import { Contacts } from "./Contacts";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import React, { useEffect, useState } from "react";
import {
  createContact,
  getContact,
  getContacts,
  resetContacts,
  resetSelectedContact,
  updateContact,
} from "./store/actions";
import { PagedRequest } from "../../common/models";
import {
  getCitiesDirectory,
  getContactTypesDirectory,
  getGendersDirectory,
} from "../../store/actions/directories";

export const ContactsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedContactId, setSelectedContactId] = useState<number | null>(
    null
  );
  const pageSize: number = 5;

  // Queries
  const contacts = useSelector((state: RootStore) => state.contacts.contacts);
  const genders = useSelector(
    (state: RootStore) => state.directories.genders.genders
  );
  const contactTypes = useSelector(
    (state: RootStore) => state.directories.contactTypes.contactTypes
  );
  const cities = useSelector(
    (state: RootStore) => state.directories.cities.cities
  );
  const selectedContact = useSelector(
    (state: RootStore) => state.contacts.selectedContact
  );
  const contactLoading = useSelector(
    (state: RootStore) => state.contacts.loading
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetContacts = (payload: PagedRequest) =>
    dispatch(getContacts(payload));
  const onGetContact = (payload: number) => dispatch(getContact(payload));
  const onGetGenders = () => dispatch(getGendersDirectory());
  const onGetContactTypes = () => dispatch(getContactTypesDirectory());
  const onGetCities = () => dispatch(getCitiesDirectory());
  const onUpdateContact = (contact: any) => {
    dispatch(
      updateContact({
        contact,
        request: { offset: offset, take: pageSize },
      })
    );
  };

  const onCreateContact = (contact: any) =>
    dispatch(
      createContact({
        contact,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetContact = () => dispatch(resetSelectedContact());
  const onResetContacts = () => dispatch(resetContacts());

  useEffect(() => {
    if (selectedContactId) {
      onResetContact();
      onGetContact(selectedContactId);
    }
  }, [selectedContactId]);

  useEffect(() => {
    onGetContactTypes();
    onGetGenders();
    onGetCities();
    // return () => {
    //   onResetContacts();
    // };
  }, []);

  const offsetChanged = (offset: number) => {
    onResetContacts();
    setOffset(offset);
    onGetContacts({ offset: offset, take: pageSize });
  };

  return (
    <Contacts
      pagedContacts={contacts}
      selectedContact={selectedContact}
      contactLoading={contactLoading}
      createContact={onCreateContact}
      updateContact={onUpdateContact}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      setSelectedContactId={setSelectedContactId}
      currentPage={currentPage}
      genders={genders}
      cities={cities}
      contactTypes={contactTypes}
    />
  );
};
