import { DirectoryItem } from "../types";

export const CURRENCY = {
  USD: 1,
  EUR: 2,
  UAH: 3,
  RUB: 4,
};

export const CURRENCY_OPTIONS: DirectoryItem[] = [
  { label: "USD", value: 1 },
  { label: "EUR", value: 2 },
  { label: "UAH", value: 3 },
  { label: "RUB", value: 4 },
];
