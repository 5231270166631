import React from "react";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { EmployeePayment } from "../types";
import { Paged } from "../../../../../../common/models";
import { SharedPagination } from "../../../../../../common/components";
import {
  formatCurrencyFixed,
  formatDate,
} from "../../../../../../common/utils";
import { extractLabelByValue } from "../../../../../../common/utils/const.utils";
import { EMPLOYEE_PAYMENT_TYPES_OPTIONS } from "../const/employee-payments.conts";

interface IEmployeePaymentsListProps {
  pagedEmployeePayments: Paged<EmployeePayment>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const EmployeePaymentsTable: React.FC<IEmployeePaymentsListProps> = ({
  pagedEmployeePayments,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>Month</Th>
            <Th>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pagedEmployeePayments.entities.map((employeePayment, index) => {
            return (
              <Tr key={index}>
                <Td>{formatDate(employeePayment.date)}</Td>
                <Td>
                  {extractLabelByValue(
                    EMPLOYEE_PAYMENT_TYPES_OPTIONS,
                    employeePayment.paymentType
                  )}
                </Td>
                <Td>{employeePayment.month}</Td>
                <Td>
                  {formatCurrencyFixed(employeePayment.amount)}{" "}
                  {employeePayment.currencySymbol}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedEmployeePayments.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
