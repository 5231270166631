import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getTeamsDirectory,
  getTeamsDirectoryError,
  getTeamsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/teams`;

function* workerGetTeams(action: ReturnType<typeof getTeamsDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getTeamsDirectorySuccess(result.value.entities));
    } else {
      yield put(getTeamsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(getTeamsDirectoryError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchTeamsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getTeamsDirectory.type, workerGetTeams);
}
