import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Paged } from "../../../common/models";
import { Contract, ContractCreate, ContractUpdate } from "./types";
import {
  ContractEditModal,
  ContractsTable,
  ContractCreateModal,
} from "./components";
import { DirectoryItem } from "../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IContractsProps {
  pagedContracts: Paged<Contract>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createContract: (contract: ContractCreate) => void;
  updateContract: (contract: ContractUpdate) => void;
  contractLoading: boolean;
  partners: DirectoryItem[];
}

export const Contracts: React.FC<IContractsProps> = ({
  pagedContracts,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createContract,
  updateContract,
  contractLoading,
  partners,
}) => {
  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    null
  );
  const {
    isOpen: isOpenContractEdit,
    onOpen: onOpenContractEdit,
    onClose: onCloseContractEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenContractCreate,
    onOpen: onOpenContractCreate,
    onClose: onCloseContractCreate,
  } = useDisclosure();

  const handleOpenEdit = (contract: Contract) => {
    setSelectedContract(contract);
    onOpenContractEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Contracts</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenContractCreate()}>
            <CFaPlus mr={2} /> Add contract
          </Button>
        </Box>
      </Flex>

      <ContractsTable
        pagedContracts={pagedContracts}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <ContractCreateModal
        partners={partners}
        handleCreate={createContract}
        isOpen={isOpenContractCreate}
        onOpen={onOpenContractCreate}
        onClose={onCloseContractCreate}
      />

      <ContractEditModal
        partners={partners}
        handleUpdate={updateContract}
        selectedContract={selectedContract}
        isOpen={isOpenContractEdit}
        onOpen={onOpenContractEdit}
        onClose={onCloseContractEdit}
      />
    </>
  );
};
