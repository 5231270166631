import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getJobsDirectory = createAction("DIRECTORIES/GET_JOBS");

export const getJobsDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_JOBS_SUCCESS"
);

export const getJobsDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_JOBS_ERROR"
);
