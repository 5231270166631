import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getPartnerTypesDirectory = createAction(
  "DIRECTORIES/GET_PARTNER_TYPES"
);

export const getPartnerTypesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_PARTNER_TYPES_SUCCESS"
);

export const getPartnerTypesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_PARTNER_TYPES_ERROR"
);
