import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getEmployeesDirectory,
  getEmployeesDirectoryError,
  getEmployeesDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  employees: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  employees: [],
  loaded: false,
};

const employeesDirectorySlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeesDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeesDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.employees = action.payload;
      })
      .addCase(getEmployeesDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default employeesDirectorySlice.reducer;
