import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  getPayoneerReport,
  getPayoneerReportError,
  getPayoneerReportSuccess,
} from "./actions";
import { ApiResponse } from "../../../../common/models/common";
import { PayoneerReport } from "../types";

const apiUrl = `/api/payoneer/report`;

function* workerGetPayoneerReport(
  action: ReturnType<typeof getPayoneerReport>
) {
  try {
    const result: ApiResponse<PayoneerReport> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getPayoneerReportSuccess(result.value));
    } else {
      yield put(getPayoneerReportError(result.errors));
    }
  } catch (e) {
    yield put(getPayoneerReportError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchPayoneerReportSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getPayoneerReport.type, workerGetPayoneerReport);
}
