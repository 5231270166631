// Get single
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { ProjectFull } from "../types";

export const getProject = createAction<number>("PROJECTS/GET_PROJECT");

export const getProjectSuccess = createAction<ProjectFull>(
  "PROJECTS/GET_PROJECT_SUCCESS"
);

export const getProjectError = createAction<PayloadError[]>(
  "PROJECTS/GET_PROJECT_ERROR"
);

export const resetSelectedProject = createAction(
  "PROJECTS/RESET_SELECTED_PROJECT"
);
