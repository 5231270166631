import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getEmployeesDirectory = createAction("DIRECTORIES/GET_EMPLOYEES");

export const getEmployeesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_EMPLOYEES_SUCCESS"
);

export const getEmployeesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_EMPLOYEES_ERROR"
);
