import React from "react";
import { Badge } from "@chakra-ui/react";
import { EMPLOYEE_STATUSES } from "../../const/employee.const";

interface IEmployeeStatusIconProps {
  status: number;
}

export const EmployeeStatusIcon: React.FC<IEmployeeStatusIconProps> = ({
  status,
}) => {
  switch (status) {
    case EMPLOYEE_STATUSES.Active:
      return <Badge colorScheme="green">Active</Badge>;
    case EMPLOYEE_STATUSES.Pending:
      return <Badge colorScheme="yellow">Pending</Badge>;
    case EMPLOYEE_STATUSES.Fired:
      return <Badge colorScheme="red">Fired</Badge>;
    case EMPLOYEE_STATUSES.Quit:
      return <Badge>Quit</Badge>;
    default:
      return <></>;
  }
};
