import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Paged } from "../../common/models";
import { User } from "./types";
import { UserCreateModal } from "./components/UserCreateModal";
import { UserEditModal } from "./components/UserEditModal";
import { UsersTable } from "./components/UsersTable";
import { DirectoryItem } from "../../common/types";

const CFaUserAlt = chakra(FaSearch);
const CFaPlus = chakra(FaPlus);

interface IUsersProps {
  pagedUsers: Paged<User>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createUser: (user: User) => void;
  updateUser: (user: User) => void;
  contacts: DirectoryItem[];
  roles: DirectoryItem[];
  userLoading: boolean;
}

export const Users: React.FC<IUsersProps> = ({
  pagedUsers,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createUser,
  updateUser,
  userLoading,
  contacts,
  roles,
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const {
    isOpen: isOpenUserEdit,
    onOpen: onOpenUserEdit,
    onClose: onCloseUserEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenUserCreate,
    onOpen: onOpenUserCreate,
    onClose: onCloseUserCreate,
  } = useDisclosure();

  const handleOpenEdit = (user: User) => {
    setSelectedUser(user);
    onOpenUserEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Users</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenUserCreate()}>
            <CFaPlus mr={2} /> Add user
          </Button>
        </Box>
      </Flex>

      <UsersTable
        pagedUsers={pagedUsers}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <UserCreateModal
        handleCreate={createUser}
        isOpen={isOpenUserCreate}
        onOpen={onOpenUserCreate}
        onClose={onCloseUserCreate}
        contacts={contacts}
        roles={roles}
      />

      <UserEditModal
        handleUpdate={updateUser}
        selectedUser={selectedUser}
        isOpen={isOpenUserEdit}
        onOpen={onOpenUserEdit}
        onClose={onCloseUserEdit}
        contacts={contacts}
        roles={roles}
      />
    </>
  );
};
