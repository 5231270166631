import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { User } from "../types";
import { Paged } from "../../../common/models";
import { AuthImage, SharedPagination } from "../../../common/components";

interface IUsersListProps {
  pagedUsers: Paged<User>;
  showEdit: (user: User) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const UsersTable: React.FC<IUsersListProps> = ({
  pagedUsers,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Email</Th>
            <Th>Username</Th>
            <Th>Contact</Th>
            <Th>Role</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedUsers.entities.map((user, index) => {
            return (
              <Tr key={index}>
                <Td>{user.id}</Td>
                <Td>{user.email}</Td>
                <Td>{user.username}</Td>
                <Td>{user.contactName}</Td>
                <Td>{user.role}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(user)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedUsers.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
