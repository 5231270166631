import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createTeam,
  createTeamError,
  createTeamSuccess,
  getTeams,
  getTeamsError,
  getTeamsSuccess,
  updateTeam,
  updateTeamError,
  updateTeamSuccess,
} from "./actions";
import { Team } from "../types";
import { ApiResponse, Paged } from "../../../../common/models/common";

const apiUrl = `/api/teams/`;

function* workerGetTeams(action: ReturnType<typeof getTeams>) {
  try {
    const result: ApiResponse<Paged<Team>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getTeamsSuccess(result.value));
    } else {
      yield put(getTeamsError(result.errors));
    }
  } catch (e) {
    yield put(getTeamsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateTeam(action: ReturnType<typeof updateTeam>) {
  try {
    const result: ApiResponse<Team> = yield call(
      API.put,
      apiUrl,
      action.payload.team
    );
    if (result.success) {
      yield put(updateTeamSuccess());
      yield put(getTeams(action.payload.request));
    } else {
      yield put(updateTeamError(result.errors));
    }
  } catch (e) {
    yield put(updateTeamError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateTeam(action: ReturnType<typeof createTeam>) {
  try {
    const result: ApiResponse<Team> = yield call(
      API.post,
      apiUrl,
      action.payload.team
    );
    if (result.success) {
      yield put(createTeamSuccess(result.value));
      // Call update contacts list
      yield put(getTeams(action.payload.request));
    } else {
      yield put(createTeamError(result.errors));
    }
  } catch (e) {
    yield put(createTeamError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchTeamsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getTeams.type, workerGetTeams);
  yield takeEvery(createTeam.type, workerCreateTeam);
  yield takeEvery(updateTeam.type, workerUpdateTeam);
}
