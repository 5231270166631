import { ContactUsLeads } from "./ContactUsLeads";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useState } from "react";
import {
  getContactUsLeads,
  removeContactUsLead,
  resetContactUsLeads,
  updateContactUsLead,
} from "./store/actions";
import { PagedRequest } from "../../../common/models";
import { ContactUsLead } from "./types";

export const ContactUsLeadsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedContactId, setSelectedContactId] = useState<number | null>(
    null
  );
  const pageSize: number = 5;

  // Queries
  const contactUsLeads = useSelector(
    (state: RootStore) => state.contactUsLeads.contactUsLeads
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetContactUsLeads = (payload: PagedRequest) =>
    dispatch(getContactUsLeads(payload));

  const onRemoveContactUsLead = (payload: number) =>
    dispatch(
      removeContactUsLead({
        contactUsLeadId: payload,
        request: { offset, take: pageSize },
      })
    );

  const onUpdateContactUsLead = (lead: ContactUsLead) =>
    dispatch(
      updateContactUsLead({
        lead,
        request: { offset, take: pageSize },
      })
    );

  const onResetContacts = () => dispatch(resetContactUsLeads());

  const offsetChanged = (offset: number) => {
    onResetContacts();
    setOffset(offset);
    onGetContactUsLeads({ offset: offset, take: pageSize });
  };

  return (
    <ContactUsLeads
      pagedContactUsLeads={contactUsLeads}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      removeContactUsLead={onRemoveContactUsLead}
      updateContactUsLead={onUpdateContactUsLead}
      currentPage={currentPage}
    />
  );
};
