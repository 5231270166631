import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { PayoneerUploadData } from "../types";

export const uploadData = createAction<PayoneerUploadData>(
  "PAYONEER/UPLOAD_DATA"
);

export const uploadDataSuccess = createAction("PAYONEER/UPLOAD_DATA_SUCCESS");

export const uploadDataError = createAction<PayloadError[]>(
  "PAYONEER/UPLOAD_DATA_ERROR"
);
