import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  loginError,
  loginSuccess,
  logout,
} from "../../actions/auth/auth.actions";
import { PayloadError } from "../../../common/types";
import { IUserModel } from "../../../common/models";

export const initialState: {
  errors: PayloadError[];
  user: IUserModel;
  loading: boolean;
  loaded: boolean;
} = {
  errors: [],
  user: {
    accessToken: "",
    userName: "",
    id: 0,
    email: "",
    role: "",
  },
  loading: false,
  loaded: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login, (state) => {
        state.loading = true;
        state.loaded = false;
        state.errors = [];
      })
      .addCase(loginSuccess, (state, action) => {
        state.user = action.payload.value;
        state.loading = false;
        state.loaded = true;
        state.errors = [];
      })
      .addCase(loginError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(logout, (state) => {
        state.user = initialState.user;
      });
  },
});

export default authSlice.reducer;
