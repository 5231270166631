import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./common/utils/routing";
import { useSelector } from "react-redux";
import { RootStore } from "./store";
import { LoginContainer } from "./pages/Login/Login.container";
import { AppContainer } from "./pages/App.container";

export const App: React.FC = () => {
  const accessToken = useSelector(
    (state: RootStore) => !!state.auth?.user.accessToken
  );

  return (
    <Switch>
      <Route path={"/login"} component={LoginContainer} />
      <PrivateRoute
        path={"/app"}
        isSignedIn={accessToken}
        component={AppContainer}
      />
      <Redirect from="*" exact={true} to={`/app/contacts`} />
    </Switch>
  );
};
