import React, { useEffect } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  Spacer,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { EMPLOYEE_PAYMENT_TYPES_OPTIONS } from "../const/employee-payments.conts";
import { HookSelect } from "../../../../../../common/components/libs";

const CFaPlus = chakra(FaPlus);

interface IEmployeePaymentsHeaderProps {
  selectedPaymentTypeChanged?: (paymentType: number) => void;
  onOpenEmployeePaymentCreate: () => void;
}

export const EmployeePaymentsHeaders: React.FC<IEmployeePaymentsHeaderProps> =
  ({ selectedPaymentTypeChanged, onOpenEmployeePaymentCreate }) => {
    const {
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm();

    const paymentType = useWatch({ control, name: "paymentType" });

    useEffect(() => {
      if (paymentType && selectedPaymentTypeChanged) {
        selectedPaymentTypeChanged(paymentType);
      }
    }, [paymentType]);

    return (
      <>
        <Flex mb={3}>
          <Box w={"30%"}>
            <form>
              <FormControl id="paymentType" pr={3}>
                <HookSelect
                  control={control}
                  name={"paymentType"}
                  options={EMPLOYEE_PAYMENT_TYPES_OPTIONS}
                  placeholder={"Select payment type..."}
                />
              </FormControl>
            </form>
          </Box>
          <Spacer />
          <Box>
            <Button
              colorScheme="teal"
              onClick={() => onOpenEmployeePaymentCreate()}
            >
              <CFaPlus mr={2} /> Add payment
            </Button>
          </Box>
        </Flex>
      </>
    );
  };
