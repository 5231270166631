import { createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../../common/models/common";
import { uploadData, uploadDataError, uploadDataSuccess } from "./actions";

const initialState: BaseState = {
  loading: false,
  errors: [],
  loaded: false,
};

const uploadDataSlice = createSlice({
  name: "uploadData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadData, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(uploadDataSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(uploadDataError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      });
  },
});

export default uploadDataSlice.reducer;
