import React from "react";
import { Flex, List, ListItem, Spacer, Text } from "@chakra-ui/react";

type LabelValueListType = {
  label: string;
  value: string | null;
};

interface ILabelValueListProps {
  labelValueList: LabelValueListType[];
}

export const LabelValueList: React.FC<ILabelValueListProps> = ({
  labelValueList,
}) => {
  return (
    <List spacing={2}>
      {labelValueList.map((labelValue, index) => {
        return (
          <ListItem key={index}>
            <Flex>
              <Text fontWeight={600} mr={4}>
                {labelValue.label}:
              </Text>
              {labelValue.value ? <Text>{labelValue.value}</Text> : null}
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};
