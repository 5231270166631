// Get all
import { PagedRequest, Paged } from "../../../../common/models";
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import {
  PayoneerTransaction,
  PayoneerTransactionBinding,
  PayoneerTransactionCreate,
  PayoneerTransactionsRequest,
  PayoneerTransactionUpdate,
} from "../types";

export const getPayoneerTransactions = createAction<PayoneerTransactionsRequest>(
  "PAYONEER/GET_PAYONEER_TRANSACTIONS"
);

export const getPayoneerTransactionsSuccess = createAction<
  Paged<PayoneerTransaction>
>("PAYONEER/GET_PAYONEER_TRANSACTIONS_SUCCESS");

export const getPayoneerTransactionsError = createAction<PayloadError[]>(
  "PAYONEER/GET_PAYONEER_TRANSACTIONS_ERROR"
);

// Get single
export const getPayoneerTransaction = createAction(
  "PAYONEER/GET_PAYONEER_TRANSACTION"
);

export const getPayoneerTransactionSuccess = createAction<any>(
  "PAYONEER/GET_PAYONEER_TRANSACTION_SUCCESS"
);

export const getPayoneerTransactionError = createAction<PayloadError[]>(
  "PAYONEER/GET_PAYONEER_TRANSACTION_ERROR"
);

// Create
export const createPayoneerTransaction = createAction<{
  payoneerTransaction: PayoneerTransactionCreate;
  request: PayoneerTransactionsRequest;
}>("PAYONEER/CREATE_PAYONEER_TRANSACTION");

export const createPayoneerTransactionSuccess = createAction<PayoneerTransaction>(
  "PAYONEER/CREATE_PAYONEER_TRANSACTION_SUCCESS"
);

export const createPayoneerTransactionError = createAction<PayloadError[]>(
  "PAYONEER/CREATE_PAYONEER_TRANSACTION_ERROR"
);

// Bind
export const bindPayoneerTransaction = createAction<{
  transactionBinding: PayoneerTransactionBinding;
  request: PayoneerTransactionsRequest;
}>("PAYONEER/BIND_PAYONEER_TRANSACTION");

export const bindPayoneerTransactionSuccess = createAction(
  "PAYONEER/BIND_PAYONEER_TRANSACTION_SUCCESS"
);

export const bindPayoneerTransactionError = createAction<PayloadError[]>(
  "PAYONEER/BIND_PAYONEER_TRANSACTION_ERROR"
);

// Update
export const updatePayoneerTransaction = createAction<{
  payoneerTransaction: PayoneerTransactionUpdate;
  request: PayoneerTransactionsRequest;
}>("PAYONEER/UPDATE_PAYONEER_TRANSACTION");

export const updatePayoneerTransactionSuccess = createAction(
  "PAYONEER/UPDATE_PAYONEER_TRANSACTION_SUCCESS"
);

export const updatePayoneerTransactionError = createAction<PayloadError[]>(
  "PAYONEER/UPDATE_PAYONEER_TRANSACTION_ERROR"
);

// Reset
export const resetSelectedPayoneerTransaction = createAction(
  "PAYONEER/RESET_SELECTED_PAYONEER_TRANSACTION"
);

export const resetPayoneerTransactions = createAction("PAYONEER/RESET");
