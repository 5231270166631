import React from "react";
import { Route } from "react-router";

import { ContractsContainer } from "./Contracts/Contracts.container";
import { PartnersContainer } from "./Partners/Partners.container";
import { ProjectsContainer } from "./Projects/Projects.container";
import { ProjectContainer } from "./Project/Project.container";

interface IJobRoutingProps {
  match: any;
  nestedPath: string;
}

export const JobRouting: React.FC<IJobRoutingProps> = ({
  match,
  nestedPath,
}) => {
  return (
    <>
      <Route
        path={`${match.url}/${nestedPath}/contracts`}
        component={ContractsContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/partners`}
        component={PartnersContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/projects`}
        component={ProjectsContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/project/:projectId`}
        component={ProjectContainer}
      />
    </>
  );
};
