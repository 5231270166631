import { createSlice } from "@reduxjs/toolkit";
import { PayoneerReport } from "../types";
import {
  getPayoneerReport,
  getPayoneerReportError,
  getPayoneerReportSuccess,
} from "./actions";
import { BaseState } from "../../../../common/models/common";

type InitialState = BaseState & {
  report: PayoneerReport;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  report: {
    gandalfOwesAragorn: 0,
    aragornOwesGandalf: 0,
    aragornTotal: 0,
    stats: {
      aragornAmount: 0,
      aragornIncome: 0,
      aragornPersonalExpenses: 0,
      aragornPersonalIncome: 0,
      gandalfAmount: 0,
      gandalfIncome: 0,
      gandalfPersonalExpenses: 0,
      gandalfPersonalIncome: 0,
    },
    gandalfTotal: 0,
  },
  loaded: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayoneerReport, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPayoneerReportSuccess, (state, action) => {
        state.report = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getPayoneerReportError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      });
  },
});

export default reportSlice.reducer;
