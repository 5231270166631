import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createContract,
  createContractError,
  createContractSuccess,
  getContracts,
  getContractsError,
  getContractsSuccess,
  updateContract,
  updateContractError,
  updateContractSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { Contract } from "../types";

const apiUrl = `/api/contracts`;

function* workerGetContracts(action: ReturnType<typeof getContracts>) {
  try {
    const result: ApiResponse<Paged<Contract>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getContractsSuccess(result.value));
    } else {
      yield put(getContractsError(result.errors));
    }
  } catch (e) {
    yield put(getContractsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateContract(action: ReturnType<typeof updateContract>) {
  try {
    const result: ApiResponse<Contract> = yield call(
      API.put,
      apiUrl,
      action.payload.contract
    );
    if (result.success) {
      yield put(updateContractSuccess());
      yield put(getContracts(action.payload.request));
    } else {
      yield put(updateContractError(result.errors));
    }
  } catch (e) {
    yield put(updateContractError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateContract(action: ReturnType<typeof createContract>) {
  try {
    const result: ApiResponse<Contract> = yield call(
      API.post,
      apiUrl,
      action.payload.contract
    );
    if (result.success) {
      yield put(createContractSuccess(result.value));
      // Call update contacts list
      yield put(getContracts(action.payload.request));
    } else {
      yield put(createContractError(result.errors));
    }
  } catch (e) {
    yield put(createContractError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchContractsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContracts.type, workerGetContracts);
  yield takeEvery(createContract.type, workerCreateContract);
  yield takeEvery(updateContract.type, workerUpdateContract);
}
