import React from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../common/models";
import { Partner, PartnerCreate, PartnerProfile, PartnerUpdate } from "./types";
import {
  PartnerCreateModal,
  PartnerEditModal,
  PartnersTable,
  PartnerViewModal,
} from "./components";
import { DirectoryItem } from "../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IPartnersProps {
  pagedPartners: Paged<Partner>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  selectedPartner: PartnerProfile;
  createPartner: (partner: PartnerCreate) => void;
  updatePartner: (partner: PartnerUpdate) => void;
  setSelectedPartnerId: (partnerId: number) => void;
  partnerLoading: boolean;
  countries: DirectoryItem[];
  partnerTypes: DirectoryItem[];
  partnerIndustries: DirectoryItem[];
  addressTypes: DirectoryItem[];
  contacts: DirectoryItem[];
}

export const Partners: React.FC<IPartnersProps> = ({
  pagedPartners,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createPartner,
  updatePartner,
  partnerLoading,
  selectedPartner,
  setSelectedPartnerId,
  countries,
  partnerTypes,
  partnerIndustries,
  addressTypes,
  contacts,
}) => {
  const {
    isOpen: isOpenPartnerView,
    onOpen: onOpenPartnerView,
    onClose: onClosePartnerView,
  } = useDisclosure();
  const {
    isOpen: isOpenPartnerEdit,
    onOpen: onOpenPartnerEdit,
    onClose: onClosePartnerEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenPartnerCreate,
    onOpen: onOpenPartnerCreate,
    onClose: onClosePartnerCreate,
  } = useDisclosure();

  const handleOpenView = (partner: Partner) => {
    setSelectedPartnerId(partner.id);
    onOpenPartnerView();
  };

  const handleOpenEdit = (partner: Partner) => {
    setSelectedPartnerId(partner.id);
    onOpenPartnerEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Partners</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenPartnerCreate()}>
            <CFaPlus mr={2} /> Add partner
          </Button>
        </Box>
      </Flex>

      <PartnersTable
        countries={countries}
        partnerTypes={partnerTypes}
        partnerIndustries={partnerIndustries}
        pagedPartners={pagedPartners}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
        showView={handleOpenView}
      />

      <PartnerCreateModal
        addressTypes={addressTypes}
        countries={countries}
        contacts={contacts}
        partnerTypes={partnerTypes}
        partnerIndustries={partnerIndustries}
        handleCreate={createPartner}
        isOpen={isOpenPartnerCreate}
        onOpen={onOpenPartnerCreate}
        onClose={onClosePartnerCreate}
      />

      <PartnerEditModal
        addressTypes={addressTypes}
        countries={countries}
        contacts={contacts}
        partnerTypes={partnerTypes}
        partnerIndustries={partnerIndustries}
        handleUpdate={updatePartner}
        selectedPartner={selectedPartner}
        isOpen={isOpenPartnerEdit}
        onOpen={onOpenPartnerEdit}
        onClose={onClosePartnerEdit}
      />

      <PartnerViewModal
        selectedPartner={selectedPartner}
        isLoading={partnerLoading}
        isOpen={isOpenPartnerView}
        onOpen={onOpenPartnerView}
        onClose={onClosePartnerView}
      />
    </>
  );
};
