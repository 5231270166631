import React, { useEffect } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IProjectsHeaderProps {
  selectedContractChanged: (contractId: number) => void;
  onOpenProjectCreate: () => void;
  contracts: DirectoryItem[];
}

export const ProjectsHeaders: React.FC<IProjectsHeaderProps> = ({
  selectedContractChanged,
  onOpenProjectCreate,
  contracts,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const contractId = useWatch({ control, name: "contractId" });

  useEffect(() => {
    if (contractId) {
      selectedContractChanged(contractId);
    }
  }, [contractId]);

  return (
    <>
      <Heading size={"lg"} mb={3}>
        Projects
      </Heading>
      <Flex mb={3}>
        <Box w={"30%"}>
          <form>
            <FormControl id="contractId" pr={3}>
              <HookSelect
                control={control}
                name={"contractId"}
                options={contracts}
                placeholder={"Select contract..."}
              />
            </FormControl>
          </form>
        </Box>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenProjectCreate()}>
            <CFaPlus mr={2} /> Add project
          </Button>
        </Box>
      </Flex>
    </>
  );
};
