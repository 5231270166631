import { createSlice } from "@reduxjs/toolkit";
import { Salary } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  createSalary,
  createSalaryError,
  createSalarySuccess,
  getSalaries,
  getSalariesError,
  getSalariesSuccess,
  resetSalaries,
  updateSalary,
  updateSalaryError,
  updateSalarySuccess,
} from "./actions";

type InitialState = BaseState & {
  salaries: Paged<Salary>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  salaries: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const salariesSlice = createSlice({
  name: "salaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalaries, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getSalariesSuccess, (state, action) => {
        state.salaries = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getSalariesError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateSalary, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateSalarySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateSalaryError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createSalary, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createSalarySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createSalaryError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetSalaries, (state) => {
        state.salaries.entities = [];
      });
  },
});

export default salariesSlice.reducer;
