import { createSlice } from "@reduxjs/toolkit";
import { ProjectSimple } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  createProject,
  createProjectError,
  createProjectSuccess,
  getProjects,
  getProjectsError,
  getProjectsSuccess,
  resetProjects,
  updateProject,
  updateProjectError,
  updateProjectSuccess,
} from "./actions";

type InitialState = BaseState & {
  projects: Paged<ProjectSimple>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  projects: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getProjectsSuccess, (state, action) => {
        state.projects = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getProjectsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateProject, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateProjectSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateProjectError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createProject, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createProjectSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createProjectError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetProjects, (state) => {
        state.projects.entities = [];
      });
  },
});

export default projectsSlice.reducer;
