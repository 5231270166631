// Get all
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../../../common/types";
import { Paged, PagedRequest } from "../../../../../../common/models";
import {
  EmployeeVacation,
  EmployeeVacationCreate,
  EmployeeVacationDaysCheck,
  EmployeeVacationsRequest,
  EmployeeVacationUpdate,
} from "../types";

export const getEmployeeVacations = createAction<EmployeeVacationsRequest>(
  "EMPLOYEE/GET_EMPLOYEE_VACATIONS"
);

export const getEmployeeVacationsSuccess = createAction<
  Paged<EmployeeVacation>
>("EMPLOYEE/GET_EMPLOYEE_VACATIONS_SUCCESS");

export const getEmployeeVacationsError = createAction<PayloadError[]>(
  "EMPLOYEE/GET_EMPLOYEE_VACATIONS_ERROR"
);

// Get single
export const getEmployeeVacation = createAction(
  "EMPLOYEE/GET_EMPLOYEE_VACATION"
);

export const getEmployeeVacationSuccess = createAction<any>(
  "EMPLOYEE/GET_EMPLOYEE_VACATION_SUCCESS"
);

export const getEmployeeVacationError = createAction<PayloadError[]>(
  "EMPLOYEE/GET_EMPLOYEE_VACATION_ERROR"
);

// Create
export const createEmployeeVacation = createAction<{
  employeeVacation: EmployeeVacationCreate;
  request: EmployeeVacationsRequest;
}>("EMPLOYEE/CREATE_EMPLOYEE_VACATION");

export const createEmployeeVacationSuccess = createAction<EmployeeVacation>(
  "EMPLOYEE/CREATE_EMPLOYEE_VACATION_SUCCESS"
);

export const createEmployeeVacationError = createAction<PayloadError[]>(
  "EMPLOYEE/CREATE_EMPLOYEE_VACATION_ERROR"
);

// Update
export const updateEmployeeVacation = createAction<{
  employeeVacation: EmployeeVacationUpdate;
  request: EmployeeVacationsRequest;
}>("EMPLOYEE/UPDATE_EMPLOYEE_VACATION");

export const updateEmployeeVacationSuccess = createAction(
  "EMPLOYEE/UPDATE_EMPLOYEE_VACATION_SUCCESS"
);

export const updateEmployeeVacationError = createAction<PayloadError[]>(
  "EMPLOYEE/UPDATE_EMPLOYEE_VACATION_ERROR"
);

// Get single
export const checkEmployeeVacation = createAction<EmployeeVacationDaysCheck>(
  "EMPLOYEE/CHECK_EMPLOYEE_VACATION"
);

export const checkEmployeeVacationSuccess = createAction<{ days: number }>(
  "EMPLOYEE/CHECK_EMPLOYEE_VACATION_SUCCESS"
);

export const checkEmployeeVacationError = createAction<PayloadError[]>(
  "EMPLOYEE/CHECK_EMPLOYEE_VACATION_ERROR"
);

// Reset
export const resetSelectedEmployeeVacation = createAction(
  "EMPLOYEE/RESET_SELECTED_EMPLOYEE_VACATION"
);

export const resetEmployeeVacations = createAction("EMPLOYEE/RESET");
