import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createEmployee,
  createEmployeeError,
  createEmployeeSuccess,
  getEmployees,
  getEmployeesError,
  getEmployeesSuccess,
  updateEmployee,
  updateEmployeeError,
  updateEmployeeSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { Employee } from "../types";

const apiUrl = `/api/employees/`;

function* workerGetEmployees(action: ReturnType<typeof getEmployees>) {
  try {
    const result: ApiResponse<Paged<Employee>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeesSuccess(result.value));
    } else {
      yield put(getEmployeesError(result.errors));
    }
  } catch (e) {
    yield put(getEmployeesError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateEmployee(action: ReturnType<typeof updateEmployee>) {
  try {
    const result: ApiResponse<Employee> = yield call(
      API.put,
      apiUrl,
      action.payload.employee
    );
    if (result.success) {
      yield put(updateEmployeeSuccess());
      yield put(getEmployees(action.payload.request));
    } else {
      yield put(updateEmployeeError(result.errors));
    }
  } catch (e) {
    yield put(updateEmployeeError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateEmployee(action: ReturnType<typeof createEmployee>) {
  try {
    const result: ApiResponse<Employee> = yield call(
      API.post,
      apiUrl,
      action.payload.employee
    );
    if (result.success) {
      yield put(createEmployeeSuccess(result.value));
      // Call update contacts list
      yield put(getEmployees(action.payload.request));
    } else {
      yield put(createEmployeeError(result.errors));
    }
  } catch (e) {
    yield put(createEmployeeError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchEmployeesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getEmployees.type, workerGetEmployees);
  yield takeEvery(createEmployee.type, workerCreateEmployee);
  yield takeEvery(updateEmployee.type, workerUpdateEmployee);
}
