import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Contact, ContactUpdate } from "../types";
import { HookDatePicker, HookSelect } from "../../../common/components/libs";
import { useForm } from "react-hook-form";
import {
  parseDateToISO,
  parseStringToDate,
} from "../../../common/utils/date-parser";
import {
  AuthImage,
  FileInput,
  ImageCropModal,
} from "../../../common/components";
import { DirectoryItem } from "../../../common/types";

interface IContactEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedContact: Contact;
  handleUpdate: (contact: ContactUpdate) => void;
  genders: DirectoryItem[];
  cities: DirectoryItem[];
  contactTypes: DirectoryItem[];
}

export const ContactEditModal: React.FC<IContactEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedContact,
  handleUpdate,
  genders,
  cities,
  contactTypes,
}) => {
  const { register, control, handleSubmit, setValue, reset, getValues } =
    useForm();

  const {
    isOpen: isOpenImageCrop,
    onOpen: onOpenImageCrop,
    onClose: onCloseImageCrop,
  } = useDisclosure();

  const handleSubmitUpdate = (values: Contact) => {
    handleUpdate({
      ...values,
      id: selectedContact.id,
      birthDate: parseDateToISO(getValues("birthDate")),
      avatarData: fileForUpload ? fileForUpload : null,
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedContact,
      birthDate: parseStringToDate(selectedContact.birthDate),
      avatarData: fileForUpload ? fileForUpload : null,
    });
  }, [selectedContact]);

  const [fileForUpload, setFileForUpload] = useState<any>(null);
  const [fileForCropper, setFileForCropper] = useState<any>(null);

  const handleSetFileForCropper = (file: File) => {
    if (file) {
      setFileForCropper(URL.createObjectURL(file));
      onOpenImageCrop();
    }
  };

  const handleImageCropped = (image: string) => {
    setFileForUpload(image);
  };

  const handleClose = () => {
    reset();
    setFileForCropper(null);
    setFileForUpload(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit contact</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Flex>
                <Box w={"30%"}>
                  <Box pr={6}>
                    <Box mb={6}>
                      {fileForUpload ? (
                        <Avatar size="3xl" src={fileForUpload} />
                      ) : selectedContact.avatarStorageHash ? (
                        <AuthImage
                          url={
                            `/api/storage/images/contact-avatar/` +
                            selectedContact.avatarStorageHash
                          }
                          childComponent={<Avatar size="3xl" />}
                        />
                      ) : (
                        <Avatar size="3xl" />
                      )}
                    </Box>

                    <FileInput
                      register={register}
                      name={"avatarInput"}
                      fileChanged={(file) => handleSetFileForCropper(file)}
                    />
                  </Box>
                </Box>
                <Box w={"70%"}>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="firstName" pr={3}>
                        <FormLabel>First name</FormLabel>
                        <Input {...register("firstName", { required: true })} />
                      </FormControl>
                      <FormControl id="middleName" pl={3} pr={3}>
                        <FormLabel>Middle name</FormLabel>
                        <Input {...register("middleName")} />
                      </FormControl>
                      <FormControl id="lastName" pl={3}>
                        <FormLabel>Last name</FormLabel>
                        <Input {...register("lastName", { required: true })} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="birthDate" pr={3}>
                        <FormLabel>Birth date</FormLabel>
                        <HookDatePicker control={control} name={"birthDate"} />
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>Phone</FormLabel>
                        <Input {...register("phone")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="email" pr={3}>
                        <FormLabel>Email</FormLabel>
                        <Input
                          type={"email"}
                          {...register("email", { required: true })}
                        />
                      </FormControl>
                      <FormControl id="address" pl={3}>
                        <FormLabel>Address</FormLabel>
                        <Input {...register("address")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="birthDate" pr={3}>
                        <FormLabel>Zip code</FormLabel>
                        <Input {...register("zipCode")} />
                      </FormControl>
                      <FormControl id="skype" pl={3}>
                        <FormLabel>Skype</FormLabel>
                        <Input {...register("skype")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3}>
                        <FormLabel>Gender</FormLabel>
                        <HookSelect
                          control={control}
                          name={"genderId"}
                          options={genders}
                        />
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>Contact type</FormLabel>
                        <HookSelect
                          control={control}
                          name={"contactTypeId"}
                          options={contactTypes}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <FormControl w={"50%"} pr={3}>
                      <FormLabel>City</FormLabel>
                      <HookSelect
                        control={control}
                        name={"cityId"}
                        options={cities}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <ImageCropModal
        handleImageCropped={handleImageCropped}
        sourceImage={fileForCropper}
        isOpen={isOpenImageCrop}
        onOpen={onOpenImageCrop}
        onClose={onCloseImageCrop}
      />
    </>
  );
};
