import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createSalary,
  createSalaryError,
  createSalarySuccess,
  getSalaries,
  getSalariesError,
  getSalariesSuccess,
  updateSalary,
  updateSalaryError,
  updateSalarySuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { Salary } from "../types";

const apiUrl = `/api/employees/salaries/`;

function* workerGetSalaries(action: ReturnType<typeof getSalaries>) {
  try {
    const result: ApiResponse<Paged<Salary>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getSalariesSuccess(result.value));
    } else {
      yield put(getSalariesError(result.errors));
    }
  } catch (e) {
    yield put(getSalariesError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateSalary(action: ReturnType<typeof updateSalary>) {
  try {
    const result: ApiResponse<Salary> = yield call(
      API.put,
      apiUrl,
      action.payload.salary
    );
    if (result.success) {
      yield put(updateSalarySuccess());
      yield put(getSalaries(action.payload.request));
    } else {
      yield put(updateSalaryError(result.errors));
    }
  } catch (e) {
    yield put(updateSalaryError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateSalary(action: ReturnType<typeof createSalary>) {
  try {
    const result: ApiResponse<Salary> = yield call(
      API.post,
      apiUrl,
      action.payload.salary
    );
    if (result.success) {
      yield put(createSalarySuccess(result.value));
      // Call update contacts list
      yield put(getSalaries(action.payload.request));
    } else {
      yield put(createSalaryError(result.errors));
    }
  } catch (e) {
    yield put(createSalaryError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchSalariesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getSalaries.type, workerGetSalaries);
  yield takeEvery(createSalary.type, workerCreateSalary);
  yield takeEvery(updateSalary.type, workerUpdateSalary);
}
