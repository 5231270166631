import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Team, TeamUpdate } from "../types";

import { useForm } from "react-hook-form";
import { HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { TEAM_STATUS_OPTIONS } from "../teams.const";
import { SimpleDictionaryTable } from "../../../../common/components";
import { FaPlus } from "react-icons/fa";

const CFaPlus = chakra(FaPlus);

interface ITeamEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedTeam: Team | null;
  handleUpdate: (team: TeamUpdate) => void;
  employees: DirectoryItem[];
}

export const TeamEditModal: React.FC<ITeamEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedTeam,
  handleUpdate,
  employees,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    getValues,
  } = useForm();

  const [selectedEmployees, setSelectedEmployees] = useState<DirectoryItem[]>(
    []
  );

  const watchEmployee = watch("employeeId");

  const handleSubmitUpdate = (values: TeamUpdate) => {
    const { name, status } = values;
    handleUpdate({
      name,
      status,
      id: selectedTeam ? selectedTeam.id : null,
      employeesIds: selectedEmployees.map((employee) => employee.value),
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedTeam,
    });
    if (selectedTeam && selectedTeam.members) {
      const employeesList: DirectoryItem[] = selectedTeam.members.map(
        (member) => {
          return {
            value: member.employeeId,
            label: `${member.employeeFirstName} ${member.employeeLastName}`,
          };
        }
      );
      setSelectedEmployees(employeesList);
    }
  }, [selectedTeam]);

  const handleRemoveEmployee = (employeeId: number) => {
    const filteredEmployees = selectedEmployees.filter(
      (x) => x.value !== employeeId
    );
    setSelectedEmployees(filteredEmployees);
  };

  const handleAddEmployee = () => {
    const employeeId = getValues().employeeId;
    if (employeeId) {
      const employee = employees.find((x) => x.value === employeeId);
      if (employee) {
        setSelectedEmployees([...selectedEmployees, employee]);
      }
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit team</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="name" pr={3} isInvalid={errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input {...register("name", { required: true })} />
                      <FormErrorMessage>
                        {errors.name ? "Name is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="status" pl={3}>
                      <FormLabel>Status</FormLabel>
                      <HookSelect
                        control={control}
                        name={"status"}
                        options={TEAM_STATUS_OPTIONS}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="employeeId" mr={3}>
                      <FormLabel>Employee</FormLabel>
                      <HookSelect
                        control={control}
                        name={"employeeId"}
                        options={employees}
                      />
                    </FormControl>
                    <Button
                      mt={"auto"}
                      variant={"outline"}
                      disabled={!watchEmployee}
                      onClick={handleAddEmployee}
                    >
                      <CFaPlus mr={2} /> Add
                    </Button>
                  </Flex>
                </Box>
              </Box>
              <SimpleDictionaryTable
                directoryEntities={selectedEmployees}
                removeEntity={handleRemoveEmployee}
              />
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
