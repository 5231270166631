import React, { useState } from "react";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../common/models";
import { ProjectCreate, ProjectSimple, ProjectUpdate } from "./types";
import {
  ProjectCreateModal,
  ProjectEditModal,
  ProjectsTable,
} from "./components";
import { ProjectsHeaders } from "./components/ProjectsHeader";
import { DirectoryItem } from "../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IProjectsProps {
  pagedProjects: Paged<ProjectSimple>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createProject: (project: ProjectCreate) => void;
  updateProject: (project: ProjectUpdate) => void;
  projectLoading: boolean;
  contracts: DirectoryItem[];
  teams: DirectoryItem[];
  selectedContractChanged: (contractId: number) => void;
}

export const Projects: React.FC<IProjectsProps> = ({
  pagedProjects,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createProject,
  updateProject,
  projectLoading,
  contracts,
  selectedContractChanged,
  teams,
}) => {
  const [selectedProject, setSelectedProject] = useState<ProjectSimple | null>(
    null
  );
  const {
    isOpen: isOpenProjectEdit,
    onOpen: onOpenProjectEdit,
    onClose: onCloseProjectEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenProjectCreate,
    onOpen: onOpenProjectCreate,
    onClose: onCloseProjectCreate,
  } = useDisclosure();

  const handleOpenEdit = (project: ProjectSimple) => {
    setSelectedProject(project);
    onOpenProjectEdit();
  };

  return (
    <>
      <ProjectsHeaders
        contracts={contracts}
        selectedContractChanged={selectedContractChanged}
        onOpenProjectCreate={onOpenProjectCreate}
      />

      <ProjectsTable
        pagedProjects={pagedProjects}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <ProjectCreateModal
        contracts={contracts}
        teams={teams}
        handleCreate={createProject}
        isOpen={isOpenProjectCreate}
        onOpen={onOpenProjectCreate}
        onClose={onCloseProjectCreate}
      />

      <ProjectEditModal
        contracts={contracts}
        teams={teams}
        handleUpdate={updateProject}
        selectedProject={selectedProject}
        isOpen={isOpenProjectEdit}
        onOpen={onOpenProjectEdit}
        onClose={onCloseProjectEdit}
      />
    </>
  );
};
