import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { User } from "../types";
import { useForm } from "react-hook-form";
import { HookSelect } from "../../../common/components/libs";
import { DirectoryItem } from "../../../common/types";

interface IUserEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedUser: User | null;
  handleUpdate: (user: User) => void;
  contacts: DirectoryItem[];
  roles: DirectoryItem[];
}

export const UserEditModal: React.FC<IUserEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedUser,
  handleUpdate,
  contacts,
  roles,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const handleSubmitUpdate = (values: User) => {
    handleUpdate({
      ...values,
      id: selectedUser ? selectedUser.id : null,
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedUser,
    });
  }, [selectedUser]);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit user</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="contactId"
                      pr={3}
                      isInvalid={errors.contactId}
                    >
                      <FormLabel>Contact</FormLabel>
                      <HookSelect
                        control={control}
                        name={"contactId"}
                        options={contacts}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.contactId ? "Contact is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="email" pl={3} isInvalid={errors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input {...register("email", { required: true })} />
                      <FormErrorMessage>
                        {errors.email ? "Email is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="username"
                      pr={3}
                      isInvalid={errors.username}
                    >
                      <FormLabel>Username</FormLabel>
                      <Input {...register("username", { required: true })} />
                      <FormErrorMessage>
                        {errors.username ? "Username is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="roleId" pl={3} isInvalid={errors.roleId}>
                      <FormLabel>Roles</FormLabel>
                      <HookSelect
                        control={control}
                        name={"roleId"}
                        options={roles}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.role ? "Role is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="password"
                      pr={3}
                      isInvalid={errors.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <Input
                        type={"password"}
                        {...register("password", { required: true })}
                      />
                      <FormErrorMessage>
                        {errors.password ? "Password is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="confirmPassword"
                      pl={3}
                      isInvalid={errors.confirmPassword}
                    >
                      <FormLabel>Confirm password</FormLabel>
                      <Input
                        type={"password"}
                        {...register("confirmPassword", {
                          validate: (value) =>
                            value === password.current ||
                            "The passwords do not match",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.confirmPassword
                          ? "Password does not match"
                          : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
