import React, { useState } from "react";
import {
  Accordion,
  Avatar,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  LinkBox,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiBriefcase, FiDollarSign, FiMenu, FiSettings } from "react-icons/fi";
import { NavItem } from "./NavItem";
import {
  FaAddressBook,
  FaBalanceScale,
  FaCashRegister,
  FaExchangeAlt,
  FaHandshake,
  FaMoneyBillWave,
  FaPeopleCarry,
  FaProjectDiagram,
  FaScroll,
  FaSignOutAlt,
  FaUserFriends,
  FaUsers,
  FaUserTie,
} from "react-icons/all";
import logo from "../../../../assets/img/logo1-auth.png";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import { logout } from "../../../../store/actions/auth";
import {
  UserProfile,
  UserProfileShort,
  UserProfileUpdate,
} from "../../../types";
import { AuthImage } from "../../helpers";

type NavItem = {
  to: string;
  icon: any;
  title: string;
};

const navItems: NavItem[] = [
  { to: "/app/contacts", icon: FaAddressBook, title: "Contacts" },
  { to: "/app/users", icon: FaUserFriends, title: "Users" },
  { to: "/app/work/employees", icon: FaPeopleCarry, title: "Employees" },
  { to: "/app/work/salaries", icon: FaCashRegister, title: "Salaries" },
  { to: "/app/work/teams", icon: FaUsers, title: "Teams" },
  { to: "/app/job/contracts", icon: FaScroll, title: "Contracts" },
  { to: "/app/job/partners", icon: FaHandshake, title: "Partners" },
  { to: "/app/job/projects", icon: FaProjectDiagram, title: "Projects" },
  { to: "/app/leads/contact-us", icon: FaUserTie, title: "Leads" },
  { to: "/app/payoneer/report", icon: FaMoneyBillWave, title: "Report" },
  {
    to: "/app/payoneer/transactions",
    icon: FaExchangeAlt,
    title: "Transactions",
  },
];

interface ISidebarProps {
  generalProfile: UserProfileShort;
}

export const Sidebar: React.FC<ISidebarProps> = ({ generalProfile }) => {
  const location = useLocation<Location>();
  const history = useHistory();
  const [navSize, changeNavSize] = useState<string>("large");
  const currentUser = useSelector((state: RootStore) => state.auth.user);
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  const sidebarBg = useColorModeValue("gray.50", "#1f2335");

  return (
    <Flex
      pos={"sticky"}
      h="100vh"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.1)"
      borderRadius={"10px"}
      bgColor={sidebarBg}
      w={navSize == "small" ? "75px" : "250px"}
      minW={navSize == "small" ? "75px" : "200px"}
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize == "small" ? "center" : "flex-start"}
        as="nav"
      >
        <Flex alignItems={"center"} mt={5} mb={3}>
          <IconButton
            background="none"
            colorScheme={"gray"}
            _hover={{ background: "none" }}
            icon={<FiMenu />}
            onClick={() => {
              if (navSize == "small") changeNavSize("large");
              else changeNavSize("small");
            }}
            aria-label={"IconBtn"}
          />
          {navSize == "large" ? (
            <Flex ml={2} alignItems={"center"}>
              <Image boxSize="50px" src={logo} />
              <Heading color={"teal.400"}>BB</Heading>
            </Flex>
          ) : (
            ""
          )}
        </Flex>

        {navItems.map((navItem: NavItem, index: number) => {
          return (
            <NavItem
              key={index}
              navSize={navSize}
              icon={navItem.icon}
              to={navItem.to}
              title={navItem.title}
              active={location.pathname === navItem.to}
            />
          );
        })}

        <Flex
          flexDir="column"
          w="100%"
          alignItems={navSize == "small" ? "center" : ""}
        >
          <Accordion borderColor={"transparent"}>
            {/*<NavItem*/}
            {/*  navSize={navSize}*/}
            {/*  icon={FiDollarSign}*/}
            {/*  title="Stocks"*/}
            {/*  isAccordion={true}*/}
            {/*/>*/}
            {/*<NavItem*/}
            {/*  navSize={navSize}*/}
            {/*  icon={FiBriefcase}*/}
            {/*  title="Reports"*/}
            {/*  isAccordion={true}*/}
            {/*/>*/}
            {/*<NavItem*/}
            {/*  navSize={navSize}*/}
            {/*  icon={FiSettings}*/}
            {/*  title="Settings"*/}
            {/*  isAccordion={true}*/}
            {/*/>*/}
          </Accordion>
        </Flex>
      </Flex>

      <Flex
        p={"5%"}
        pl={navSize == "small" ? "5%" : "10%"}
        flexDir="column"
        w="100%"
        alignItems={navSize == "small" ? "center" : "flex-start"}
        mb={4}
        cursor={"pointer"}
        onClick={() => history.push("/app/profile")}
      >
        <Divider display={navSize == "small" ? "none" : "flex"} />
        <Flex mt={4} align="center" w={"100%"}>
          {generalProfile.avatarHash ? (
            <AuthImage
              url={`/api/storage/images/contact-avatar/${generalProfile.avatarHash}${generalProfile.avatarExtension}`}
              childComponent={
                <Avatar
                  size="sm"
                  display={navSize == "small" ? "none" : "flex"}
                />
              }
            />
          ) : (
            <Avatar
              size="sm"
              src="avatar-1.jpg"
              display={navSize == "small" ? "none" : "flex"}
            />
          )}
          <Flex
            flexDir="column"
            ml={4}
            display={navSize == "small" ? "none" : "flex"}
          >
            <Heading
              as="h3"
              size="sm"
              color={location.pathname === "/app/profile" ? "teal.500" : ""}
            >
              {generalProfile.firstName} {generalProfile.lastName}
            </Heading>
            {/*<Text>{currentUser.roleName}</Text>*/}
          </Flex>
          <Tooltip label="Logout" fontSize="md">
            <IconButton
              ml={navSize == "small" ? "2" : "auto"}
              size="lg"
              background="none"
              colorScheme={"gray"}
              _hover={{ background: "gray.100" }}
              color={"gray.400"}
              icon={<FaSignOutAlt />}
              onClick={onLogout}
              aria-label={"LogoutBtn"}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};
