import { Contract, ContractCreate, ContractUpdate } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { PagedRequest } from "../../../common/models";
import {
  createContract,
  getContracts,
  resetContracts,
  updateContract,
} from "./store/actions";
import { Contracts } from "./Contracts";
import { getPartnersDirectory } from "../../../store/actions/directories";

export const ContractsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  useEffect(() => {
    onGetPartnersDirectory();
  }, []);

  // Queries
  const contracts = useSelector(
    (state: RootStore) => state.contracts.contracts
  );
  const contractLoading = useSelector(
    (state: RootStore) => state.contracts.loading
  );
  const partners = useSelector(
    (state: RootStore) => state.directories.partners.partners
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetContracts = (payload: PagedRequest) =>
    dispatch(getContracts(payload));
  const onGetPartnersDirectory = () => dispatch(getPartnersDirectory());
  const onUpdateContract = (contract: ContractUpdate) =>
    dispatch(
      updateContract({
        contract,
        request: { offset: offset, take: pageSize },
      })
    );
  const onCreateContract = (contract: ContractCreate) =>
    dispatch(
      createContract({
        contract,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetContracts = () => dispatch(resetContracts());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetContracts({ offset: offset, take: pageSize });
  };

  return (
    <Contracts
      partners={partners}
      pagedContracts={contracts}
      contractLoading={contractLoading}
      createContract={onCreateContract}
      updateContract={onUpdateContract}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
