import React, { useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { EmployeeVacation, EmployeeVacationCreate } from "../types";
import {
  HookDatePicker,
  HookSelect,
} from "../../../../../../common/components/libs";
import { parseDateToISO } from "../../../../../../common/utils";

interface IEmployeeVacationCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (employeeVacation: EmployeeVacationCreate) => void;
}

export const EmployeeVacationCreateModal: React.FC<IEmployeeVacationCreateModalProps> =
  ({ isOpen, onOpen, onClose, handleCreate }) => {
    const {
      register,
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
      getValues,
    } = useForm();

    const watchDateFrom = useWatch({ control, name: "dateFrom" });
    const watchDateTo = useWatch({ control, name: "dateTo" });

    const handleSubmitCreate = (values: EmployeeVacationCreate) => {
      handleCreate({
        ...values,
        dateFrom: parseDateToISO(getValues("dateFrom")),
        dateTo: parseDateToISO(getValues("dateTo")),
      });
      handleClose();
    };

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New vacation</ModalHeader>
            <form onSubmit={handleSubmit(handleSubmitCreate)}>
              <ModalBody>
                <Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3}>
                        <FormLabel>From</FormLabel>
                        <HookDatePicker
                          control={control}
                          name={"dateFrom"}
                          maxDate={watchDateTo}
                        />
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>To</FormLabel>
                        <HookDatePicker
                          control={control}
                          name={"dateTo"}
                          minDate={watchDateFrom}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button type={"submit"} mr={3}>
                  Create
                </Button>
                <Button colorScheme="gray" onClick={handleClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
