import React from "react";
import {
  Flex,
  Text,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  MenuItem,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavHoverBox } from "./NavHoverBox";
import { IconType } from "react-icons";
import { useHistory } from "react-router-dom";

interface INavItemProps {
  icon: IconType;
  title: string;
  description?: string;
  to?: string;
  active?: boolean;
  navSize: string;
  isAccordion?: boolean;
}

export const NavItem: React.FC<INavItemProps> = ({
  icon,
  title,
  description,
  active = false,
  navSize,
  to = "/app/contacts",
  isAccordion = false,
}) => {
  const history = useHistory();
  const hoverBgColor = useColorModeValue("#cbecec", "teal.500");
  const bgColor = useColorModeValue("#cbecec", "teal.500");
  const textColorActive = useColorModeValue("teal.500", "dark");
  const textColor = useColorModeValue("gray.500", "dark");
  return (
    <>
      {" "}
      {isAccordion ? (
        <AccordionItem>
          <Flex
            mt={2}
            flexDir="column"
            w="100%"
            alignItems={navSize == "small" ? "center" : "flex-start"}
          >
            <Menu placement="right">
              <AccordionButton>
                <Link
                  bgColor={active ? "#cbecec" : ""}
                  p={3}
                  pt={2}
                  pb={2}
                  borderRadius={6}
                  _hover={{ textDecor: "none", backgroundColor: hoverBgColor }}
                  w={navSize == "large" ? "100%" : ""}
                >
                  <Box w="100%">
                    <Flex>
                      <Icon
                        pt={1}
                        as={icon}
                        fontSize="xl"
                        color={active ? "teal.500" : "gray.500"}
                      />
                      <Text
                        ml={6}
                        display={navSize == "small" ? "none" : "flex"}
                      >
                        {title}
                      </Text>
                    </Flex>
                  </Box>
                </Link>
              </AccordionButton>
              <AccordionPanel pl={5} w={"100%"} mt={3}>
                <Link
                  bgColor={active ? "#cbecec" : ""}
                  p={3}
                  pt={2}
                  pb={2}
                  onClick={() => history.push(to)}
                  borderRadius={6}
                  _hover={{ textDecor: "none", backgroundColor: "#cbecec" }}
                  w={navSize == "large" ? "100%" : ""}
                >
                  <MenuButton w="100%">
                    <Flex>
                      <Icon
                        pt={1}
                        as={icon}
                        fontSize="xl"
                        color={active ? "teal.500" : "gray.500"}
                      />
                      <Text
                        ml={6}
                        display={navSize == "small" ? "none" : "flex"}
                      >
                        {title}
                      </Text>
                    </Flex>
                  </MenuButton>
                </Link>
              </AccordionPanel>

              {/*<MenuList py={0} border="none" w={200} h={200} ml={5}>*/}
              {/*  <NavHoverBox title={title} icon={icon} description={description} />*/}
              {/*</MenuList>*/}
            </Menu>
          </Flex>
        </AccordionItem>
      ) : (
        <Flex
          mt={3}
          flexDir="column"
          w="100%"
          alignItems={navSize == "small" ? "center" : "flex-start"}
        >
          <Menu placement="right">
            <Link
              bgColor={active ? bgColor : ""}
              p={3}
              pt={2}
              pb={1}
              onClick={() => history.push(to)}
              borderRadius={8}
              _hover={{ textDecor: "none", backgroundColor: hoverBgColor }}
              w={navSize == "large" ? "100%" : ""}
            >
              <MenuButton w="100%">
                <Flex alignItems={"center"}>
                  <Icon
                    // pt={1}
                    as={icon}
                    fontSize="xl"
                    color={active ? textColorActive : textColor}
                  />
                  <Text
                    ml={6}
                    display={navSize == "small" ? "none" : "flex"}
                    fontWeight="600"
                    color={active ? textColorActive : textColor}
                  >
                    {title}
                  </Text>
                </Flex>
              </MenuButton>
            </Link>
          </Menu>
        </Flex>
      )}
    </>
  );
};
