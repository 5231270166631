import React, { useState } from "react";
import {
  Box,
  Divider,
  Heading,
  Input,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { PayoneerUploadData } from "../types";
import { formatCurrency } from "../../../../common/utils";
import { FileInput } from "../../../../common/components";
import { useForm } from "react-hook-form";

interface IUploadDataUserCardsProps {
  uploadFile: (payoneerUploadData: PayoneerUploadData) => void;
  accountId: number;
  userName: string;
  isLoading: boolean;
}

export const UploadDataUserCard: React.FC<IUploadDataUserCardsProps> = ({
  uploadFile,
  accountId,
  userName,
  isLoading,
}) => {
  const onUploadFile = (file: File) => {
    uploadFile({ accountId, file });
  };
  const { register } = useForm();
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} m={3}>
      <Box mb={4}>
        <Heading size="md" mb={2}>
          {userName}
        </Heading>
        <Divider />
      </Box>

      <Box>
        <form>
          <FileInput
            register={register}
            name={`fileUploader${accountId}`}
            fileChanged={(file) => onUploadFile(file)}
            placeholder={"Select file..."}
            isLoading={isLoading}
          />
        </form>
      </Box>
    </Box>
  );
};
