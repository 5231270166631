// Function for using in redux-toolkit slice, if we want to register few actions on same state changes
import { Action } from "redux";

// prettier-ignore
const isAnyOf =
	(...matchers: Array<string | { type: string }>) =>
		(action: Action): boolean =>
			matchers.some((matcher) =>
				typeof matcher === "string"
					? matcher === action.type
					: matcher.type === action.type,
			);

export default isAnyOf;
