import { Transactions } from "./Transactions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { PagedRequest } from "../../../common/models";
import {
  bindPayoneerTransaction,
  createPayoneerTransaction,
  getPayoneerTransactions,
  resetPayoneerTransactions,
  updatePayoneerTransaction,
} from "./store/actions";
import {
  PayoneerTransaction,
  PayoneerTransactionBinding,
  PayoneerTransactionCreate,
  PayoneerTransactionsFilters,
  PayoneerTransactionsRequest,
  PayoneerTransactionUpdate,
} from "./types";
import {
  getEmployeesDirectory,
  getSalaryPaymentsDirectory,
} from "../../../store/actions/directories";
import { PAYONEER_ACCOUNTS } from "./const/payoneer-transactions.const";

export const TransactionsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;
  const [filters, setFilters] = useState<PayoneerTransactionsFilters>({
    account: PAYONEER_ACCOUNTS.Aragorn,
  });

  // Queries
  const payoneerTransactions = useSelector(
    (state: RootStore) => state.payoneer.transactions.payoneerTransactions
  );
  const payoneerTransactionLoading = useSelector(
    (state: RootStore) => state.payoneer.transactions.loading
  );

  const employees = useSelector(
    (state: RootStore) => state.directories.employees.employees
  );

  const salaryPayments = useSelector(
    (state: RootStore) => state.directories.salaryPayments.salaryPayments
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetPayoneerTransactions = (payload: PayoneerTransactionsRequest) =>
    dispatch(getPayoneerTransactions(payload));
  const onGetEmployeesDirectory = () => dispatch(getEmployeesDirectory());
  const onGetSalaryPaymentsDirectory = (employeeId: number) =>
    dispatch(getSalaryPaymentsDirectory(employeeId));
  const onUpdatePayoneerTransaction = (
    payoneerTransaction: PayoneerTransactionUpdate
  ) =>
    dispatch(
      updatePayoneerTransaction({
        payoneerTransaction,
        request: { offset: offset, take: pageSize, filters },
      })
    );
  const onUpdatePayoneerTransactionBinding = (
    transactionBinding: PayoneerTransactionBinding
  ) =>
    dispatch(
      bindPayoneerTransaction({
        transactionBinding,
        request: { offset: offset, take: pageSize, filters },
      })
    );
  const onCreatePayoneerTransaction = (
    payoneerTransaction: PayoneerTransactionCreate
  ) =>
    dispatch(
      createPayoneerTransaction({
        payoneerTransaction,
        request: { offset: offset, take: pageSize, filters },
      })
    );
  const onResetPayoneerTransactions = () =>
    dispatch(resetPayoneerTransactions());

  useEffect(() => {
    onGetEmployeesDirectory();
  }, []);

  const offsetChanged = (offset: number) => {
    onResetPayoneerTransactions();
    setOffset(offset);
    onGetPayoneerTransactions({
      offset: offset,
      take: pageSize,
      filters,
    });
  };

  const filtersChanged = (filters: PayoneerTransactionsFilters) => {
    setFilters({ ...filters });
    onGetPayoneerTransactions({
      offset: offset,
      take: pageSize,
      filters: { ...filters },
    });
  };

  return (
    <Transactions
      employees={employees}
      salaryPayments={salaryPayments}
      getSalaryPayments={onGetSalaryPaymentsDirectory}
      pagedPayoneerTransactions={payoneerTransactions}
      payoneerTransactionLoading={payoneerTransactionLoading}
      createPayoneerTransaction={onCreatePayoneerTransaction}
      updatePayoneerTransaction={onUpdatePayoneerTransaction}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      updatePayoneerTransactionBinding={onUpdatePayoneerTransactionBinding}
      filtersChanged={filtersChanged}
    />
  );
};
