// import { UploadData } from "./UploadData";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { uploadData } from "./store/actions";
import { UploadData } from "./UploadData";
import { PayoneerUploadData } from "./types";

export const UploadDataContainer: React.FC = () => {
  /// Queries
  const isLoading = useSelector(
    (state: RootStore) => state.payoneer.uploadData.loading
  );
  /// Actions
  const dispatch = useDispatch();
  const onUploadFile = (payload: PayoneerUploadData) =>
    dispatch(uploadData(payload));

  return <UploadData uploadFile={onUploadFile} isLoading={isLoading} />;
};
