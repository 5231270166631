import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { EmployeeVacation } from "../types";
import { Paged } from "../../../../../../common/models";
import { SharedPagination } from "../../../../../../common/components";
import { formatDate, parseDateToISO } from "../../../../../../common/utils";

interface IEmployeeVacationsListProps {
  pagedEmployeeVacations: Paged<EmployeeVacation>;
  showEdit: (employeeVacation: EmployeeVacation) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const EmployeeVacationsTable: React.FC<IEmployeeVacationsListProps> = ({
  pagedEmployeeVacations,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Used days</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedEmployeeVacations.entities.map((employeeVacation, index) => {
            return (
              <Tr key={index}>
                <Td>{formatDate(employeeVacation.dateFrom)}</Td>
                <Td>{formatDate(employeeVacation.dateTo)}</Td>
                <Td>{employeeVacation.usedDays}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(employeeVacation)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedEmployeeVacations.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
