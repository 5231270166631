import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getAddressTypesDirectory,
  getAddressTypesDirectoryError,
  getAddressTypesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/address-types`;

function* workerGetAddressTypes(
  action: ReturnType<typeof getAddressTypesDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getAddressTypesDirectorySuccess(result.value.entities));
    } else {
      yield put(getAddressTypesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getAddressTypesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchAddressTypesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getAddressTypesDirectory.type, workerGetAddressTypes);
}
