import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { PartnerCreate } from "../types";
import { HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { FileInput, ImageCropModal } from "../../../../common/components";

interface IPartnerCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (partner: PartnerCreate) => void;
  countries: DirectoryItem[];
  partnerTypes: DirectoryItem[];
  partnerIndustries: DirectoryItem[];
  addressTypes: DirectoryItem[];
  contacts: DirectoryItem[];
}

export const PartnerCreateModal: React.FC<IPartnerCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  countries,
  partnerTypes,
  partnerIndustries,
  addressTypes,
  contacts,
}) => {
  const {
    isOpen: isOpenImageCrop,
    onOpen: onOpenImageCrop,
    onClose: onCloseImageCrop,
  } = useDisclosure();

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: PartnerCreate) => {
    handleCreate({
      ...values,
      // birthDate: parseDateToString(getValues("birthDate")),
      logoData: fileForUpload,
    });
    handleClose();
  };

  const [fileForUpload, setFileForUpload] = useState<any>(null);
  const [fileForCropper, setFileForCropper] = useState<any>(null);

  const handleSetFileForCropper = (file: File) => {
    if (file) {
      setFileForCropper(URL.createObjectURL(file));
      onOpenImageCrop();
    }
  };

  const handleImageCropped = (image: string) => {
    setFileForUpload(image);
  };

  const handleClose = () => {
    reset();
    setFileForCropper(null);
    setFileForUpload(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New partner</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Flex>
                <Box w={"30%"}>
                  <Box pr={6}>
                    <Avatar
                      size="3xl"
                      src={
                        fileForUpload
                          ? fileForUpload
                          : "https://bit.ly/broken-link"
                      }
                      mb={6}
                    />
                    <FileInput
                      register={register}
                      name={"avatarInput"}
                      fileChanged={(file) => handleSetFileForCropper(file)}
                    />
                  </Box>
                </Box>
                <Box w={"70%"}>
                  <Flex mb={3}>
                    <FormControl pr={3} isInvalid={errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input {...register("name", { required: true })} />
                      <FormErrorMessage>
                        {errors.name ? "Name is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3} isInvalid={errors.responsibleContactId}>
                      <FormLabel>Responsible contact</FormLabel>
                      <HookSelect
                        control={control}
                        name={"responsibleContactId"}
                        options={contacts}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.responsibleContactId
                          ? "Responsible contact is required"
                          : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Email</FormLabel>
                      <Input type={"email"} {...register("email")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Phone</FormLabel>
                      <Input {...register("phone")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Additional phone</FormLabel>
                      <Input {...register("additionalPhone")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Fax</FormLabel>
                      <Input {...register("fax")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Address</FormLabel>
                      <Input {...register("address")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Zip code</FormLabel>
                      <Input {...register("zipCode")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Address type</FormLabel>
                      <HookSelect
                        control={control}
                        name={"addressTypeId"}
                        options={addressTypes}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Country</FormLabel>
                      <HookSelect
                        control={control}
                        name={"countryId"}
                        options={countries}
                      />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Type</FormLabel>
                      <HookSelect
                        control={control}
                        name={"partnerTypeId"}
                        options={partnerTypes}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Industry</FormLabel>
                      <HookSelect
                        control={control}
                        name={"partnerIndustryId"}
                        options={partnerIndustries}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {/*<Button onClick={() => onOpenImageCrop()}>Test crop</Button>*/}
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <ImageCropModal
        handleImageCropped={handleImageCropped}
        sourceImage={fileForCropper}
        isOpen={isOpenImageCrop}
        onOpen={onOpenImageCrop}
        onClose={onCloseImageCrop}
      />
    </>
  );
};
