import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { HookSelect } from "../../../../common/components/libs";
import { useForm } from "react-hook-form";
import { DirectoryItem } from "../../../../common/types";
import {
  PayoneerTransactionBinding,
  PayoneerTransactionUpdate,
} from "../types";

interface ITransactionBindingModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedTransaction: PayoneerTransactionUpdate | null;
  handleBinding: (transactionBinding: PayoneerTransactionBinding) => void;
  getSalaryPayments: (employeeId: number) => void;
  employees: DirectoryItem[];
  salaryPayments: DirectoryItem[];
}

export const TransactionBindingModal: React.FC<ITransactionBindingModalProps> =
  ({
    isOpen,
    onOpen,
    onClose,
    selectedTransaction,
    getSalaryPayments,
    handleBinding,
    employees,
    salaryPayments,
  }) => {
    const {
      register,
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
      getValues,
    } = useForm();

    const watchEmployee = watch("employeeId");

    const handleSubmitBinding = (values: PayoneerTransactionBinding) => {
      handleBinding({
        ...values,
        transactionId: selectedTransaction ? selectedTransaction.id : null,
      });
      reset();
      onClose();
    };

    useEffect(() => {
      reset({
        ...selectedTransaction,
      });
    }, [selectedTransaction]);

    useEffect(() => {
      if (watchEmployee) {
        getSalaryPayments(getValues().employeeId);
      }
    }, [watchEmployee]);

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Bind transaction</ModalHeader>
            <form onSubmit={handleSubmit(handleSubmitBinding)}>
              <ModalBody>
                <Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3} isInvalid={errors.employeeId}>
                        <FormLabel>Employee</FormLabel>
                        <HookSelect
                          control={control}
                          name={"employeeId"}
                          options={employees}
                          rules={{ required: true }}
                        />
                        <FormErrorMessage>
                          {errors.test ? "Employee is required" : ""}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl pl={3} isInvalid={errors.salaryPaymentId}>
                        <FormLabel>Salary payment</FormLabel>
                        <HookSelect
                          control={control}
                          name={"salaryPaymentId"}
                          options={salaryPayments}
                          rules={{ required: true }}
                        />
                        <FormErrorMessage>
                          {errors.salaryPaymentId
                            ? "Salary payment is required"
                            : ""}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button type={"submit"} mr={3}>
                  Bind
                </Button>
                <Button colorScheme="gray" onClick={handleClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
