import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getPartnersDirectory,
  getPartnersDirectoryError,
  getPartnersDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  partners: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  partners: [],
  loaded: false,
};

const partnersDirectorySlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnersDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPartnersDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.partners = action.payload;
      })
      .addCase(getPartnersDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default partnersDirectorySlice.reducer;
