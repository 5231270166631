import { createAction } from "@reduxjs/toolkit";
import {
  LoginPayload,
  LoginResponseSuccess,
  PayloadError,
} from "../../../common/types";

export const login = createAction<LoginPayload>("LOGIN");
export const loginSuccess = createAction<LoginResponseSuccess>(
  "LOGIN_SUCCESS"
);
export const loginError = createAction<PayloadError[]>("LOGIN_ERROR");

export const logout = createAction("LOGOUT");
