import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getCountriesDirectory,
  getCountriesDirectoryError,
  getCountriesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/countries`;

function* workerGetCountries(action: ReturnType<typeof getCountriesDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getCountriesDirectorySuccess(result.value.entities));
    } else {
      yield put(getCountriesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getCountriesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchCountriesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getCountriesDirectory.type, workerGetCountries);
}
