import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../common/types";

export const connectUpworkAccount = createAction<string>(
  "UPWORK_ACCOUNTS/CONNECT_UPWORK_ACCOUNT"
);

export const connectUpworkAccountSuccess = createAction<any>(
  "UPWORK_ACCOUNTS/CONNECT_UPWORK_ACCOUNT_SUCCESS"
);

export const connectUpworkAccountError = createAction<PayloadError[]>(
  "UPWORK_ACCOUNTS/CONNECT_UPWORK_ACCOUNT_ERROR"
);

export const disconnectUpworkAccount = createAction(
  "UPWORK_ACCOUNTS/UPWORK_ACCOUNT"
);

export const disconnectUpworkAccountSuccess = createAction<any>(
  "UPWORK_ACCOUNTS/UPWORK_ACCOUNT_SUCCESS"
);

export const disconnectUpworkAccountError = createAction<PayloadError[]>(
  "UPWORK_ACCOUNTS/UPWORK_ACCOUNT_ERROR"
);
