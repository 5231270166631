import React from "react";
import { Box, Button, chakra, Flex, Heading, Spacer } from "@chakra-ui/react";
import { PAYONEER_ACCOUNTS } from "../Transactions/const/payoneer-transactions.const";
import { PayoneerUploadData } from "./types";
import { UploadDataUserCard } from "./components/UploadDataUserCard";
import { FaArrowRight } from "react-icons/all";
import { useHistory } from "react-router-dom";

const CFaArrowRight = chakra(FaArrowRight);

interface IUploadDataProps {
  uploadFile: (payoneerUploadData: PayoneerUploadData) => void;
  isLoading: boolean;
}

export const UploadData: React.FC<IUploadDataProps> = ({
  uploadFile,
  isLoading,
}) => {
  const history = useHistory();
  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Upload payoneer transactions</Heading>
        <Spacer />
        <Button
          colorScheme={"gray"}
          onClick={() => history.push("./transactions")}
        >
          <CFaArrowRight mr={2} />
          Back
        </Button>
      </Flex>
      <Flex>
        <UploadDataUserCard
          userName={"Aragorn"}
          accountId={PAYONEER_ACCOUNTS.Aragorn}
          uploadFile={uploadFile}
          isLoading={isLoading}
        />
        <UploadDataUserCard
          userName={"Gandalf"}
          accountId={PAYONEER_ACCOUNTS.Gandalf}
          uploadFile={uploadFile}
          isLoading={isLoading}
        />
      </Flex>
    </>
  );
};
