import { createSlice } from "@reduxjs/toolkit";
import { Partner, PartnerProfile } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  createPartner,
  createPartnerError,
  createPartnerSuccess,
  getPartner,
  getPartnerError,
  getPartners,
  getPartnersError,
  getPartnersSuccess,
  getPartnerSuccess,
  resetPartners,
  resetSelectedPartner,
  updatePartner,
  updatePartnerError,
  updatePartnerSuccess,
} from "./actions";

const initialPartner: PartnerProfile = {
  id: 0,
  partnerIndustryName: null,
  partnerTypeName: null,
  countryName: null,
  zipCode: "",
  address: "",
  addressTypeId: null,
  addressTypeName: null,
  fax: "",
  email: "",
  name: "",
  partnerIndustryId: null,
  partnerTypeId: null,
  countryId: null,
  logoStorageHash: "",
  phone: "",
  responsibleContactId: 0,
  responsibleContactName: "",
};

type InitialState = BaseState & {
  partners: Paged<Partner>;
  selectedPartner: PartnerProfile;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  partners: {
    total: 0,
    entities: [],
  },
  selectedPartner: initialPartner,
  loaded: false,
};

const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartners, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPartnersSuccess, (state, action) => {
        state.partners = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getPartnersError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(getPartner, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPartnerSuccess, (state, action) => {
        state.selectedPartner = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getPartnerError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updatePartner, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updatePartnerSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updatePartnerError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createPartner, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createPartnerSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createPartnerError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetSelectedPartner, (state) => {
        state.selectedPartner = { ...initialPartner };
      })
      .addCase(resetPartners, (state) => {
        state.partners.entities = [];
      });
  },
});

export default partnersSlice.reducer;
