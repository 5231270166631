import { call, put, takeEvery, ForkEffect } from "redux-saga/effects";
import {
  login,
  loginSuccess,
  loginError,
} from "../../actions/auth/auth.actions";
import API from "../../../common/api/api.base";
import { LoginResponseSuccess } from "../../../common/types";
import { push } from "connected-react-router";

function* workerLogin(action: ReturnType<typeof login>) {
  try {
    const result: LoginResponseSuccess = yield call(
      API.post,
      `api/auth/login`,
      action.payload
    );
    if (result.success) {
      yield put(loginSuccess(result));
      yield put(push("/app/contacts"));
    } else {
      yield put(loginError(result.errors));
    }
  } catch (error) {
    yield put(loginError([{ key: "Error", errorMessage: "Network error" }]));
  }
}

// prettier-ignore
export function* watchLoginSaga(): Generator<ForkEffect<never>,
    void,
    unknown> {
    yield takeEvery(login.type, workerLogin);
}
