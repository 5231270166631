import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getGendersDirectory = createAction("DIRECTORIES/GET_GENDERS");

export const getGendersDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_GENDERS_SUCCESS"
);

export const getGendersDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_GENDERS_ERROR"
);
