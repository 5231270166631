import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Contact, ContactCreate } from "../types";
import { HookDatePicker, HookSelect } from "../../../common/components/libs";
import { parseDateToISO } from "../../../common/utils";
import { FileInput, ImageCropModal } from "../../../common/components";
import { DirectoryItem } from "../../../common/types";

interface IContactCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (contact: ContactCreate) => void;
  genders: DirectoryItem[];
  cities: DirectoryItem[];
  contactTypes: DirectoryItem[];
}

export const ContactCreateModal: React.FC<IContactCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  genders,
  cities,
  contactTypes,
}) => {
  const {
    isOpen: isOpenImageCrop,
    onOpen: onOpenImageCrop,
    onClose: onCloseImageCrop,
  } = useDisclosure();

  const { register, control, handleSubmit, setValue, reset, getValues } =
    useForm();

  const handleSubmitCreate = (values: Contact) => {
    handleCreate({
      ...values,
      birthDate: parseDateToISO(getValues("birthDate")),
      avatarData: fileForUpload,
    });
    handleClose();
  };

  const [fileForUpload, setFileForUpload] = useState<any>(null);
  const [fileForCropper, setFileForCropper] = useState<any>(null);

  const handleSetFileForCropper = (file: File) => {
    if (file) {
      setFileForCropper(URL.createObjectURL(file));
      onOpenImageCrop();
    }
  };

  const handleImageCropped = (image: string) => {
    setFileForUpload(image);
  };

  const handleClose = () => {
    reset();
    setFileForCropper(null);
    setFileForUpload(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New contact</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Flex>
                <Box w={"30%"}>
                  <Box pr={6}>
                    <Avatar
                      size="3xl"
                      src={
                        fileForUpload
                          ? fileForUpload
                          : "https://bit.ly/broken-link"
                      }
                      mb={6}
                    />
                    <FileInput
                      register={register}
                      name={"avatarInput"}
                      fileChanged={(file) => handleSetFileForCropper(file)}
                    />
                  </Box>
                </Box>
                <Box w={"70%"}>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="firstName" pr={3}>
                        <FormLabel>First name</FormLabel>
                        <Input {...register("firstName", { required: true })} />
                      </FormControl>
                      <FormControl id="middleName" pl={3} pr={3}>
                        <FormLabel>Middle name</FormLabel>
                        <Input {...register("middleName")} />
                      </FormControl>
                      <FormControl id="lastName" pl={3}>
                        <FormLabel>Last name</FormLabel>
                        <Input {...register("lastName", { required: true })} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="birthDate" pr={3}>
                        <FormLabel>Birth date</FormLabel>
                        <HookDatePicker control={control} name={"birthDate"} />
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>Phone</FormLabel>
                        <Input {...register("phone")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="email" pr={3}>
                        <FormLabel>Email</FormLabel>
                        <Input
                          type={"email"}
                          {...register("email", { required: true })}
                        />
                      </FormControl>
                      <FormControl id="address" pl={3}>
                        <FormLabel>Address</FormLabel>
                        <Input {...register("address")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="birthDate" pr={3}>
                        <FormLabel>Zip code</FormLabel>
                        <Input {...register("zipCode")} />
                      </FormControl>
                      <FormControl id="skype" pl={3}>
                        <FormLabel>Skype</FormLabel>
                        <Input {...register("skype")} />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3}>
                        <FormLabel>Gender</FormLabel>
                        <HookSelect
                          control={control}
                          name={"genderId"}
                          options={genders}
                        />
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>Contact type</FormLabel>
                        <HookSelect
                          control={control}
                          name={"contactTypeId"}
                          options={contactTypes}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <FormControl>
                      <FormLabel>City</FormLabel>
                      <HookSelect
                        control={control}
                        name={"cityId"}
                        options={cities}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {/*<Button onClick={() => onOpenImageCrop()}>Test crop</Button>*/}
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <ImageCropModal
        handleImageCropped={handleImageCropped}
        sourceImage={fileForCropper}
        isOpen={isOpenImageCrop}
        onOpen={onOpenImageCrop}
        onClose={onCloseImageCrop}
      />
    </>
  );
};
