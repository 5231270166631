// Get all
import { createAction } from "@reduxjs/toolkit";
import { PagedRequest, Paged } from "../../../../common/models";
import { PayloadError } from "../../../../common/types";
import { Employee, EmployeeCreate, EmployeeUpdate } from "../types";

export const getEmployees = createAction<PagedRequest>(
  "WORK_EMPLOYEES/GET_EMPLOYEES"
);

export const getEmployeesSuccess = createAction<Paged<any>>(
  "WORK_EMPLOYEES/GET_EMPLOYEES_SUCCESS"
);

export const getEmployeesError = createAction<PayloadError[]>(
  "WORK_EMPLOYEES/GET_EMPLOYEES_ERROR"
);

// Create
export const createEmployee = createAction<{
  employee: EmployeeCreate;
  request: PagedRequest;
}>("WORK_EMPLOYEES/CREATE_EMPLOYEE");

export const createEmployeeSuccess = createAction<Employee>(
  "WORK_EMPLOYEES/CREATE_EMPLOYEE_SUCCESS"
);

export const createEmployeeError = createAction<PayloadError[]>(
  "WORK_EMPLOYEES/CREATE_EMPLOYEE_ERROR"
);

// Update
export const updateEmployee = createAction<{
  employee: EmployeeUpdate;
  request: PagedRequest;
}>("WORK_EMPLOYEES/UPDATE_EMPLOYEE");

export const updateEmployeeSuccess = createAction(
  "WORK_EMPLOYEES/UPDATE_EMPLOYEE_SUCCESS"
);

export const updateEmployeeError = createAction<PayloadError[]>(
  "WORK_EMPLOYEES/UPDATE_EMPLOYEE_ERROR"
);

export const resetEmployees = createAction("WORK_EMPLOYEES/RESET");
