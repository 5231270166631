import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../../common/api/api.base";
import {
  checkEmployeeVacation,
  checkEmployeeVacationError,
  checkEmployeeVacationSuccess,
  createEmployeeVacation,
  createEmployeeVacationError,
  createEmployeeVacationSuccess,
  getEmployeeVacations,
  getEmployeeVacationsError,
  getEmployeeVacationsSuccess,
  updateEmployeeVacation,
  updateEmployeeVacationError,
  updateEmployeeVacationSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../../../common/models/common";
import { EmployeeVacation } from "../types";

const apiUrl = `/api/employee/vacations`;

function* workerGetEmployeeVacations(
  action: ReturnType<typeof getEmployeeVacations>
) {
  try {
    const result: ApiResponse<Paged<EmployeeVacation>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeeVacationsSuccess(result.value));
    } else {
      yield put(getEmployeeVacationsError(result.errors));
    }
  } catch (e) {
    yield put(
      getEmployeeVacationsError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerCheckEmployeeVacationDays(
  action: ReturnType<typeof checkEmployeeVacation>
) {
  try {
    const result: ApiResponse<{ days: number }> = yield call(
      API.get,
      `${apiUrl}/check`,
      action.payload
    );
    if (result.success) {
      yield put(checkEmployeeVacationSuccess(result.value));
    } else {
      yield put(checkEmployeeVacationError(result.errors));
    }
  } catch (e) {
    yield put(
      checkEmployeeVacationError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerUpdateEmployeeVacation(
  action: ReturnType<typeof updateEmployeeVacation>
) {
  try {
    const result: ApiResponse<EmployeeVacation> = yield call(
      API.put,
      apiUrl,
      action.payload.employeeVacation
    );
    if (result.success) {
      yield put(updateEmployeeVacationSuccess());
      yield put(getEmployeeVacations(action.payload.request));
    } else {
      yield put(updateEmployeeVacationError(result.errors));
    }
  } catch (e) {
    yield put(
      updateEmployeeVacationError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerCreateEmployeeVacation(
  action: ReturnType<typeof createEmployeeVacation>
) {
  try {
    const result: ApiResponse<EmployeeVacation> = yield call(
      API.post,
      apiUrl,
      action.payload.employeeVacation
    );
    if (result.success) {
      yield put(createEmployeeVacationSuccess(result.value));
      // Call update contacts list
      yield put(getEmployeeVacations(action.payload.request));
    } else {
      yield put(createEmployeeVacationError(result.errors));
    }
  } catch (e) {
    yield put(
      createEmployeeVacationError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchEmployeeVacationsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getEmployeeVacations.type, workerGetEmployeeVacations);
  yield takeEvery(createEmployeeVacation.type, workerCreateEmployeeVacation);
  yield takeEvery(updateEmployeeVacation.type, workerUpdateEmployeeVacation);
  yield takeEvery(checkEmployeeVacation.type, workerCheckEmployeeVacationDays);
}
