import { Team, TeamCreate, TeamUpdate } from "./types";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import { RootStore } from "../../../store";
import { PagedRequest } from "../../../common/models";
import { createTeam, getTeams, resetTeams, updateTeam } from "./store/actions";
import { Teams } from "./Teams";
import { getEmployeesDirectory } from "../../../store/actions/directories";

export const TeamsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  useEffect(() => {
    onGetEmployeesDirectory();
  }, []);

  // Queries
  const teams = useSelector((state: RootStore) => state.teams.teams);
  const teamLoading = useSelector((state: RootStore) => state.teams.loading);
  const employees = useSelector(
    (state: RootStore) => state.directories.employees.employees
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetTeams = (payload: PagedRequest) => dispatch(getTeams(payload));
  const onGetEmployeesDirectory = () => dispatch(getEmployeesDirectory());
  const onUpdateTeam = (team: TeamUpdate) =>
    dispatch(
      updateTeam({
        team,
        request: { offset: offset, take: pageSize },
      })
    );
  const onCreateTeam = (team: TeamCreate) =>
    dispatch(
      createTeam({
        team,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetTeams = () => dispatch(resetTeams());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetTeams({ offset: offset, take: pageSize });
  };

  return (
    <Teams
      employees={employees}
      pagedTeams={teams}
      teamLoading={teamLoading}
      createTeam={onCreateTeam}
      updateTeam={onUpdateTeam}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
