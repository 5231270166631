import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { UpworkEmployeeSnapshots } from "../types";
import { EmployeeSnapshotCard } from "./EmployeeSnapshotCard";
import { SRLWrapper } from "simple-react-lightbox";

interface IEmployeeSnapshotsListProps {
  employeeSnapshots: UpworkEmployeeSnapshots;
}

export const EmployeeSnapshotsList: React.FC<IEmployeeSnapshotsListProps> = ({
  employeeSnapshots,
}) => {
  return (
    <SRLWrapper>
      <SimpleGrid columns={5} spacing={3}>
        {employeeSnapshots.entities.map((employeeSnapshot, index) => {
          return (
            <EmployeeSnapshotCard
              key={index}
              employeeSnapshot={employeeSnapshot}
            />
          );
        })}
      </SimpleGrid>
    </SRLWrapper>
  );
};
