import { createSlice } from "@reduxjs/toolkit";
import { EmployeePayment } from "../types";
import { BaseState, Paged } from "../../../../../../common/models/common";
import {
  createEmployeePayment,
  createEmployeePaymentError,
  createEmployeePaymentSuccess,
  getEmployeePayments,
  getEmployeePaymentsError,
  getEmployeePaymentsSuccess,
  resetEmployeePayments,
  updateEmployeePayment,
  updateEmployeePaymentError,
  updateEmployeePaymentSuccess,
} from "./actions";

type InitialState = BaseState & {
  employeePayments: Paged<EmployeePayment>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  employeePayments: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const employeePaymentsSlice = createSlice({
  name: "employeePayments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeePayments, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeePaymentsSuccess, (state, action) => {
        state.employeePayments = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getEmployeePaymentsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateEmployeePayment, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateEmployeePaymentSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateEmployeePaymentError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createEmployeePayment, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createEmployeePaymentSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createEmployeePaymentError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetEmployeePayments, (state) => {
        state.employeePayments.entities = [];
      });
  },
});

export default employeePaymentsSlice.reducer;
