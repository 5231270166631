import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getCitiesDirectory,
  getCitiesDirectoryError,
  getCitiesDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  cities: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  cities: [],
  loaded: false,
};

const citiesDirectorySlice = createSlice({
  name: "cities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCitiesDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getCitiesDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.cities = action.payload;
      })
      .addCase(getCitiesDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default citiesDirectorySlice.reducer;
