import React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import {
  UserProfile,
  UserProfileUpdate,
} from "../../../common/types/profile.types";
import { useForm } from "react-hook-form";

interface IProfileUpworkProps {
  getUpworkLink: () => void;
  profile: UserProfile;
  disconnectUpworkAccount: () => void;
}

export const ProfileUpwork: React.FC<IProfileUpworkProps> = ({
  getUpworkLink,
  profile,
  disconnectUpworkAccount,
}) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      pt={8}
      pb={8}
      mr={4}
    >
      {profile.hasUpworkProfile ? (
        <Flex>
          {profile.upworkProfilePhotoString && (
            <Avatar size="2xl" src={profile.upworkProfilePhotoString} />
          )}
          <Box ml={8}>
            <Heading as={"h5"}>{profile.upworkProfileName}</Heading>
            <Text>City: {profile.upworkProfileCity}</Text>
            <Text mb={2}>Country: {profile.upworkProfileCountry}</Text>
            <Button
              colorScheme="gray"
              onClick={() => disconnectUpworkAccount()}
            >
              Disconnect account
            </Button>
          </Box>
        </Flex>
      ) : (
        <Button colorScheme="teal" onClick={() => getUpworkLink()}>
          Connect account
        </Button>
      )}
    </Box>
  );
};
