import { cloneElement, useEffect, useState } from "react";
import React from "react";
import { Circle, Skeleton, Spinner } from "@chakra-ui/react";
import { store } from "../../../store";

interface IAuthImageProps {
  url: string;
  useSkeleton?: boolean;
  childComponent: React.ReactElement;
  attributeName?: string;

  [x: string]: any;
}

export const AuthImage: React.FC<IAuthImageProps> = ({
  url,
  useSkeleton = true,
  childComponent,
  attributeName = "src",
  ...rest
}) => {
  const { response, loading, error } = useFetchImagesAuthorized(url);
  const clonedImage = cloneElement(childComponent, {
    [attributeName]: response,
    ...rest,
  });

  return loading && useSkeleton ? (
    <Skeleton rounded={"999px"} isLoaded={!loading}>
      {childComponent}
    </Skeleton>
  ) : (
    clonedImage
  );
};

const useFetchImagesAuthorized = (url: string) => {
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const token = store.getState().auth.user.accessToken;
  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      try {
        await fetch(process.env.REACT_APP_BASE_URL + url, {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + token,
            responseType: "blob",
          }),
        })
          .then((response) => response.blob())
          .then(function (myBlob) {
            const objectURL = URL.createObjectURL(myBlob);
            setResponse(objectURL);
          });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch().then();
  }, [url]);
  return { response, error, loading };
};
