import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../common/models";
import { Employee, EmployeeCreate, EmployeeUpdate } from "./types";
import {
  EmployeeCreateModal,
  EmployeeEditModal,
  EmployeesTable,
} from "./components";
import { DirectoryItem } from "../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IEmployeesProps {
  pagedEmployees: Paged<Employee>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createEmployee: (employee: EmployeeCreate) => void;
  updateEmployee: (employee: EmployeeUpdate) => void;
  employeeLoading: boolean;
  jobs: DirectoryItem[];
  departments: DirectoryItem[];
  contacts: DirectoryItem[];
}

export const Employees: React.FC<IEmployeesProps> = ({
  pagedEmployees,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createEmployee,
  updateEmployee,
  employeeLoading,
  jobs,
  departments,
  contacts,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null
  );
  const {
    isOpen: isOpenEmployeeEdit,
    onOpen: onOpenEmployeeEdit,
    onClose: onCloseEmployeeEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenEmployeeCreate,
    onOpen: onOpenEmployeeCreate,
    onClose: onCloseEmployeeCreate,
  } = useDisclosure();

  const handleOpenEdit = (employee: Employee) => {
    setSelectedEmployee(employee);
    onOpenEmployeeEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Employees</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenEmployeeCreate()}>
            <CFaPlus mr={2} /> Add employee
          </Button>
        </Box>
      </Flex>

      <EmployeesTable
        pagedEmployees={pagedEmployees}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <EmployeeCreateModal
        jobs={jobs}
        departments={departments}
        contacts={contacts}
        handleCreate={createEmployee}
        isOpen={isOpenEmployeeCreate}
        onOpen={onOpenEmployeeCreate}
        onClose={onCloseEmployeeCreate}
      />

      <EmployeeEditModal
        jobs={jobs}
        departments={departments}
        contacts={contacts}
        handleUpdate={updateEmployee}
        selectedEmployee={selectedEmployee}
        isOpen={isOpenEmployeeEdit}
        onOpen={onOpenEmployeeEdit}
        onClose={onCloseEmployeeEdit}
      />
    </>
  );
};
