import React from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
} from "@chakra-ui/react";
import { PartnerProfile } from "../types";
import { AuthImage, LabelValueList } from "../../../../common/components";

interface IPartnerViewModalProps {
  selectedPartner: PartnerProfile;
  isOpen: boolean;
  isLoading: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const PartnerViewModal: React.FC<IPartnerViewModalProps> = ({
  selectedPartner,
  isOpen,
  isLoading,
  onOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          <Flex>
            <Box w={"40%"}>
              {selectedPartner.logoStorageHash ? (
                <AuthImage
                  url={
                    `/api/storage/images/partner-logo/` +
                    selectedPartner.logoStorageHash
                  }
                  childComponent={<Avatar size="3xl" />}
                />
              ) : (
                <Avatar size="3xl" />
              )}
            </Box>

            <Box w={"60%"} pl={10}>
              <Skeleton isLoaded={!isLoading}>
                <Heading size={"md"}>{selectedPartner.name}</Heading>
                <Heading size={"sm"} mb={2} color={"gray.600"}>
                  {selectedPartner.email}
                </Heading>

                <LabelValueList
                  labelValueList={[
                    { label: "Phone", value: selectedPartner.phone },
                    {
                      label: "Responsible",
                      value: selectedPartner.responsibleContactName,
                    },
                    { label: "Fax", value: selectedPartner.fax },
                    { label: "Address", value: selectedPartner.address },
                    {
                      label: "Address type",
                      value: selectedPartner.addressTypeName,
                    },
                    { label: "Zip code", value: selectedPartner.zipCode },
                    { label: "Country", value: selectedPartner.countryName },
                    { label: "Type", value: selectedPartner.partnerTypeName },
                    {
                      label: "Industry",
                      value: selectedPartner.partnerIndustryName,
                    },
                  ]}
                />
              </Skeleton>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
