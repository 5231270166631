import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import {
  createSalary,
  getSalaries,
  resetSalaries,
  Salaries,
  SalariesRequest,
  SalaryCreate,
  SalaryUpdate,
  updateSalary,
} from "../../../../../common/modules";
import { getEmployeesDirectory } from "../../../../../store/actions/directories";
import { RootStore } from "../../../../../store";
import { useParams } from "react-router-dom";

export const EmployeeSalariesContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  const { employeeId } = useParams<{ employeeId: string }>();

  useEffect(() => {
    onGetEmployeesDirectory();
    return () => {
      onResetSalaries();
    };
  }, []);

  // Queries
  const salaries = useSelector((state: RootStore) => state.salaries.salaries);
  const salaryLoading = useSelector(
    (state: RootStore) => state.salaries.loading
  );
  const employees = useSelector(
    (state: RootStore) => state.directories.employees.employees
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetSalaries = (payload: SalariesRequest) =>
    dispatch(getSalaries(payload));
  const onGetEmployeesDirectory = () => dispatch(getEmployeesDirectory());
  const onUpdateSalary = (salary: SalaryUpdate) =>
    dispatch(
      updateSalary({
        salary,
        request: {
          offset: offset,
          take: pageSize,
          employeeId: employeeId ? +employeeId : null,
        },
      })
    );
  const onCreateSalary = (salary: SalaryCreate) =>
    dispatch(
      createSalary({
        salary,
        request: {
          offset: offset,
          take: pageSize,
          employeeId: employeeId ? +employeeId : null,
        },
      })
    );
  const onResetSalaries = () => dispatch(resetSalaries());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetSalaries({
      offset: offset,
      take: pageSize,
      employeeId: employeeId ? +employeeId : null,
    });
  };

  return (
    <Salaries
      showFilters={false}
      showHeader={false}
      pagedSalaries={salaries}
      salaryLoading={salaryLoading}
      createSalary={onCreateSalary}
      updateSalary={onUpdateSalary}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      employees={employees}
    />
  );
};
