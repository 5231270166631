import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import {
  createSalary,
  getSalaries,
  resetSalaries,
  Salaries,
  SalariesRequest,
  SalaryCreate,
  SalaryUpdate,
  updateSalary,
} from "../../../common/modules";
import { getEmployeesDirectory } from "../../../store/actions/directories";

export const SalariesContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<
    number | undefined | null
  >();
  const pageSize: number = 5;

  useEffect(() => {
    onGetEmployeesDirectory();
    return () => {
      onResetSalaries();
    };
  }, []);

  // Queries
  const salaries = useSelector((state: RootStore) => state.salaries.salaries);
  const salaryLoading = useSelector(
    (state: RootStore) => state.salaries.loading
  );
  const employees = useSelector(
    (state: RootStore) => state.directories.employees.employees
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetSalaries = (payload: SalariesRequest) =>
    dispatch(getSalaries(payload));
  const onGetEmployeesDirectory = () => dispatch(getEmployeesDirectory());
  const onUpdateSalary = (salary: SalaryUpdate) =>
    dispatch(
      updateSalary({
        salary,
        request: {
          offset: offset,
          take: pageSize,
          employeeId: selectedEmployeeId,
        },
      })
    );
  const onCreateSalary = (salary: SalaryCreate) =>
    dispatch(
      createSalary({
        salary,
        request: {
          offset: offset,
          take: pageSize,
          employeeId: selectedEmployeeId,
        },
      })
    );
  const onResetSalaries = () => dispatch(resetSalaries());

  const offsetChanged = (offset: number) => {
    onResetSalaries();
    setOffset(offset);
    onGetSalaries({
      offset: offset,
      take: pageSize,
      employeeId: selectedEmployeeId,
    });
  };

  const selectedEmployeeChanged = (employeeId: number) => {
    setSelectedEmployeeId(employeeId);
    onGetSalaries({
      offset: offset,
      take: pageSize,
      employeeId,
    });
  };

  return (
    <Salaries
      pagedSalaries={salaries}
      salaryLoading={salaryLoading}
      createSalary={onCreateSalary}
      updateSalary={onUpdateSalary}
      pageSize={pageSize}
      selectedEmployeeChanged={selectedEmployeeChanged}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      employees={employees}
    />
  );
};
