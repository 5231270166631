import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getSalaryPaymentsDirectory,
  getSalaryPaymentsDirectoryError,
  getSalaryPaymentsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/employees/salary-payments/dictionary/`;

function* workerGetSalaryPayments(
  action: ReturnType<typeof getSalaryPaymentsDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      { employeeId: action.payload }
    );
    if (result.success) {
      yield put(getSalaryPaymentsDirectorySuccess(result.value.entities));
    } else {
      yield put(getSalaryPaymentsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getSalaryPaymentsDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchSalaryPaymentsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getSalaryPaymentsDirectory.type, workerGetSalaryPayments);
}
