import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { Employee } from "../types";
import { Paged } from "../../../../common/models";
import { AuthImage, SharedPagination } from "../../../../common/components";
import { useHistory } from "react-router-dom";
import { EmployeeStatusIcon } from "./EmployeeStatusIcon";

interface IEmployeesListProps {
  pagedEmployees: Paged<Employee>;
  showEdit: (employee: Employee) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const EmployeesTable: React.FC<IEmployeesListProps> = ({
  pagedEmployees,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  const history = useHistory();

  const redirectToView = (employee: Employee) => {
    history.push({
      pathname: `../work/employee/${employee.id}`,
    });
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>
              <Center>Avatar</Center>
            </Th>
            <Th>Status</Th>
            <Th>Name</Th>
            <Th>Department</Th>
            <Th>Job</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedEmployees.entities.map((employee, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Center>
                    {employee.avatarStorageHash ? (
                      <AuthImage
                        url={
                          `/api/storage/images/contact-avatar/` +
                          employee.avatarStorageHash
                        }
                        childComponent={
                          <Avatar
                            size={"lg"}
                            style={{ cursor: "pointer" }}
                            onClick={() => redirectToView(employee)}
                          />
                        }
                      />
                    ) : (
                      <Avatar
                        size={"lg"}
                        style={{ cursor: "pointer" }}
                        onClick={() => redirectToView(employee)}
                      />
                    )}
                  </Center>
                </Td>
                <Td>
                  <EmployeeStatusIcon status={employee.status} />
                </Td>
                <Td>
                  {employee.firstName} {employee.lastName}
                </Td>
                <Td>{employee.departmentName}</Td>
                <Td>{employee.jobName}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(employee)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => redirectToView(employee)}
                  >
                    <FaEye />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedEmployees.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
