import { Milestones } from "./Milestones";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import React, { useState } from "react";
import { MilestoneCreate, MilestonesRequest, MilestoneUpdate } from "./types";
import {
  createMilestone,
  getMilestones,
  resetMilestones,
  updateMilestone,
} from "./store/actions";
import { useParams } from "react-router-dom";

export const MilestonesContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  const { projectId } = useParams<{ projectId: string }>();

  // Queries
  const milestones = useSelector(
    (state: RootStore) => state.projectMilestones.milestones
  );
  const milestoneLoading = useSelector(
    (state: RootStore) => state.projectMilestones.loading
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetMilestones = (payload: MilestonesRequest) =>
    dispatch(getMilestones(payload));
  const onUpdateMilestone = (milestone: MilestoneUpdate) =>
    dispatch(
      updateMilestone({
        milestone: { ...milestone, projectId },
        request: { offset: offset, take: pageSize, projectId },
      })
    );
  const onCreateMilestone = (milestone: MilestoneCreate) =>
    dispatch(
      createMilestone({
        milestone: { ...milestone, projectId },
        request: { offset: offset, take: pageSize, projectId },
      })
    );
  const onResetMilestones = () => dispatch(resetMilestones());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetMilestones({ offset: offset, take: pageSize, projectId });
  };

  return (
    <Milestones
      pagedMilestones={milestones}
      milestoneLoading={milestoneLoading}
      createMilestone={onCreateMilestone}
      updateMilestone={onUpdateMilestone}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
