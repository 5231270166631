// Get all
import { PagedRequest, Paged } from "../../../../common/models";
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { Contract, ContractCreate, ContractUpdate } from "../types";

export const getContracts = createAction<PagedRequest>(
  "CONTRACTS/GET_CONTRACTS"
);

export const getContractsSuccess = createAction<Paged<Contract>>(
  "CONTRACTS/GET_CONTRACTS_SUCCESS"
);

export const getContractsError = createAction<PayloadError[]>(
  "CONTRACTS/GET_CONTRACTS_ERROR"
);

// Get single
export const getContract = createAction("CONTRACTS/GET_CONTRACT");

export const getContractSuccess = createAction<any>(
  "CONTRACTS/GET_CONTRACT_SUCCESS"
);

export const getContractError = createAction<PayloadError[]>(
  "CONTRACTS/GET_CONTRACT_ERROR"
);

// Create
export const createContract = createAction<{
  contract: ContractCreate;
  request: PagedRequest;
}>("CONTRACTS/CREATE_CONTRACT");

export const createContractSuccess = createAction<Contract>(
  "CONTRACTS/CREATE_CONTRACT_SUCCESS"
);

export const createContractError = createAction<PayloadError[]>(
  "CONTRACTS/CREATE_CONTRACT_ERROR"
);

// Update
export const updateContract = createAction<{
  contract: ContractUpdate;
  request: PagedRequest;
}>("CONTRACTS/UPDATE_CONTRACT");

export const updateContractSuccess = createAction(
  "CONTRACTS/UPDATE_CONTRACT_SUCCESS"
);

export const updateContractError = createAction<PayloadError[]>(
  "CONTRACTS/UPDATE_CONTRACT_ERROR"
);

// Reset
export const resetSelectedContract = createAction(
  "CONTRACTS/RESET_SELECTED_CONTRACT"
);

export const resetContracts = createAction("CONTRACTS/RESET");
