import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRouting } from "./Routing";
import { Box, Center, Container, Flex } from "@chakra-ui/react";
import { Sidebar } from "../common/components";
import { RootStore } from "../store";
import SimpleReactLightbox from "simple-react-lightbox";
import { getUserProfileShort } from "../store/actions/profile/profile.actions";

export const AppContainer: React.FC = () => {
  // Queries
  const generalProfile = useSelector(
    (state: RootStore) => state.userProfile.generalProfile
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetProfileShort = () => dispatch(getUserProfileShort());

  useEffect(() => {
    onGetProfileShort();
  }, []);

  return (
    <SimpleReactLightbox>
      <Flex w="100%">
        <Sidebar generalProfile={generalProfile} />
        <Box flex="1" overflowX={"hidden"}>
          <Center h="30%">
            <Container h="100px" maxW="container.xl">
              <AppRouting />
            </Container>
          </Center>
        </Box>
      </Flex>
    </SimpleReactLightbox>
  );
};
