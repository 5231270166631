import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getPartnerTypesDirectory,
  getPartnerTypesDirectoryError,
  getPartnerTypesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/partner-types`;

function* workerGetPartnerTypes(
  action: ReturnType<typeof getPartnerTypesDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getPartnerTypesDirectorySuccess(result.value.entities));
    } else {
      yield put(getPartnerTypesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getPartnerTypesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchPartnerTypesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getPartnerTypesDirectory.type, workerGetPartnerTypes);
}
