import React from "react";
import { Route, useRouteMatch } from "react-router";
import { ContactsContainer } from "./Contacts/Contacts.container";
import { UsersContainer } from "./Users/Users.container";
import { WorkRouting } from "./Work/Work.routing";
import { PayoneerRouting } from "./Payoneer/Payoneer.routing";
import { JobRouting } from "./Job/Job.routing";
import { LeadsRouting } from "./Leads/Leads.routing";
import { ProfileContainer } from "./Profile/Profile.container";

export const AppRouting: React.FC = () => {
  const match = useRouteMatch();
  return (
    <>
      <JobRouting match={match} nestedPath={"job"} />
      <PayoneerRouting match={match} nestedPath={"payoneer"} />
      <WorkRouting match={match} nestedPath={"work"} />
      <LeadsRouting match={match} nestedPath={"leads"} />
      <Route path={`${match.url}/contacts`} component={ContactsContainer} />
      {/*<Route path={`${match.url}/reports`} component={Dashboard} />*/}
      <Route path={`${match.url}/profile`} component={ProfileContainer} />
      <Route path={`${match.url}/users`} component={UsersContainer} />
      {/*<Route path={`${match.url}/mail`} component={Mail} />*/}
      {/*<Route path={`${match.url}/diary`} component={Diary} />*/}
    </>
  );
};
