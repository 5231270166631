export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function extractLabelByValue(objectArray: any[], value: any): string {
  if (objectArray && value) {
    const foundObject = objectArray.find((x) => x.value === value);
    if (foundObject) {
      return foundObject.label;
    }
  }
  return "";
}
