import { EmployeeVacations } from "./EmployeeVacations";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import React, { useEffect, useState } from "react";
import { PagedRequest } from "../../../../../common/models";
import {
  checkEmployeeVacation,
  createEmployeeVacation,
  getEmployeeVacations,
  resetEmployeeVacations,
  updateEmployeeVacation,
} from "./store/actions";
import {
  EmployeeVacation,
  EmployeeVacationCreate,
  EmployeeVacationsRequest,
  EmployeeVacationUpdate,
} from "./types";
import { useParams } from "react-router-dom";

export const EmployeeVacationsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;
  const { employeeId } = useParams<{ employeeId: string }>();

  // Queries
  const employeeVacations = useSelector(
    (state: RootStore) => state.employee.vacations.vacations
  );
  const employeeVacationDays = useSelector(
    (state: RootStore) => state.employee.vacations.vacationDays
  );
  const employeeVacationLoading = useSelector(
    (state: RootStore) => state.employee.vacations.loading
  );
  const employeeProfile = useSelector(
    (state: RootStore) => state.employee.profile.profile
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetEmployeeVacations = (payload: EmployeeVacationsRequest) =>
    dispatch(getEmployeeVacations(payload));
  const onUpdateEmployeeVacation = (employeeVacation: EmployeeVacationUpdate) =>
    dispatch(
      updateEmployeeVacation({
        employeeVacation: {
          ...employeeVacation,
          employeeId: +employeeId,
        },
        request: {
          offset: offset,
          take: pageSize,
          employeeId: +employeeId,
        },
      })
    );
  const onCreateEmployeeVacation = (employeeVacation: EmployeeVacationCreate) =>
    dispatch(
      createEmployeeVacation({
        employeeVacation: {
          ...employeeVacation,
          employeeId: +employeeId,
        },
        request: {
          offset: offset,
          take: pageSize,
          employeeId: +employeeId,
        },
      })
    );
  const onCheckEmployeeVacation = (selectedDate: string) =>
    dispatch(
      checkEmployeeVacation({
        date: selectedDate,
        employeeId: +employeeId,
      })
    );
  const onResetEmployeeVacations = () => dispatch(resetEmployeeVacations());

  const offsetChanged = (offset: number) => {
    onResetEmployeeVacations();
    setOffset(offset);
    onGetEmployeeVacations({
      offset: offset,
      take: pageSize,
      employeeId: +employeeId,
    });
  };

  return (
    <EmployeeVacations
      vacationDays={employeeVacationDays}
      checkVacation={onCheckEmployeeVacation}
      pagedEmployeeVacations={employeeVacations}
      employeeVacationLoading={employeeVacationLoading}
      createEmployeeVacation={onCreateEmployeeVacation}
      updateEmployeeVacation={onUpdateEmployeeVacation}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
