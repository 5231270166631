import { createSlice } from "@reduxjs/toolkit";
import { UpworkEmployeeSnapshot, UpworkEmployeeSnapshots } from "../types";
import { BaseState } from "../../../../../../common/models/common";
import {
  getEmployeeUpworkSnapshots,
  getEmployeeUpworkSnapshotsError,
  getEmployeeUpworkSnapshotsSuccess,
  resetEmployeeUpworkSnapshots,
} from "./actions";

type InitialState = BaseState & {
  employeeSnapshots: UpworkEmployeeSnapshots;
};

const initialSnapshots = {
  total: 0,
  entities: [],
  manualTime: "",
  overtime: "",
  totalTime: "",
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  employeeSnapshots: initialSnapshots,
  loaded: false,
};

const employeeSnapshotsSlice = createSlice({
  name: "snapshots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeUpworkSnapshots, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeeUpworkSnapshotsSuccess, (state, action) => {
        state.employeeSnapshots = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getEmployeeUpworkSnapshotsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetEmployeeUpworkSnapshots, (state) => {
        state.employeeSnapshots = initialSnapshots;
      });
  },
});

export default employeeSnapshotsSlice.reducer;
