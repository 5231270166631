import React, { useState } from "react";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../../../common/models";
import {
  EmployeePayment,
  EmployeePaymentCreate,
  EmployeePaymentUpdate,
} from "./types";
import {
  EmployeePaymentCreateModal,
  EmployeePaymentsHeaders,
  EmployeePaymentsTable,
} from "./components";

const CFaPlus = chakra(FaPlus);

interface IEmployeePaymentsProps {
  pagedEmployeePayments: Paged<EmployeePayment>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createEmployeePayment: (employeePayment: EmployeePaymentCreate) => void;
  updateEmployeePayment: (employeePayment: EmployeePaymentUpdate) => void;
  selectedPaymentTypeChanged: (paymentType: number) => void;
  employeePaymentLoading: boolean;
  selectedEmployeeId: number;
}

export const EmployeePayments: React.FC<IEmployeePaymentsProps> = ({
  pagedEmployeePayments,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createEmployeePayment,
  updateEmployeePayment,
  employeePaymentLoading,
  selectedPaymentTypeChanged,
  selectedEmployeeId,
}) => {
  const [
    selectedEmployeePayment,
    setSelectedEmployeePayment,
  ] = useState<EmployeePayment | null>(null);
  // const {
  //   isOpen: isOpenEmployeePaymentEdit,
  //   onOpen: onOpenEmployeePaymentEdit,
  //   onClose: onCloseEmployeePaymentEdit,
  // } = useDisclosure();
  const {
    isOpen: isOpenEmployeePaymentCreate,
    onOpen: onOpenEmployeePaymentCreate,
    onClose: onCloseEmployeePaymentCreate,
  } = useDisclosure();

  // const handleOpenEdit = (employeePayment: EmployeePayment) => {
  //   setSelectedEmployeePayment(employeePayment);
  //   onOpenEmployeePaymentEdit();
  // };

  return (
    <>
      <EmployeePaymentsHeaders
        selectedPaymentTypeChanged={selectedPaymentTypeChanged}
        onOpenEmployeePaymentCreate={onOpenEmployeePaymentCreate}
      />

      <EmployeePaymentsTable
        pagedEmployeePayments={pagedEmployeePayments}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        // showEdit={handleOpenEdit}
      />

      <EmployeePaymentCreateModal
        selectedEmployeeId={selectedEmployeeId}
        handleCreate={createEmployeePayment}
        isOpen={isOpenEmployeePaymentCreate}
        onOpen={onOpenEmployeePaymentCreate}
        onClose={onCloseEmployeePaymentCreate}
      />

      {/*<EmployeePaymentEditModal*/}
      {/*  handleUpdate={updateEmployeePayment}*/}
      {/*  selectedEmployeePayment={selectedEmployeePayment}*/}
      {/*  isOpen={isOpenEmployeePaymentEdit}*/}
      {/*  onOpen={onOpenEmployeePaymentEdit}*/}
      {/*  onClose={onCloseEmployeePaymentEdit}*/}
      {/*/>*/}
    </>
  );
};
