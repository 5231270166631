import React, { useEffect } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight, FaPlus } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import {
  UpworkEmployeeSnapshots,
  UpworkEmployeeSnapshotsFilters,
} from "../types";
import { addDays, formatISO, parse, subDays } from "date-fns";
import { HookDatePicker } from "../../../../../../common/components/libs";
import { parseDateToISO } from "../../../../../../common/utils";

const CFaArrowLeft = chakra(FaArrowLeft);
const CFaArrowRight = chakra(FaArrowRight);

interface IEmployeeSnapshotsHeaderProps {
  employeeSnapshots: UpworkEmployeeSnapshots;
  onFiltersChanged: (filters: UpworkEmployeeSnapshotsFilters) => void;
}

export const EmployeeSnapshotsHeader: React.FC<IEmployeeSnapshotsHeaderProps> =
  ({ onFiltersChanged, employeeSnapshots }) => {
    const {
      control,
      handleSubmit,
      reset,
      watch,
      setValue,
      formState: { errors },
    } = useForm();

    const watchDay = useWatch({ control, name: "day" });

    useEffect(() => {
      if (watchDay) {
        onFiltersChanged({ day: parseDateToISO(watchDay) });
      }
    }, [watchDay]);

    useEffect(() => {
      const formattedValue = subDays(new Date(), 1);
      setValue("day", formattedValue);
    }, []);

    const changeDay = (isNext: boolean) => {
      const parsedDate = parseDateToISO(
        isNext ? addDays(watchDay, 1) : subDays(watchDay, 1)
      );
      setValue("day", isNext ? addDays(watchDay, 1) : subDays(watchDay, 1));
      // onFiltersChanged({ day: parsedDate });
    };

    return (
      <>
        <Flex mb={3} alignItems={"center"}>
          <Box w={"30%"} mr={8}>
            <form>
              <Flex>
                {watchDay && (
                  <IconButton
                    mr={2}
                    variant="ghost"
                    aria-label="Send email"
                    onClick={() => changeDay(false)}
                    icon={<CFaArrowLeft />}
                  />
                )}
                <FormControl>
                  <HookDatePicker control={control} name={"day"} />
                </FormControl>
                {watchDay && (
                  <IconButton
                    ml={2}
                    variant="ghost"
                    aria-label="Send email"
                    onClick={() => changeDay(true)}
                    icon={<CFaArrowRight />}
                  />
                )}
              </Flex>
            </form>
          </Box>
          <Flex>
            {employeeSnapshots.totalTime && (
              <Text fontSize={"lg"} pr={8}>
                Total time: <strong>{employeeSnapshots.totalTime}</strong>
              </Text>
            )}
            {employeeSnapshots.overtime &&
              employeeSnapshots.overtime !== "0:00" && (
                <Text fontSize={"lg"} pr={8} color={"red.500"}>
                  Overtime: <strong>{employeeSnapshots.overtime}</strong>
                </Text>
              )}
            {employeeSnapshots.manualTime &&
              employeeSnapshots.manualTime !== "0:00" && (
                <Text fontSize={"lg"} color={"orange.500"}>
                  Manual time: <strong>{employeeSnapshots.manualTime}</strong>
                </Text>
              )}
          </Flex>
          <Spacer />
          {/*<Box>*/}
          {/*  <Button colorScheme="teal">Add project</Button>*/}
          {/*</Box>*/}
        </Flex>
      </>
    );
  };
