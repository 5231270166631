import { all } from "redux-saga/effects";
import watchUsersSaga from "../../pages/Users/store/saga";
import watchDirectoriesSaga from "./directories";
import watchContactsSaga from "../../pages/Contacts/store/saga";
import watchEmployeesSaga from "../../pages/Work/Employees/store/saga";
import watchTeamsSaga from "../../pages/Work/Teams/store/saga";
import watchPayoneerSaga from "../../pages/Payoneer/store/saga";
import watchProjectsSaga from "../../pages/Job/Projects/store/saga";
import watchContractsSaga from "../../pages/Job/Contracts/store/saga";
import watchPartnersSaga from "../../pages/Job/Partners/store/saga";
import watchUserProfilesSaga from "./profile/profile.saga";
import { watchLoginSaga, watchUpworkAuthSaga } from "./auth";
import watchProjectSaga from "../../pages/Job/Project/store/saga";
import watchProjectMilestonesSaga from "../../pages/Job/Project/modules/Milestones/store/saga";
import watchEmployeeSaga from "../../pages/Work/Employee/store/saga";
import watchSalariesSaga from "../../common/modules/Salaries/store/saga";
import watchContactUsLeadsSaga from "../../pages/Leads/ContactUsLeads/store/saga";

export default function* rootSaga() {
  yield all([
    watchLoginSaga(),
    watchContactsSaga(),
    watchUsersSaga(),
    watchUsersSaga(),
    watchDirectoriesSaga(),
    watchEmployeesSaga(),
    watchEmployeeSaga(),
    watchSalariesSaga(),
    watchTeamsSaga(),
    watchPayoneerSaga(),
    watchProjectsSaga(),
    watchContractsSaga(),
    watchPartnersSaga(),
    watchUserProfilesSaga(),
    watchUpworkAuthSaga(),
    watchProjectSaga(),
    watchProjectMilestonesSaga(),
    watchContactUsLeadsSaga(),
  ]);
}
