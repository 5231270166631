import { EmployeePayments } from "./EmployeePayments";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import React, { useState } from "react";
import {
  createEmployeePayment,
  getEmployeePayments,
  resetEmployeePayments,
  updateEmployeePayment,
} from "./store/actions";
import {
  EmployeePaymentCreate,
  EmployeePaymentsRequest,
  EmployeePaymentUpdate,
} from "./types";
import { useParams } from "react-router-dom";

export const EmployeePaymentsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;
  const [selectedPaymentType, setSelectedPaymentType] = useState<
    number | undefined | null
  >();
  const { employeeId } = useParams<{ employeeId: string }>();

  // Queries
  const employeePayments = useSelector(
    (state: RootStore) => state.employee.payments.employeePayments
  );
  const employeePaymentLoading = useSelector(
    (state: RootStore) => state.employee.payments.loading
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetEmployeePayments = (payload: EmployeePaymentsRequest) =>
    dispatch(getEmployeePayments(payload));
  const onUpdateEmployeePayment = (employeePayment: EmployeePaymentUpdate) =>
    dispatch(
      updateEmployeePayment({
        employeePayment,
        request: {
          offset: offset,
          take: pageSize,
          paymentType: selectedPaymentType,
          employeeId: +employeeId,
        },
      })
    );
  const onCreateEmployeePayment = (employeePayment: EmployeePaymentCreate) =>
    dispatch(
      createEmployeePayment({
        employeePayment,
        request: {
          offset: offset,
          take: pageSize,
          paymentType: selectedPaymentType,
          employeeId: +employeeId,
        },
      })
    );
  const onResetEmployeePayments = () => dispatch(resetEmployeePayments());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetEmployeePayments({
      offset: offset,
      take: pageSize,
      paymentType: selectedPaymentType,
      employeeId: +employeeId,
    });
  };

  const onSelectedPaymentTypeChanged = (paymentType: number) => {
    setSelectedPaymentType(paymentType);
    onGetEmployeePayments({
      offset: offset,
      take: pageSize,
      paymentType,
      employeeId: +employeeId,
    });
  };

  return (
    <EmployeePayments
      pagedEmployeePayments={employeePayments}
      employeePaymentLoading={employeePaymentLoading}
      createEmployeePayment={onCreateEmployeePayment}
      updateEmployeePayment={onUpdateEmployeePayment}
      selectedPaymentTypeChanged={onSelectedPaymentTypeChanged}
      pageSize={pageSize}
      selectedEmployeeId={+employeeId}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
