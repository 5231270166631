import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getPartnerIndustriesDirectory = createAction(
  "DIRECTORIES/GET_PARTNER_INDUSTRIES"
);

export const getPartnerIndustriesDirectorySuccess = createAction<
  DirectoryItem[]
>("DIRECTORIES/GET_PARTNER_INDUSTRIES_SUCCESS");

export const getPartnerIndustriesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_PARTNER_INDUSTRIES_ERROR"
);
