export const EMPLOYEE_STATUSES = {
  Pending: 1,
  Active: 2,
  Fired: 3,
  Quit: 4,
};

export const EMPLOYEE_STATUSES_OPTIONS = [
  { label: "Pending", value: 1 },
  { label: "Active", value: 2 },
  { label: "Fired", value: 3 },
  { label: "Quit", value: 4 },
];
