import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Paged } from "../../../../../common/models";
import {
  EmployeeVacation,
  EmployeeVacationCreate,
  EmployeeVacationUpdate,
} from "./types";
import {
  EmployeeVacationEditModal,
  EmployeeVacationsTable,
  EmployeeVacationCreateModal,
  EmployeeVacationsHeaders,
} from "./components";
import { DirectoryItem } from "../../../../../common/types";

const CFaPlus = chakra(FaPlus);

interface IEmployeeVacationsProps {
  pagedEmployeeVacations: Paged<EmployeeVacation>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createEmployeeVacation: (employeeVacation: EmployeeVacationCreate) => void;
  updateEmployeeVacation: (employeeVacation: EmployeeVacationUpdate) => void;
  checkVacation: (selectedDate: string) => void;
  employeeVacationLoading: boolean;
  vacationDays: number | null;
}

export const EmployeeVacations: React.FC<IEmployeeVacationsProps> = ({
  pagedEmployeeVacations,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createEmployeeVacation,
  updateEmployeeVacation,
  employeeVacationLoading,
  checkVacation,
  vacationDays,
}) => {
  const [selectedEmployeeVacation, setSelectedEmployeeVacation] =
    useState<EmployeeVacation | null>(null);
  const {
    isOpen: isOpenEmployeeVacationEdit,
    onOpen: onOpenEmployeeVacationEdit,
    onClose: onCloseEmployeeVacationEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenEmployeeVacationCreate,
    onOpen: onOpenEmployeeVacationCreate,
    onClose: onCloseEmployeeVacationCreate,
  } = useDisclosure();

  const handleOpenEdit = (employeeVacation: EmployeeVacation) => {
    setSelectedEmployeeVacation(employeeVacation);
    onOpenEmployeeVacationEdit();
  };

  return (
    <>
      <EmployeeVacationsHeaders
        onOpenEmployeeVacationCreate={onOpenEmployeeVacationCreate}
        handleCheckVacation={checkVacation}
        vacationDays={vacationDays}
      />

      <EmployeeVacationsTable
        pagedEmployeeVacations={pagedEmployeeVacations}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <EmployeeVacationCreateModal
        handleCreate={createEmployeeVacation}
        isOpen={isOpenEmployeeVacationCreate}
        onOpen={onOpenEmployeeVacationCreate}
        onClose={onCloseEmployeeVacationCreate}
      />

      <EmployeeVacationEditModal
        handleUpdate={updateEmployeeVacation}
        selectedEmployeeVacation={selectedEmployeeVacation}
        isOpen={isOpenEmployeeVacationEdit}
        onOpen={onOpenEmployeeVacationEdit}
        onClose={onCloseEmployeeVacationEdit}
      />
    </>
  );
};
