import { LoginForm } from "./components/LoginForm";
import { LoginPayload, PayloadError } from "../../common/types";
import { SubmitHandler } from "react-hook-form";
import React from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "../../assets/img/logo1-auth.png";
import backgroundLight from "../../assets/img/abstract-grey-wallpaper-14.png";
import backgroundDark from "../../assets/img/136276-teal-neon-light-with-black-background-hd-wallpaper.png";

interface ILoginProps {
  loading?: boolean;
  onSubmit: SubmitHandler<LoginPayload>;
  errors: PayloadError[];
}

export const Login: React.FC<ILoginProps> = ({ loading, onSubmit, errors }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundRepeat={"round"}
      backgroundImage={colorMode === "light" ? backgroundLight : backgroundDark}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar
          src={logo}
          bg={"transparent"}
          size={"2xl"}
          shadow={"md"}
          mb={2}
        />
        <Box minW={{ base: "90%", md: "370px" }}>
          <LoginForm
            loading={loading}
            onSubmit={onSubmit}
            stateErrors={errors}
          />
        </Box>
      </Stack>
    </Flex>
  );
};
