import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { PagedRequest } from "../../../common/models";
import {
  createEmployee,
  getEmployees,
  resetEmployees,
  updateEmployee,
} from "./store/actions";
import { Employee, EmployeeCreate, EmployeeUpdate } from "./types";
import { Employees } from "./Employees";
import {
  getContactsDirectory,
  getDepartmentsDirectory,
  getJobsDirectory,
} from "../../../store/actions/directories";

export const EmployeesContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  // Queries
  const employees = useSelector(
    (state: RootStore) => state.employees.employees
  );
  const employeeLoading = useSelector(
    (state: RootStore) => state.employees.loading
  );
  const jobs = useSelector((state: RootStore) => state.directories.jobs.jobs);
  const departments = useSelector(
    (state: RootStore) => state.directories.departments.departments
  );
  const contacts = useSelector(
    (state: RootStore) => state.directories.contacts.contacts
  );

  useEffect(() => {
    onGetJobsDirectory();
    onGetDepartmentsDirectory();
    onGetContactsDirectory();
    // return () => {
    //   onResetEmployees();
    // };
  }, []);

  // Dispatches
  const dispatch = useDispatch();
  const onGetEmployees = (payload: PagedRequest) =>
    dispatch(getEmployees(payload));
  const onGetJobsDirectory = () => dispatch(getJobsDirectory());
  const onGetDepartmentsDirectory = () => dispatch(getDepartmentsDirectory());
  const onGetContactsDirectory = () => dispatch(getContactsDirectory());
  const onUpdateEmployee = (employee: EmployeeUpdate) =>
    dispatch(
      updateEmployee({
        employee,
        request: { offset: offset, take: pageSize },
      })
    );
  const onCreateEmployee = (employee: EmployeeCreate) =>
    dispatch(
      createEmployee({
        employee,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetEmployees = () => dispatch(resetEmployees());

  const offsetChanged = (offset: number) => {
    onResetEmployees();
    setOffset(offset);
    onGetEmployees({ offset: offset, take: pageSize });
  };

  return (
    <Employees
      pagedEmployees={employees}
      employeeLoading={employeeLoading}
      createEmployee={onCreateEmployee}
      updateEmployee={onUpdateEmployee}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      jobs={jobs}
      departments={departments}
      contacts={contacts}
    />
  );
};
