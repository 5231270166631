import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Paged } from "../../models";
import { Salary, SalaryCreate, SalaryUpdate } from "./types";
import {
  SalaryEditModal,
  SalariesTable,
  SalaryCreateModal,
  SalariesHeaders,
} from "./components";
import { DirectoryItem } from "../../types";

const CFaPlus = chakra(FaPlus);
const CFaUser = chakra(FaUser);

interface ISalariesProps {
  pagedSalaries: Paged<Salary>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createSalary: (salary: SalaryCreate) => void;
  updateSalary: (salary: SalaryUpdate) => void;
  selectedEmployeeChanged?: (employeeId: number) => void;
  salaryLoading: boolean;
  employees: DirectoryItem[];
  showFilters?: boolean;
  showHeader?: boolean;
}

export const Salaries: React.FC<ISalariesProps> = ({
  pagedSalaries,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createSalary,
  updateSalary,
  salaryLoading,
  employees,
  selectedEmployeeChanged,
  showFilters = true,
  showHeader = true,
}) => {
  const [selectedSalary, setSelectedSalary] = useState<Salary | null>(null);
  const {
    isOpen: isOpenSalaryEdit,
    onOpen: onOpenSalaryEdit,
    onClose: onCloseSalaryEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenSalaryCreate,
    onOpen: onOpenSalaryCreate,
    onClose: onCloseSalaryCreate,
  } = useDisclosure();

  const handleOpenEdit = (salary: Salary) => {
    setSelectedSalary(salary);
    onOpenSalaryEdit();
  };

  return (
    <>
      <SalariesHeaders
        showFilters={showFilters}
        showHeader={showHeader}
        employees={employees}
        selectedEmployeeChanged={selectedEmployeeChanged}
        onOpenSalaryCreate={onOpenSalaryCreate}
      />

      <SalariesTable
        pagedSalaries={pagedSalaries}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <SalaryCreateModal
        employees={employees}
        handleCreate={createSalary}
        isOpen={isOpenSalaryCreate}
        onOpen={onOpenSalaryCreate}
        onClose={onCloseSalaryCreate}
      />

      <SalaryEditModal
        handleUpdate={updateSalary}
        selectedSalary={selectedSalary}
        isOpen={isOpenSalaryEdit}
        onOpen={onOpenSalaryEdit}
        onClose={onCloseSalaryEdit}
      />
    </>
  );
};
