import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  UserProfile,
  UserProfileUpdate,
} from "../../../common/types/profile.types";
import { HookDatePicker, HookSelect } from "../../../common/components/libs";
import { useForm } from "react-hook-form";
import { DirectoryItem } from "../../../common/types";

interface IProfileFormProps {
  handleUpdate: (profile: UserProfileUpdate) => void;
}

export const ProfileForm: React.FC<IProfileFormProps> = ({ handleUpdate }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitUpdate = (values: UserProfileUpdate) => {
    handleUpdate({
      ...values,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitUpdate)}>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={4}
        pt={8}
        pb={8}
      >
        <Flex>
          <FormControl id="password" mr={3} isInvalid={errors.currentPassword}>
            <FormLabel>Current password</FormLabel>
            <Input
              type={"currentPassword"}
              {...register("currentPassword", { required: true })}
            />
            <FormErrorMessage>
              {errors.currentPassword ? "Old password is required" : ""}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="newPassword" isInvalid={errors.newPassword}>
            <FormLabel>New password</FormLabel>
            <Input
              type={"password"}
              {...register("newPassword", { required: true })}
            />
            <FormErrorMessage>
              {errors.newPassword ? "New password is required" : ""}
            </FormErrorMessage>
          </FormControl>
          <Box mt={"auto"} ml={3}>
            <Button type={"submit"}>Update</Button>
          </Box>
        </Flex>
      </Box>
    </form>
  );
};
