import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getPartnerTypesDirectory,
  getPartnerTypesDirectoryError,
  getPartnerTypesDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  partnerTypes: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  partnerTypes: [],
  loaded: false,
};

const partnerTypesDirectorySlice = createSlice({
  name: "partnerTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerTypesDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPartnerTypesDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.partnerTypes = action.payload;
      })
      .addCase(getPartnerTypesDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default partnerTypesDirectorySlice.reducer;
