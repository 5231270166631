import React from "react";
import {
  Box,
  Button,
  Center,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaCheck, FaCheckSquare, FaEye, FaTrash } from "react-icons/fa";
import { ContactUsLead } from "../types";
import { Paged } from "../../../../common/models";
import { SharedPagination } from "../../../../common/components";
import { formatDate } from "../../../../common/utils";

interface IContactUsLeadsListProps {
  pagedContactUsLeads: Paged<ContactUsLead>;
  showView: (contact: ContactUsLead) => void;
  showRemove: (contact: ContactUsLead) => void;
  markAsRead: (contact: ContactUsLead) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const ContactUsLeadsTable: React.FC<IContactUsLeadsListProps> = ({
  pagedContactUsLeads,
  showView,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  markAsRead,
  showRemove,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr variant={"solid"}>
            <Th>Date</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Title</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedContactUsLeads.entities.map((lead, index) => {
            return (
              <Tr key={index} bg={lead.isRead ? "" : "gray.100"}>
                <Td>{formatDate(lead.createdAt)}</Td>
                <Td>{lead.name}</Td>
                <Td>{lead.email}</Td>
                <Td>{lead.title}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => markAsRead(lead)}
                  >
                    <FaCheckSquare />
                  </Button>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showView(lead)}
                  >
                    <FaEye />
                  </Button>
                  <Button
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => showRemove(lead)}
                  >
                    <FaTrash />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedContactUsLeads.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
