import React from "react";
import { TEmployeeProfile } from "./types";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/all";
import { useHistory } from "react-router-dom";
import { EmployeeInfo } from "./components/EmployeeInfo";
import { EmployeeSalariesContainer } from "./modules/EmployeeSalaries/EmployeeSalaries.container";
import { EmployeePaymentsContainer } from "./modules/EmployeePayments/EmployeePayments.container";
import { EmployeeSnapshotsContainer } from "./modules/EmployeeSnapshots/EmployeeSnapshots.container";
import { EmployeeVacationsContainer } from "./modules/EmployeeVacations/EmployeeVacations.container";

const CFaArrowRight = chakra(FaArrowRight);

interface IEmployeeProps {
  employeeProfile: TEmployeeProfile;
}

export const Employee: React.FC<IEmployeeProps> = ({ employeeProfile }) => {
  const history = useHistory();
  return (
    <>
      <Flex mb={3}>
        <Box>
          <Heading size={"lg"}>
            {employeeProfile.employee.firstName +
              " " +
              employeeProfile.employee.lastName}
          </Heading>
          <Text size={"sm"}>
            {employeeProfile.employee.jobName +
              ", " +
              employeeProfile.employee.departmentName}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Button
            colorScheme={"gray"}
            onClick={() => history.push("../employees")}
          >
            <CFaArrowRight mr={2} />
            Back
          </Button>
        </Box>
      </Flex>
      <Tabs>
        <TabList>
          <Tab>Info</Tab>
          <Tab>Salaries</Tab>
          <Tab>Payments</Tab>
          <Tab>Snapshots</Tab>
          <Tab>Vacations</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <EmployeeInfo employeeProfile={employeeProfile} />
          </TabPanel>
          <TabPanel>
            <EmployeeSalariesContainer />
          </TabPanel>
          <TabPanel>
            <EmployeePaymentsContainer />
          </TabPanel>
          <TabPanel>
            <EmployeeSnapshotsContainer />
          </TabPanel>
          <TabPanel>
            <EmployeeVacationsContainer />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
