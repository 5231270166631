import { createSlice } from "@reduxjs/toolkit";
import { ProjectFull } from "../types";
import { BaseState } from "../../../../common/models/common";
import {
  getProject,
  getProjectError,
  getProjectSuccess,
  resetSelectedProject,
} from "./actions";

const initialProject: ProjectFull = {
  id: 0,
  startDate: new Date(),
  endDate: null,
  contractName: "",
  name: "",
};

type InitialState = BaseState & {
  project: ProjectFull;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  project: initialProject,
  loaded: false,
};

const projectsSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProject, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getProjectSuccess, (state, action) => {
        state.project = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getProjectError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetSelectedProject, (state) => {
        state.project = initialProject;
      });
  },
});

export default projectsSlice.reducer;
