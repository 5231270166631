// Get all
import { PagedRequest, Paged } from "../../../../common/models";
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { Partner, PartnerCreate, PartnerUpdate } from "../types";

export const getPartners = createAction<PagedRequest>("PARTNERS/GET_PARTNERS");

export const getPartnersSuccess = createAction<Paged<Partner>>(
  "PARTNERS/GET_PARTNERS_SUCCESS"
);

export const getPartnersError = createAction<PayloadError[]>(
  "PARTNERS/GET_PARTNERS_ERROR"
);

// Get single
export const getPartner = createAction<number>("PARTNERS/GET_PARTNER");

export const getPartnerSuccess = createAction<any>(
  "PARTNERS/GET_PARTNER_SUCCESS"
);

export const getPartnerError = createAction<PayloadError[]>(
  "PARTNERS/GET_PARTNER_ERROR"
);

// Create
export const createPartner = createAction<{
  partner: PartnerCreate;
  request: PagedRequest;
}>("PARTNERS/CREATE_PARTNER");

export const createPartnerSuccess = createAction<Partner>(
  "PARTNERS/CREATE_PARTNER_SUCCESS"
);

export const createPartnerError = createAction<PayloadError[]>(
  "PARTNERS/CREATE_PARTNER_ERROR"
);

// Update
export const updatePartner = createAction<{
  partner: PartnerUpdate;
  request: PagedRequest;
}>("PARTNERS/UPDATE_PARTNER");

export const updatePartnerSuccess = createAction(
  "PARTNERS/UPDATE_PARTNER_SUCCESS"
);

export const updatePartnerError = createAction<PayloadError[]>(
  "PARTNERS/UPDATE_PARTNER_ERROR"
);

// Reset
export const resetSelectedPartner = createAction(
  "PARTNERS/RESET_SELECTED_PARTNER"
);

export const resetPartners = createAction("PARTNERS/RESET");
