import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getTeamsDirectory = createAction("DIRECTORIES/GET_TEAMS");

export const getTeamsDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_TEAMS_SUCCESS"
);

export const getTeamsDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_TEAMS_ERROR"
);
