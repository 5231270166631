import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Employee, EmployeeUpdate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { useForm } from "react-hook-form";
import { DirectoryItem } from "../../../../common/types";
import { EMPLOYEE_STATUSES_OPTIONS } from "../../const/employee.const";
import { parseDateToISO, parseStringToDate } from "../../../../common/utils";

interface IEmployeeEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedEmployee: Employee | null;
  handleUpdate: (employee: EmployeeUpdate) => void;
  jobs: DirectoryItem[];
  departments: DirectoryItem[];
  contacts: DirectoryItem[];
}

export const EmployeeEditModal: React.FC<IEmployeeEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedEmployee,
  handleUpdate,
  jobs,
  departments,
  contacts,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const handleSubmitUpdate = (values: EmployeeUpdate) => {
    handleUpdate({
      ...values,
      id: selectedEmployee ? selectedEmployee.id : null,
      hireDate: parseDateToISO(getValues("hireDate")),
      quitDate: parseDateToISO(getValues("quitDate")),
    });
    reset();
    onClose();
  };

  useEffect(() => {
    if (selectedEmployee) {
      const { contactId, departmentId, hireDate, jobId, status, quitDate } =
        selectedEmployee;
      reset({
        contactId,
        departmentId,
        jobId,
        status,
        hireDate: parseStringToDate(hireDate),
        quitDate: parseStringToDate(quitDate),
      });
    }
  }, [selectedEmployee]);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit employee</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="contactId"
                      pr={3}
                      isInvalid={errors.contactId}
                    >
                      <FormLabel>Contact</FormLabel>
                      <HookSelect
                        control={control}
                        name={"contactId"}
                        options={contacts}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.test ? "Contact is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="status" pr={3} isInvalid={errors.status}>
                      <FormLabel>Status</FormLabel>
                      <HookSelect
                        control={control}
                        name={"status"}
                        options={EMPLOYEE_STATUSES_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.test ? "Status is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="jobId" pr={3}>
                      <FormLabel>Job</FormLabel>
                      <HookSelect
                        control={control}
                        name={"jobId"}
                        options={jobs}
                      />
                    </FormControl>
                    <FormControl id="department" pr={3}>
                      <FormLabel>Department</FormLabel>
                      <HookSelect
                        control={control}
                        name={"departmentId"}
                        options={departments}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3}>
                      <FormLabel>Hire date</FormLabel>
                      <HookDatePicker control={control} name={"hireDate"} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Quit date</FormLabel>
                      <HookDatePicker control={control} name={"quitDate"} />
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
