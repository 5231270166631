import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import React, { useEffect, useState } from "react";
import { createUser, getUsers, resetUsers, updateUser } from "./store/actions";
import { PagedRequest } from "../../common/models";
import { Users } from "./Users";
import { DirectoryItem } from "../../common/types";
import {
  getContactsDirectory,
  getRolesDirectory,
} from "../../store/actions/directories";

export const UsersContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  useEffect(() => {
    onGetContactsDirectory();
    onGetRolesDirectory();
  }, []);

  // Queries
  const users = useSelector((state: RootStore) => state.users.users);
  const userLoading = useSelector((state: RootStore) => state.users.loading);
  const contacts = useSelector(
    (state: RootStore) => state.directories.contacts.contacts
  );
  const roles = useSelector(
    (state: RootStore) => state.directories.roles.roles
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetContactsDirectory = () => dispatch(getContactsDirectory());
  const onGetRolesDirectory = () => dispatch(getRolesDirectory());
  const onGetUsers = (payload: PagedRequest) => dispatch(getUsers(payload));
  const onUpdateUser = (user: any) =>
    dispatch(
      updateUser({
        user,
        request: { offset: offset, take: pageSize },
      })
    );
  const onCreateUser = (user: any) =>
    dispatch(
      createUser({
        user,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetUsers = () => dispatch(resetUsers());

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetUsers({ offset: offset, take: pageSize });
  };

  return (
    <Users
      pagedUsers={users}
      userLoading={userLoading}
      createUser={onCreateUser}
      updateUser={onUpdateUser}
      contacts={contacts}
      roles={roles}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
