import React from "react";
import { Box, Flex, Heading, Spacer } from "@chakra-ui/react";
import {
  UpworkEmployeeSnapshot,
  UpworkEmployeeSnapshots,
  UpworkEmployeeSnapshotsFilters,
} from "./types";
import { EmployeeSnapshotsList } from "./components/EmployeeSnapshotsList";
import { EmployeeSnapshotsHeader } from "./components/EmployeeSnapshotsHeader";

interface IEmployeeSnapshotsProps {
  employeeSnapshots: UpworkEmployeeSnapshots;
  employeeSnapshotsLoading: boolean;
  filtersChanged: (filters: UpworkEmployeeSnapshotsFilters) => void;
}

export const EmployeeSnapshots: React.FC<IEmployeeSnapshotsProps> = ({
  employeeSnapshots,
  employeeSnapshotsLoading,
  filtersChanged,
}) => {
  return (
    <>
      <EmployeeSnapshotsHeader
        employeeSnapshots={employeeSnapshots}
        onFiltersChanged={filtersChanged}
      />

      <EmployeeSnapshotsList employeeSnapshots={employeeSnapshots} />
    </>
  );
};
