// Get all
import { PagedRequest, Paged } from "../../../../../../common/models";
import { createAction } from "@reduxjs/toolkit";
import {
  Milestone,
  MilestoneCreate,
  MilestonesRequest,
  MilestoneUpdate,
} from "../types";
import { PayloadError } from "../../../../../../common/types";

export const getMilestones = createAction<MilestonesRequest>(
  "MILESTONES/GET_MILESTONES"
);

export const getMilestonesSuccess = createAction<Paged<Milestone>>(
  "PROJECT_MILESTONES/GET_MILESTONES_SUCCESS"
);

export const getMilestonesError = createAction<PayloadError[]>(
  "PROJECT_MILESTONES/GET_MILESTONES_ERROR"
);

// Get single
export const getMilestone = createAction("PROJECT_MILESTONES/GET_MILESTONE");

export const getMilestoneSuccess = createAction<any>(
  "PROJECT_MILESTONES/GET_MILESTONE_SUCCESS"
);

export const getMilestoneError = createAction<PayloadError[]>(
  "PROJECT_MILESTONES/GET_MILESTONE_ERROR"
);

// Create
export const createMilestone = createAction<{
  milestone: MilestoneCreate;
  request: MilestonesRequest;
}>("PROJECT_MILESTONES/CREATE_MILESTONE");

export const createMilestoneSuccess = createAction<Milestone>(
  "PROJECT_MILESTONES/CREATE_MILESTONE_SUCCESS"
);

export const createMilestoneError = createAction<PayloadError[]>(
  "PROJECT_MILESTONES/CREATE_MILESTONE_ERROR"
);

// Update
export const updateMilestone = createAction<{
  milestone: MilestoneUpdate;
  request: MilestonesRequest;
}>("PROJECT_MILESTONES/UPDATE_MILESTONE");

export const updateMilestoneSuccess = createAction(
  "PROJECT_MILESTONES/UPDATE_MILESTONE_SUCCESS"
);

export const updateMilestoneError = createAction<PayloadError[]>(
  "PROJECT_MILESTONES/UPDATE_MILESTONE_ERROR"
);

// Reset
export const resetSelectedMilestone = createAction(
  "PROJECT_MILESTONES/RESET_SELECTED_MILESTONE"
);

export const resetMilestones = createAction("PROJECT_MILESTONES/RESET");
