export function formatCurrency(value: number | null, currency = "USD") {
  if (value !== null && currency) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(value);
  } else if (value !== null) {
    return value.toFixed(2);
  } else {
    return 0;
  }
}

export function formatCurrencyFixed(value: number | null) {
  if (value) {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    return 0;
  }
}
