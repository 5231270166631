import { Partners } from "./Partners";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import {
  createPartner,
  getPartner,
  getPartners,
  resetPartners,
  resetSelectedPartner,
  updatePartner,
} from "./store/actions";
import { PagedRequest } from "../../../common/models";
import {
  getAddressTypesDirectory,
  getContactsDirectory,
  getCountriesDirectory,
  getPartnerIndustriesDirectory,
  getPartnerTypesDirectory,
} from "../../../store/actions/directories";

export const PartnersContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPartnerId, setSelectedPartnerId] = useState<number | null>(
    null
  );
  const pageSize: number = 5;

  useEffect(() => {
    onGetAddressTypesDirectory();
    onGetContactsDirectory();
    onGetCountriesDirectory();
    onGetPartnerTypesDirectory();
    onGetPartnerIndustriesDirectory();
  }, []);

  // Queries
  const partners = useSelector((state: RootStore) => state.partners.partners);
  const selectedPartner = useSelector(
    (state: RootStore) => state.partners.selectedPartner
  );
  const partnerLoading = useSelector(
    (state: RootStore) => state.partners.loading
  );
  const addressTypes = useSelector(
    (state: RootStore) => state.directories.addressTypes.addressTypes
  );
  const contacts = useSelector(
    (state: RootStore) => state.directories.contacts.contacts
  );
  const countries = useSelector(
    (state: RootStore) => state.directories.countries.countries
  );
  const partnerTypes = useSelector(
    (state: RootStore) => state.directories.partnerTypes.partnerTypes
  );
  const partnerIndustries = useSelector(
    (state: RootStore) => state.directories.partnerIndustries.partnerIndustries
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetAddressTypesDirectory = () => dispatch(getAddressTypesDirectory());
  const onGetCountriesDirectory = () => dispatch(getCountriesDirectory());
  const onGetPartnerTypesDirectory = () => dispatch(getPartnerTypesDirectory());
  const onGetContactsDirectory = () => dispatch(getContactsDirectory());
  const onGetPartnerIndustriesDirectory = () =>
    dispatch(getPartnerIndustriesDirectory());
  const onGetPartners = (payload: PagedRequest) =>
    dispatch(getPartners(payload));
  const onGetPartner = (payload: number) => dispatch(getPartner(payload));
  const onUpdatePartner = (partner: any) => {
    setSelectedPartnerId(null);
    dispatch(
      updatePartner({
        partner,
        request: { offset: offset, take: pageSize },
      })
    );
  };

  const onCreatePartner = (partner: any) =>
    dispatch(
      createPartner({
        partner,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetPartner = () => dispatch(resetSelectedPartner());
  const onResetPartners = () => dispatch(resetPartners());

  useEffect(() => {
    if (selectedPartnerId) {
      onResetPartner();
      onGetPartner(selectedPartnerId);
    }
  }, [selectedPartnerId]);

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    onGetPartners({ offset: offset, take: pageSize });
  };

  return (
    <Partners
      addressTypes={addressTypes}
      countries={countries}
      partnerTypes={partnerTypes}
      contacts={contacts}
      partnerIndustries={partnerIndustries}
      pagedPartners={partners}
      selectedPartner={selectedPartner}
      partnerLoading={partnerLoading}
      createPartner={onCreatePartner}
      updatePartner={onUpdatePartner}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      setSelectedPartnerId={setSelectedPartnerId}
      currentPage={currentPage}
    />
  );
};
