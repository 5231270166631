import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { EmployeePaymentCreate } from "../types";
import {
  HookDatePicker,
  HookSelect,
} from "../../../../../../common/components/libs";
import { EMPLOYEE_PAYMENT_TYPES_OPTIONS } from "../const/employee-payments.conts";
import { CURRENCY_OPTIONS } from "../../../../../../common/constants/currencies.const";
import { parseDateToISO } from "../../../../../../common/utils";

interface IEmployeePaymentCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (employeePayment: EmployeePaymentCreate) => void;
  selectedEmployeeId: number;
}

export const EmployeePaymentCreateModal: React.FC<IEmployeePaymentCreateModalProps> =
  ({ isOpen, onOpen, onClose, handleCreate, selectedEmployeeId }) => {
    const {
      register,
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm();

    const handleSubmitCreate = (values: EmployeePaymentCreate) => {
      handleCreate({
        ...values,
        employeeId: selectedEmployeeId,
        date: parseDateToISO(values.date),
      });
      handleClose();
    };

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New payment</ModalHeader>
            <form onSubmit={handleSubmit(handleSubmitCreate)}>
              <ModalBody>
                <Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl id="amount" isInvalid={errors.amount}>
                        <FormLabel>Amount</FormLabel>
                        <Input
                          type={"number"}
                          step={"0.01"}
                          {...register("amount", { required: true })}
                        />
                        <FormErrorMessage>
                          {errors.amount ? "Amount is required" : ""}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3} isInvalid={errors.paymentType}>
                        <FormLabel>Payment type</FormLabel>
                        <HookSelect
                          control={control}
                          name={"paymentType"}
                          options={EMPLOYEE_PAYMENT_TYPES_OPTIONS}
                          rules={{ required: true }}
                        />
                        <FormErrorMessage>
                          {errors.paymentType ? "Payment type is required" : ""}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl pl={3}>
                        <FormLabel>Date</FormLabel>
                        <HookDatePicker
                          control={control}
                          name={"date"}
                          placeholder={"Select date..."}
                        />
                      </FormControl>
                    </Flex>
                  </Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl pr={3} isInvalid={errors.currencyId}>
                        <FormLabel>Currency</FormLabel>
                        <HookSelect
                          control={control}
                          name={"currencyId"}
                          options={CURRENCY_OPTIONS}
                          rules={{ required: true }}
                        />
                        <FormErrorMessage>
                          {errors.currencyId ? "Currency is required" : ""}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl pl={3} isInvalid={errors.month}>
                        <FormLabel>Month</FormLabel>
                        <Input
                          type={"number"}
                          {...register("month", { required: true })}
                        />
                        <FormErrorMessage>
                          {errors.amount ? "Month is required" : ""}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button type={"submit"} mr={3}>
                  Create
                </Button>
                <Button colorScheme="gray" onClick={handleClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
