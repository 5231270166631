import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getRolesDirectory = createAction("DIRECTORIES/GET_ROLES");

export const getRolesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_ROLES_SUCCESS"
);

export const getRolesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_ROLES_ERROR"
);
