import React, { useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { PayoneerTransactionCreate } from "../types";
import {
  PAYONEER_ACCOUNTS_OPTIONS,
  PAYONEER_TRANSACTION_PURPOSES_OPTIONS,
  PAYONEER_TRANSACTION_STATUSES,
  PAYONEER_TRANSACTION_STATUSES_OPTIONS,
  PAYONEER_TRANSACTION_TYPES_OPTIONS,
} from "../const/payoneer-transactions.const";
import { formatDate } from "../../../../common/utils";

interface ITransactionCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (transaction: PayoneerTransactionCreate) => void;
}

export const TransactionCreateModal: React.FC<ITransactionCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: PayoneerTransactionCreate) => {
    handleCreate({
      ...values,
      transactionDate: formatDate(getValues("transactionDate")),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New transaction</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3} isInvalid={errors.transactionId}>
                      <FormLabel>Transaction id</FormLabel>
                      <Input
                        type={"number"}
                        {...register("transactionId", { required: true })}
                      />
                      <FormErrorMessage>
                        {errors.transactionId
                          ? "Transaction id is required"
                          : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Transaction date</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"transactionDate"}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3}>
                      <FormLabel>Description</FormLabel>
                      <Input {...register("description")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Additional description</FormLabel>
                      <Input {...register("additionalDescription")} />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3} isInvalid={errors.amount}>
                      <FormLabel>Amount</FormLabel>
                      <Input
                        type={"number"}
                        step={"0.01"}
                        {...register("amount", { required: true })}
                      />
                      <FormErrorMessage>
                        {errors.amount ? "Amount is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3} isInvalid={errors.purpose}>
                      <FormLabel>Purpose</FormLabel>
                      <HookSelect
                        control={control}
                        name={"purpose"}
                        options={PAYONEER_TRANSACTION_PURPOSES_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.purpose ? "Purpose is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3} isInvalid={errors.type}>
                      <FormLabel>Type</FormLabel>
                      <HookSelect
                        control={control}
                        name={"type"}
                        options={PAYONEER_TRANSACTION_TYPES_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.type ? "Type is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3} isInvalid={errors.account}>
                      <FormLabel>Account</FormLabel>
                      <HookSelect
                        control={control}
                        name={"account"}
                        options={PAYONEER_ACCOUNTS_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.account ? "Account is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box>
                  <Flex>
                    <FormControl pr={3} isInvalid={errors.balanceAmount}>
                      <FormLabel>Balance amount</FormLabel>
                      <Input
                        step={"0.01"}
                        type={"number"}
                        {...register("balanceAmount", { required: true })}
                      />
                      <FormErrorMessage>
                        {errors.balanceAmount
                          ? "Balance amount is required"
                          : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3} isInvalid={errors.status}>
                      <FormLabel>Status</FormLabel>
                      <HookSelect
                        control={control}
                        name={"status"}
                        options={PAYONEER_TRANSACTION_STATUSES_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.purpose ? "Status is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
