// Get all
import { PagedRequest, Paged } from "../../../../common/models";
import {
  ProjectSimple,
  ProjectCreate,
  ProjectUpdate,
  ProjectsRequest,
} from "../types";
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";

export const getProjects = createAction<ProjectsRequest>(
  "PROJECTS/GET_PROJECTS"
);

export const getProjectsSuccess = createAction<Paged<ProjectSimple>>(
  "PROJECTS/GET_PROJECTS_SUCCESS"
);

export const getProjectsError = createAction<PayloadError[]>(
  "PROJECTS/GET_PROJECTS_ERROR"
);

// Create
export const createProject = createAction<{
  project: ProjectCreate;
  request: ProjectsRequest;
}>("PROJECTS/CREATE_PROJECT");

export const createProjectSuccess = createAction<ProjectSimple>(
  "PROJECTS/CREATE_PROJECT_SUCCESS"
);

export const createProjectError = createAction<PayloadError[]>(
  "PROJECTS/CREATE_PROJECT_ERROR"
);

// Update
export const updateProject = createAction<{
  project: ProjectUpdate;
  request: ProjectsRequest;
}>("PROJECTS/UPDATE_PROJECT");

export const updateProjectSuccess = createAction(
  "PROJECTS/UPDATE_PROJECT_SUCCESS"
);

export const updateProjectError = createAction<PayloadError[]>(
  "PROJECTS/UPDATE_PROJECT_ERROR"
);

// Reset
export const resetProjects = createAction("PROJECTS/RESET");
