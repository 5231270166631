import React from "react";
import { Box, Center, Heading, Image, Text } from "@chakra-ui/react";
import placeholder from "../../../../../../assets/img/placeholders/placeholder-snapshots.png";
import { UpworkEmployeeSnapshot } from "../types";
import { AuthImage } from "../../../../../../common/components";
import { formatDate } from "../../../../../../common/utils";

interface IEmployeeSnapshotCardProps {
  employeeSnapshot: UpworkEmployeeSnapshot;
}

export const EmployeeSnapshotCard: React.FC<IEmployeeSnapshotCardProps> = ({
  employeeSnapshot,
}) => {
  const getBorder = (snapshot: UpworkEmployeeSnapshot) => {
    if (snapshot.isEmptyTimeCell) return "grey.100";
    if (snapshot.isManualTime) return "orange.300";
    if (snapshot.isOvertime) return "red.300";
    return "teal.500";
  };
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      borderstyle={employeeSnapshot.isEmptyTimeCell ? "dashed" : "solid"}
      borderColor={getBorder(employeeSnapshot)}
    >
      {employeeSnapshot.isEmptyTimeCell || employeeSnapshot.isManualTime ? (
        <Image
          variant="top"
          src={placeholder}
          alt={employeeSnapshot.activeWindowTitle}
        />
      ) : (
        <AuthImage
          url={
            employeeSnapshot.screenshotStorageHash
              ? `/api/storage/images/upwork-snapshots/` +
                employeeSnapshot.screenshotStorageHash +
                ".jpg"
              : placeholder
          }
          attributeName={"href"}
          useSkeleton={false}
          childComponent={
            <a data-attribute="SRL">
              <AuthImage
                url={
                  employeeSnapshot.thumbnailStorageHash
                    ? `/api/storage/images/upwork-snapshots/thumbnail/` +
                      employeeSnapshot.thumbnailStorageHash +
                      ".jpg"
                    : placeholder
                }
                childComponent={
                  <Image
                    w={"100%"}
                    variant="top"
                    alt={employeeSnapshot.activeWindowTitle}
                  />
                }
                useSkeleton={false}
              />
            </a>
          }
        />
      )}

      <Box mt={2}>
        <Center>
          <Text fontSize={"2xl"}>
            {formatDate(employeeSnapshot.startTime, "HH:mm")}
          </Text>
        </Center>
        <Center>
          <Text>
            {employeeSnapshot.keyboardEventsCount}/
            {employeeSnapshot.mouseEventsCount}
          </Text>
        </Center>
      </Box>
    </Box>
  );
};
