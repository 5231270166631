import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect } from "react";
import { getEmployeeProfile, resetSelectedEmployee } from "./store/actions";
import { Employee } from "./Employee";
import { useParams } from "react-router-dom";

export const EmployeeContainer: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();

  // Queries
  const employeeProfile = useSelector(
    (state: RootStore) => state.employee.profile.profile
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetEmployeeProfile = (employeeId: number) =>
    dispatch(getEmployeeProfile({ employeeId: employeeId }));
  const onResetEmployee = () => dispatch(resetSelectedEmployee());

  useEffect(() => {
    onGetEmployeeProfile(+employeeId);
    return () => {
      onResetEmployee();
    };
  }, []);

  return <Employee employeeProfile={employeeProfile} />;
};
