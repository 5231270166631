import React from "react";
import { Route } from "react-router";
import { EmployeesContainer } from "./Employees/Employees.container";
import { SalariesContainer } from "./Salaries/Salaries.container";
import { TeamsContainer } from "./Teams/Teams.container";
import { EmployeeContainer } from "./Employee/Employee.container";

interface IWorkRoutingProps {
  match: any;
  nestedPath: string;
}

export const WorkRouting: React.FC<IWorkRoutingProps> = ({
  match,
  nestedPath,
}) => {
  return (
    <>
      <Route
        path={`${match.url}/${nestedPath}/employees`}
        component={EmployeesContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/employee/:employeeId`}
        component={EmployeeContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/salaries`}
        component={SalariesContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/teams`}
        component={TeamsContainer}
      />
    </>
  );
};
