import {
  EXTENDED_DATE_FORMAT,
  SIMPLE_DATE_FORMAT,
} from "../constants/dates.const";
import { format, parseISO, parse, formatISO } from "date-fns";

export function formatDate(
  date: Date | string | null,
  dateFormat = SIMPLE_DATE_FORMAT
) {
  if (date) {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    return format(parsedDate, dateFormat);
  } else {
    return "";
  }
}

export function parseDateToISO(
  date: Date | string | null,
  representation: "date" | "time" | null = "date"
) {
  if (date) {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    return representation
      ? formatISO(parsedDate, { representation })
      : formatISO(parsedDate);
  } else {
    return "";
  }
}

export function parseStringToDate(
  date: Date | string | null,
  dateFormat?: string
) {
  if (date && dateFormat) {
    return date instanceof Date ? date : parseISO(date);
    // return date instanceof Date ? date : parseISO(date, dateFormat);
  } else if (date) {
    return date instanceof Date ? date : parseISO(date);
  } else {
    return null;
  }
}
