import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  getContactUsLeads,
  getContactUsLeadsError,
  getContactUsLeadsSuccess,
  removeContactUsLead,
  removeContactUsLeadError,
  removeContactUsLeadSuccess,
  updateContactUsLead,
  updateContactUsLeadError,
  updateContactUsLeadSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { ContactUsLead } from "../types";
import { Contact } from "../../../Contacts/types";

function* workerGetContactUsLeads(
  action: ReturnType<typeof getContactUsLeads>
) {
  try {
    const result: ApiResponse<Paged<ContactUsLead>> = yield call(
      API.get,
      `/api/contact-form/`,
      action.payload
    );
    if (result.success) {
      yield put(getContactUsLeadsSuccess(result.value));
    } else {
      yield put(getContactUsLeadsError(result.errors));
    }
  } catch (e) {
    yield put(getContactUsLeadsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateContactUsLead(
  action: ReturnType<typeof updateContactUsLead>
) {
  try {
    const result: ApiResponse<ContactUsLead> = yield call(
      API.put,
      `/api/contact-form`,
      {
        contactFormId: action.payload.lead.id,
        isReadStatus: action.payload.lead.isRead,
      }
    );
    if (result.success) {
      yield put(updateContactUsLeadSuccess());
      yield put(getContactUsLeads(action.payload.request));
    } else {
      yield put(updateContactUsLeadError(result.errors));
    }
  } catch (e) {
    yield put(updateContactUsLeadError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerRemoveContactUsLead(
  action: ReturnType<typeof removeContactUsLead>
) {
  try {
    const result: ApiResponse<Contact> = yield call(
      API.delete,
      `/api/contact-form/${action.payload.contactUsLeadId}`
    );
    if (result.success) {
      yield put(removeContactUsLeadSuccess());
      yield put(getContactUsLeads(action.payload.request));
    } else {
      yield put(removeContactUsLeadError(result.errors));
    }
  } catch (e) {
    yield put(removeContactUsLeadError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchContactUsLeadsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContactUsLeads.type, workerGetContactUsLeads);
  yield takeEvery(updateContactUsLead.type, workerUpdateContactUsLead);
  yield takeEvery(removeContactUsLead.type, workerRemoveContactUsLead);
}
