import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { ApiResponse } from "../../../../common/models/common";
import { uploadData, uploadDataError, uploadDataSuccess } from "./actions";

const apiUrl = `/api/payoneer/transaction-import/`;

function* workerUploadData(action: ReturnType<typeof uploadData>) {
  try {
    const result: ApiResponse = yield call(
      API.file,
      `${apiUrl}${action.payload.accountId}`,
      action.payload.file
    );
    if (result.success) {
      yield put(uploadDataSuccess());
    } else {
      yield put(uploadDataError(result.errors));
    }
  } catch (e) {
    yield put(uploadDataError([{ key: "", errorMessage: e }]));
  }
}

export default function* watchUploadPayoneerDataSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(uploadData.type, workerUploadData);
}
