import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getPartnerIndustriesDirectory,
  getPartnerIndustriesDirectoryError,
  getPartnerIndustriesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/partner-industries`;

function* workerGetPartnerIndustries(
  action: ReturnType<typeof getPartnerIndustriesDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getPartnerIndustriesDirectorySuccess(result.value.entities));
    } else {
      yield put(getPartnerIndustriesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getPartnerIndustriesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchPartnerIndustriesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    getPartnerIndustriesDirectory.type,
    workerGetPartnerIndustries
  );
}
