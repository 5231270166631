import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createPartner,
  createPartnerError,
  createPartnerSuccess,
  getPartner,
  getPartnerError,
  getPartners,
  getPartnersError,
  getPartnersSuccess,
  getPartnerSuccess,
  updatePartner,
  updatePartnerError,
  updatePartnerSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { Partner } from "../types";

const apiUrl = `/api/partners/`;

function* workerGetPartners(action: ReturnType<typeof getPartners>) {
  try {
    const result: ApiResponse<Paged<Partner>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getPartnersSuccess(result.value));
    } else {
      yield put(getPartnersError(result.errors));
    }
  } catch (e) {
    yield put(getPartnersError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerGetPartner(action: ReturnType<typeof getPartner>) {
  try {
    const result: ApiResponse<Paged<Partner>> = yield call(
      API.get,
      `${apiUrl}${action.payload}`
    );
    if (result.success) {
      yield put(getPartnerSuccess(result.value));
    } else {
      yield put(getPartnerError(result.errors));
    }
  } catch (e) {
    yield put(getPartnerError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdatePartner(action: ReturnType<typeof updatePartner>) {
  try {
    const result: ApiResponse<Partner> = yield call(
      API.put,
      apiUrl,
      action.payload.partner
    );
    if (result.success) {
      yield put(updatePartnerSuccess());
      yield put(getPartners(action.payload.request));
    } else {
      yield put(updatePartnerError(result.errors));
    }
  } catch (e) {
    yield put(updatePartnerError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreatePartner(action: ReturnType<typeof createPartner>) {
  try {
    const result: ApiResponse<Partner> = yield call(
      API.post,
      apiUrl,
      action.payload.partner
    );
    if (result.success) {
      yield put(createPartnerSuccess(result.value));
      // Call update contacts list
      yield put(getPartners(action.payload.request));
    } else {
      yield put(createPartnerError(result.errors));
    }
  } catch (e) {
    yield put(createPartnerError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchPartnersSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getPartners.type, workerGetPartners);
  yield takeEvery(getPartner.type, workerGetPartner);
  yield takeEvery(createPartner.type, workerCreatePartner);
  yield takeEvery(updatePartner.type, workerUpdatePartner);
}
