import { createSlice } from "@reduxjs/toolkit";
import { Team } from "../types";
import {
  createTeam,
  createTeamError,
  createTeamSuccess,
  getTeams,
  getTeamsError,
  getTeamsSuccess,
  resetTeams,
  updateTeam,
  updateTeamError,
  updateTeamSuccess,
} from "./actions";
import { BaseState, Paged } from "../../../../common/models/common";

type InitialState = BaseState & {
  teams: Paged<Team>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  teams: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeams, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getTeamsSuccess, (state, action) => {
        state.teams = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getTeamsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateTeam, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateTeamSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateTeamError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createTeam, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createTeamSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createTeamError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetTeams, (state) => {
        state.teams.entities = [];
      });
  },
});

export default teamsSlice.reducer;
