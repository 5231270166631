import React, { useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

interface IProfileUpworkConnectModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // @ts-ignore
  handleSubmitToken: (oauthVerifier: string) => void;
}

export const ProfileUpworkConnectModal: React.FC<IProfileUpworkConnectModalProps> =
  ({ isOpen, onOpen, onClose, handleSubmitToken }) => {
    const {
      register,
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm();

    const handleSubmitCreate = (values: { oauthVerifier: string }) => {
      const { oauthVerifier } = values;
      handleSubmitToken(oauthVerifier);
      handleClose();
    };

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New upworkConnect</ModalHeader>
            <form onSubmit={handleSubmit(handleSubmitCreate)}>
              <ModalBody>
                <Box>
                  <Box mb={3}>
                    <Flex>
                      <FormControl isInvalid={errors.oauthVerifier}>
                        <FormLabel>
                          Oauth Verifier (copy verifier after = symbol)
                        </FormLabel>
                        <Input
                          {...register("oauthVerifier", { required: true })}
                        />
                        <FormErrorMessage>
                          {errors.oauthVerifier
                            ? "Oauth verifier is required"
                            : ""}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button type={"submit"} mr={3}>
                  Create
                </Button>
                <Button colorScheme="gray" onClick={handleClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
