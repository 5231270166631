import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getContractsDirectory,
  getContractsDirectoryError,
  getContractsDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  contracts: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  contracts: [],
  loaded: false,
};

const contractsDirectorySlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContractsDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContractsDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.contracts = action.payload;
      })
      .addCase(getContractsDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default contractsDirectorySlice.reducer;
