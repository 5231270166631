import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getAddressTypesDirectory = createAction(
  "DIRECTORIES/GET_ADDRESS_TYPES"
);

export const getAddressTypesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_ADDRESS_TYPES_SUCCESS"
);

export const getAddressTypesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_ADDRESS_TYPES_ERROR"
);
