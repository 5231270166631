import React, { useEffect } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  Heading,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { FaFileUpload, FaPlus, FaTimes, FaUser } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { PayoneerTransactionsFilters } from "../types";
import {
  PAYONEER_ACCOUNTS,
  PAYONEER_ACCOUNTS_OPTIONS,
  PAYONEER_TRANSACTION_PURPOSES_OPTIONS,
  PAYONEER_TRANSACTION_STATUSES_OPTIONS,
  PAYONEER_TRANSACTION_TYPES_OPTIONS,
} from "../const/payoneer-transactions.const";
import { useHistory } from "react-router-dom";

const CFaPlus = chakra(FaPlus);
const CFaFileUpload = chakra(FaFileUpload);
const CFaTimes = chakra(FaTimes);

interface ITransactionsHeaderProps {
  filtersChanged: (filters: PayoneerTransactionsFilters) => void;
  onOpenPayoneerTransactionCreate: () => void;
}

export const TransactionsHeaders: React.FC<ITransactionsHeaderProps> = ({
  onOpenPayoneerTransactionCreate,
  filtersChanged,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      account: 1,
      status: undefined,
      type: undefined,
      purpose: undefined,
    },
    mode: "onChange",
  });

  const history = useHistory();

  const account = useWatch({ control, name: "account" });
  const status = useWatch({ control, name: "status" });
  const type = useWatch({ control, name: "type" });
  const purpose = useWatch({ control, name: "purpose" });

  useEffect(() => {
    if (account || status || type || purpose)
      onFiltersChanged({
        account: account ? account : PAYONEER_ACCOUNTS.Aragorn,
        status,
        type,
        purpose,
      });
  }, [account, status, type, purpose]);

  const onFiltersChanged = (filters: PayoneerTransactionsFilters) => {
    filtersChanged(filters);
  };

  const defaultValues1 = {
    account: PAYONEER_ACCOUNTS.Aragorn,
    status: undefined,
    type: undefined,
    purpose: undefined,
  };

  const resetFilters = () => {
    reset(defaultValues1);
  };

  return (
    <form>
      <Heading size={"lg"} mb={3}>
        Payoneer transactions
      </Heading>
      <Flex mb={3}>
        <Box minW={"170px"} mr={3}>
          <FormControl>
            <HookSelect
              control={control}
              name={"account"}
              options={PAYONEER_ACCOUNTS_OPTIONS}
              defaultValue={PAYONEER_ACCOUNTS.Aragorn}
              initialValue={PAYONEER_ACCOUNTS.Aragorn}
              placeholder={"Account"}
            />
          </FormControl>
        </Box>
        <Box minW={"170px"} mr={3}>
          <FormControl>
            <HookSelect
              control={control}
              name={"type"}
              options={PAYONEER_TRANSACTION_TYPES_OPTIONS}
              placeholder={"Type"}
              isClearable={true}
            />
          </FormControl>
        </Box>
        <Box minW={"170px"} mr={3}>
          <FormControl>
            <HookSelect
              control={control}
              name={"status"}
              options={PAYONEER_TRANSACTION_STATUSES_OPTIONS}
              placeholder={"Status"}
              isClearable={true}
            />
          </FormControl>
        </Box>
        <Box minW={"170px"} mr={3}>
          <FormControl>
            <HookSelect
              control={control}
              name={"purpose"}
              options={PAYONEER_TRANSACTION_PURPOSES_OPTIONS}
              placeholder={"Purpose"}
              isClearable={true}
            />
          </FormControl>
        </Box>

        {/*<Button*/}
        {/*  pb={1}*/}
        {/*  variant={"ghost"}*/}
        {/*  colorScheme={"red"}*/}
        {/*  onClick={resetFilters}*/}
        {/*>*/}
        {/*  <CFaTimes />*/}
        {/*</Button>*/}

        <Spacer />
        <Box>
          <Tooltip label={"Upload transactions"}>
            <Button mr={2} onClick={() => history.push("./upload-data")}>
              <CFaFileUpload />
            </Button>
          </Tooltip>
          <Tooltip label={"New transaction"}>
            <Button onClick={() => onOpenPayoneerTransactionCreate()}>
              <CFaPlus />
            </Button>
          </Tooltip>
        </Box>
      </Flex>
    </form>
  );
};
