import { createSlice } from "@reduxjs/toolkit";
import { Employee } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  createEmployee,
  createEmployeeError,
  createEmployeeSuccess,
  getEmployees,
  getEmployeesError,
  getEmployeesSuccess,
  resetEmployees,
  updateEmployee,
  updateEmployeeError,
  updateEmployeeSuccess,
} from "./actions";

type InitialState = BaseState & {
  employees: Paged<Employee>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  employees: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeesSuccess, (state, action) => {
        state.employees = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getEmployeesError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateEmployee, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateEmployeeSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateEmployeeError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createEmployee, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createEmployeeSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createEmployeeError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetEmployees, (state) => {
        state.employees.entities = [];
      });
  },
});

export default employeesSlice.reducer;
