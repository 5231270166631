import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getGendersDirectory,
  getGendersDirectoryError,
  getGendersDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  genders: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  genders: [],
  loaded: false,
};

const gendersDirectorySlice = createSlice({
  name: "genders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGendersDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getGendersDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.genders = action.payload;
      })
      .addCase(getGendersDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default gendersDirectorySlice.reducer;
