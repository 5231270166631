import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getContractsDirectory = createAction("DIRECTORIES/GET_CONTRACTS");

export const getContractsDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_CONTRACTS_SUCCESS"
);

export const getContractsDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_CONTRACTS_ERROR"
);
