import React, { useState } from "react";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../common/models";
import {
  PayoneerTransaction,
  PayoneerTransactionBinding,
  PayoneerTransactionCreate,
  PayoneerTransactionsFilters,
  PayoneerTransactionUpdate,
} from "./types";
import {
  TransactionBindingModal,
  TransactionCreateModal,
  TransactionsTable,
} from "./components";
import { DirectoryItem } from "../../../common/types";
import { TransactionsHeaders } from "./components/TransactionsHeader";

const CFaPlus = chakra(FaPlus);

interface ITransactionsProps {
  pagedPayoneerTransactions: Paged<PayoneerTransaction>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createPayoneerTransaction: (
    payoneerTransaction: PayoneerTransactionCreate
  ) => void;
  updatePayoneerTransaction: (
    payoneerTransaction: PayoneerTransactionUpdate
  ) => void;
  updatePayoneerTransactionBinding: (
    transactionBinding: PayoneerTransactionBinding
  ) => void;
  getSalaryPayments: (employeeId: number) => void;
  payoneerTransactionLoading: boolean;
  employees: DirectoryItem[];
  salaryPayments: DirectoryItem[];
  filtersChanged: (filters: PayoneerTransactionsFilters) => void;
}

export const Transactions: React.FC<ITransactionsProps> = ({
  pagedPayoneerTransactions,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createPayoneerTransaction,
  updatePayoneerTransaction,
  getSalaryPayments,
  payoneerTransactionLoading,
  updatePayoneerTransactionBinding,
  salaryPayments,
  employees,
  filtersChanged,
}) => {
  const [
    selectedPayoneerTransaction,
    setSelectedPayoneerTransaction,
  ] = useState<PayoneerTransaction | null>(null);
  const {
    isOpen: isOpenPayoneerTransactionEdit,
    onOpen: onOpenPayoneerTransactionEdit,
    onClose: onClosePayoneerTransactionEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenPayoneerTransactionCreate,
    onOpen: onOpenPayoneerTransactionCreate,
    onClose: onClosePayoneerTransactionCreate,
  } = useDisclosure();

  const handleOpenEdit = (payoneerTransaction: PayoneerTransaction) => {
    setSelectedPayoneerTransaction(payoneerTransaction);
    onOpenPayoneerTransactionEdit();
  };

  return (
    <>
      <TransactionsHeaders
        onOpenPayoneerTransactionCreate={onOpenPayoneerTransactionCreate}
        filtersChanged={filtersChanged}
      />

      <TransactionsTable
        updateTransaction={updatePayoneerTransaction}
        pagedTransactions={pagedPayoneerTransactions}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showBinding={handleOpenEdit}
      />

      <TransactionCreateModal
        handleCreate={createPayoneerTransaction}
        isOpen={isOpenPayoneerTransactionCreate}
        onOpen={onOpenPayoneerTransactionCreate}
        onClose={onClosePayoneerTransactionCreate}
      />

      <TransactionBindingModal
        employees={employees}
        salaryPayments={salaryPayments}
        getSalaryPayments={getSalaryPayments}
        handleBinding={updatePayoneerTransactionBinding}
        selectedTransaction={selectedPayoneerTransaction}
        isOpen={isOpenPayoneerTransactionEdit}
        onOpen={onOpenPayoneerTransactionEdit}
        onClose={onClosePayoneerTransactionEdit}
      />
    </>
  );
};
