import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getCountriesDirectory,
  getCountriesDirectoryError,
  getCountriesDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  countries: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  countries: [],
  loaded: false,
};

const countriesDirectorySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getCountriesDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.countries = action.payload;
      })
      .addCase(getCountriesDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default countriesDirectorySlice.reducer;
