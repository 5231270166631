import { DirectoryItem } from "../../../../common/types";

export const PAYONEER_ACCOUNTS = {
  Aragorn: 1,
  Gandalf: 2,
};

export const PAYONEER_ACCOUNTS_OPTIONS: DirectoryItem[] = [
  { label: "Aragorn", value: 1 },
  { label: "Gandalf", value: 2 },
];

export const PAYONEER_TRANSACTION_PURPOSES = {
  Pending: 1,
  PersonalExpenses: 2,
  Income: 3,
  Legio: 4,
  PayoneerFee: 5,
  Transfer: 6,
  PersonalIncome: 7,
};

export const PAYONEER_TRANSACTION_PURPOSES_OPTIONS: DirectoryItem[] = [
  { label: "Pending", value: 1 },
  { label: "Personal Expenses", value: 2 },
  { label: "Income", value: 3 },
  { label: "Legio", value: 4 },
  { label: "Payoneer Fee", value: 5 },
  { label: "Transfer", value: 6 },
  { label: "PersonalIncome", value: 7 },
];

export const PAYONEER_TRANSACTION_STATUSES = {
  Completed: 1,
  Cancelled: 2,
};

export const PAYONEER_TRANSACTION_STATUSES_OPTIONS: DirectoryItem[] = [
  { label: "Completed", value: 1 },
  { label: "Cancelled", value: 2 },
];

export const PAYONEER_TRANSACTION_TYPES = {
  Refill: 1,
  Withdrawal: 2,
};

export const PAYONEER_TRANSACTION_TYPES_OPTIONS: DirectoryItem[] = [
  { label: "Refill", value: 1 },
  { label: "Withdrawal", value: 2 },
];
