import { DirectoryItem } from "../../../../common/types";

export const CONTRACT_SOURCE = {
  Upwork: 1,
  Business: 2,
};

export const CONTRACT_SOURCE_OPTIONS: DirectoryItem[] = [
  { label: "Upwork", value: 1 },
  { label: "Business", value: 2 },
];

