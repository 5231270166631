import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ProjectSimple, ProjectUpdate } from "../types";

import { useForm } from "react-hook-form";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { SimpleDictionaryTable } from "../../../../common/components";
import { FaPlus } from "react-icons/fa";
import {
  formatDate,
  parseDateToISO,
  parseStringToDate,
} from "../../../../common/utils";

const CFaPlus = chakra(FaPlus);

interface IProjectEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedProject: ProjectSimple | null;
  handleUpdate: (project: ProjectUpdate) => void;
  teams: DirectoryItem[];
  contracts: DirectoryItem[];
}

export const ProjectEditModal: React.FC<IProjectEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedProject,
  handleUpdate,
  teams,
  contracts,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    getValues,
  } = useForm();

  const [selectedTeams, setSelectedTeams] = useState<DirectoryItem[]>([]);

  const watchTeam = watch("teamId");

  const handleSubmitUpdate = (values: ProjectUpdate) => {
    const { name, contractId } = values;
    handleUpdate({
      name,
      contractId,
      id: selectedProject ? selectedProject.id : null,
      teamsIds: selectedTeams.map((team) => team.value),
      startDate: parseDateToISO(getValues("startDate")),
      endDate: parseDateToISO(getValues("endDate")),
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedProject,
      startDate: parseStringToDate(
        selectedProject ? selectedProject.startDate : null
      ),
      endDate: parseStringToDate(
        selectedProject ? selectedProject.endDate : null
      ),
    });
    if (selectedProject && selectedProject.teams) {
      const teamsList: DirectoryItem[] = selectedProject.teams.map((team) => {
        return {
          value: team.teamId,
          label: team.teamName,
        };
      });
      setSelectedTeams(teamsList);
    }
  }, [selectedProject]);

  const handleRemoveTeam = (teamId: number) => {
    const filteredTeams = selectedTeams.filter((x) => x.value !== teamId);
    setSelectedTeams(filteredTeams);
  };

  const handleAddTeam = () => {
    const teamId = getValues().teamId;
    if (teamId) {
      const team = teams.find((x) => x.value === teamId);
      if (team) {
        setSelectedTeams([...selectedTeams, team]);
      }
    }
  };

  const handleClose = () => {
    reset();
    setSelectedTeams([]);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit project</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="name" pr={3} isInvalid={errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input {...register("name", { required: true })} />
                      <FormErrorMessage>
                        {errors.name ? "Name is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="status" pl={3}>
                      <FormLabel>Contract</FormLabel>
                      <HookSelect
                        control={control}
                        name={"contractId"}
                        options={contracts}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3}>
                      <FormLabel>Start date</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"startDate"}
                        placeholder={"Select date..."}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>End date</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"endDate"}
                        placeholder={"Select date..."}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="teamId" mr={3}>
                      <FormLabel>Team</FormLabel>
                      <HookSelect
                        control={control}
                        name={"teamId"}
                        options={teams}
                      />
                    </FormControl>
                    <Button
                      mt={"auto"}
                      variant={"outline"}
                      disabled={!watchTeam}
                      onClick={handleAddTeam}
                    >
                      <CFaPlus mr={2} /> Add
                    </Button>
                  </Flex>
                </Box>
              </Box>
              <SimpleDictionaryTable
                directoryEntities={selectedTeams}
                removeEntity={handleRemoveTeam}
              />
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
