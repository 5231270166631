import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getContactTypesDirectory = createAction(
  "DIRECTORIES/GET_CONTACT_TYPES"
);

export const getContactTypesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_CONTACT_TYPES_SUCCESS"
);

export const getContactTypesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_CONTACT_TYPES_ERROR"
);
