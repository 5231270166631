import React from "react";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { PayoneerReport } from "../types";
import { formatCurrency } from "../../../../common/utils";

interface IReportUserProps {
  userName: string;
  income: number;
  amount: number;
  personalIncome: number;
  personalExpenses: number;
}

export const ReportUser: React.FC<IReportUserProps> = ({
  userName,
  income,
  amount,
  personalIncome,
  personalExpenses,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
      <Box mb={4}>
        <Heading size="md" mb={2}>
          {userName}
        </Heading>
        <Divider />
      </Box>

      <Box>
        <StatGroup>
          <Stat m={1} p={2}>
            <StatLabel>Income</StatLabel>
            <StatNumber>{formatCurrency(income)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Amount</StatLabel>
            <StatNumber>{formatCurrency(amount)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Personal expenses</StatLabel>
            <StatNumber>{formatCurrency(personalExpenses)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Personal income</StatLabel>
            <StatNumber>{formatCurrency(personalIncome)}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </Box>
  );
};
