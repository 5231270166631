import { createSlice } from "@reduxjs/toolkit";
import { User } from "../types";
import { BaseState, Paged } from "../../../common/models/common";
import {
  createUser,
  createUserError,
  createUserSuccess,
  getUsers,
  getUsersError,
  getUsersSuccess,
  resetUsers,
  updateUser,
  updateUserError,
  updateUserSuccess,
} from "./actions";

type InitialState = BaseState & {
  users: Paged<User>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  users: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getUsersSuccess, (state, action) => {
        state.users = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getUsersError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateUser, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateUserSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateUserError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createUser, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createUserSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createUserError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetUsers, (state) => {
        state.users.entities = [];
      });
  },
});

export default usersSlice.reducer;
