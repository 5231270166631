import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getUserProfile,
  getUserProfileError,
  getUserProfileShort,
  getUserProfileShortError,
  getUserProfileShortSuccess,
  getUserProfileSuccess,
  updateUserProfile,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "../../actions/profile/profile.actions";
import { ApiResponse } from "../../../common/models/common";
import {
  UserProfile,
  UserProfileShort,
} from "../../../common/types/profile.types";

const apiUrl = `/api/profile`;

function* workerGetUserProfile(action: ReturnType<typeof getUserProfile>) {
  try {
    const result: ApiResponse<UserProfile> = yield call(API.get, apiUrl);
    if (result.success) {
      yield put(getUserProfileSuccess(result.value));
    } else {
      yield put(getUserProfileError(result.errors));
    }
  } catch (e) {
    yield put(getUserProfileError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerGetUserProfileShort(
  action: ReturnType<typeof getUserProfileShort>
) {
  try {
    const result: ApiResponse<UserProfileShort> = yield call(
      API.get,
      `${apiUrl}/short`
    );
    if (result.success) {
      yield put(getUserProfileShortSuccess(result.value));
    } else {
      yield put(getUserProfileShortError(result.errors));
    }
  } catch (e) {
    yield put(getUserProfileShortError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateUserProfile(
  action: ReturnType<typeof updateUserProfile>
) {
  try {
    const result: ApiResponse<UserProfile> = yield call(
      API.put,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(updateUserProfileSuccess());
      yield put(getUserProfile());
    } else {
      yield put(updateUserProfileError(result.errors));
    }
  } catch (e) {
    yield put(updateUserProfileError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchUserProfilesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getUserProfile.type, workerGetUserProfile);
  yield takeEvery(getUserProfileShort.type, workerGetUserProfileShort);
  yield takeEvery(updateUserProfile.type, workerUpdateUserProfile);
}
