import { createSlice } from "@reduxjs/toolkit";
import { TEmployeeFullInfo, TEmployeeProfile } from "../types";
import { BaseState } from "../../../../common/models/common";
import {
  getEmployeeProfile,
  getEmployeeProfileError,
  getEmployeeProfileSuccess,
  resetSelectedEmployee,
} from "./actions";
import { combineReducers, Reducer } from "redux";
import employeePaymentsReducer from "../modules/EmployeePayments/store/slice";
import employeeSnapshotsReducer from "../modules/EmployeeSnapshots/store/slice";
import employeeVacationsReducer from "../modules/EmployeeVacations/store/slice";

type InitialState = BaseState & {
  profile: TEmployeeProfile;
};

const initialEmployeeInfo: TEmployeeFullInfo = {
  address: "",
  avatarStorageHash: "",
  birthDate: new Date(),
  cityName: "",
  contactId: 0,
  departmentId: null,
  departmentName: "",
  email: "",
  firstName: "",
  genderName: "",
  hireDate: new Date(),
  homePhone: null,
  id: 0,
  jobId: null,
  jobName: "",
  lastName: "",
  middleName: "",
  mobilePhone: "",
  phone: "",
  quitDate: "",
  skype: "",
  status: 0,
  zipCode: "",
};

const initialEmployeeProfileState: TEmployeeProfile = {
  hiredDays: 0,
  hiredMonths: 0,
  hiredYears: 0,
  currentMonth: 0,
  currentSalary: 0,
  employee: initialEmployeeInfo,
  id: 0,
  manualHoursTimeString: "",
  nextMonthIteration: new Date(),
  nextSalaryReviewDate: new Date(),
  nextSalaryReviewDayAmount: 0,
  nextSalaryReviewMonthAmount: 0,
  nextSalaryReviewYearAmount: 0,
  overtimeHoursTimeString: "",
  profitAverageByMonth: 0,
  upworkRefunds: 0,
  totalProfit: 0,
  totalTrackedHoursTimeString: "",
  totalUpworkProfit: 0,
  upworkBonus: 0,
  upworkFee: 0,
  upworkIncome: 0,
  salaryPaidInfoList: [],
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  profile: initialEmployeeProfileState,
  loaded: false,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeProfile, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeeProfileSuccess, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getEmployeeProfileError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetSelectedEmployee, (state) => {
        state.profile = initialEmployeeProfileState;
      });
  },
});

export default combineReducers({
  profile: employeeSlice.reducer,
  payments: employeePaymentsReducer,
  snapshots: employeeSnapshotsReducer,
  vacations: employeeVacationsReducer,
});
