import React from "react";
import {
  Avatar,
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { TEmployeeProfile } from "../types";
import { AuthImage } from "../../../../common/components";
import { formatCurrencyFixed, formatDate } from "../../../../common/utils";
import { MdCheckCircle } from "react-icons/all";

interface IEmployeesInfoProps {
  employeeProfile: TEmployeeProfile;
}

export const EmployeeInfo: React.FC<IEmployeesInfoProps> = ({
  employeeProfile,
}) => {
  return (
    <Box overflow="hidden" p={6}>
      <Flex>
        <Box w={"20%"} mr={4}>
          <AuthImage
            url={
              `/api/storage/images/contact-avatar/` +
              employeeProfile.employee.avatarStorageHash
            }
            childComponent={<Avatar size={"3xl"} mb={4} />}
          />
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            mb={4}
          >
            <Stat m={1} p={2}>
              <StatLabel>Hired</StatLabel>
              <StatNumber>
                {formatDate(employeeProfile.employee.hireDate)}
              </StatNumber>
            </Stat>

            <Stat m={1} p={2}>
              <StatLabel>Quit</StatLabel>
              <StatNumber>
                {employeeProfile.employee.quitDate
                  ? formatDate(employeeProfile.employee.quitDate)
                  : "-"}
              </StatNumber>
            </Stat>

            <Stat m={1} p={2}>
              <StatLabel>Current month</StatLabel>
              <StatNumber>{employeeProfile.currentMonth}</StatNumber>
            </Stat>

            <Stat m={1} p={2}>
              <StatLabel>Next month iteration</StatLabel>
              <StatNumber>
                {employeeProfile.nextMonthIteration
                  ? formatDate(employeeProfile.nextMonthIteration)
                  : "-"}
              </StatNumber>
            </Stat>
          </Box>
        </Box>
        <Box w={"80%"}>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            mb={4}
          >
            <StatGroup>
              <Stat m={1} p={2}>
                <StatLabel>Current salary</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.currentSalary)}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Next Review</StatLabel>
                <StatNumber>
                  {employeeProfile.nextSalaryReviewDate
                    ? formatDate(employeeProfile.nextSalaryReviewDate)
                    : "-"}
                </StatNumber>
              </Stat>
            </StatGroup>
            <StatGroup>
              <Stat m={1} p={2}>
                <StatLabel>Review in</StatLabel>
                <StatNumber>
                  {employeeProfile.nextSalaryReviewYearAmount +
                    " years " +
                    employeeProfile.nextSalaryReviewMonthAmount +
                    " month " +
                    employeeProfile.nextSalaryReviewDayAmount +
                    " days"}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Hired for</StatLabel>
                <StatNumber>
                  {employeeProfile.hiredYears +
                    " years " +
                    employeeProfile.hiredMonths +
                    " month " +
                    employeeProfile.hiredDays +
                    " days"}
                </StatNumber>
              </Stat>
            </StatGroup>
          </Box>
          {/*<Box*/}
          {/*  borderWidth="1px"*/}
          {/*  borderRadius="lg"*/}
          {/*  overflow="hidden"*/}
          {/*  p={4}*/}
          {/*  mb={4}*/}
          {/*>*/}
          {/*  <StatGroup>*/}
          {/*    <Stat m={1} p={2}>*/}
          {/*      <StatLabel>Hired</StatLabel>*/}
          {/*      <StatNumber>*/}
          {/*        {formatDate(employeeProfile.employee.hireDate)}*/}
          {/*      </StatNumber>*/}
          {/*    </Stat>*/}

          {/*    <Stat m={1} p={2}>*/}
          {/*      <StatLabel>Quit</StatLabel>*/}
          {/*      <StatNumber>*/}
          {/*        {employeeProfile.employee.quitDate*/}
          {/*          ? formatDate(employeeProfile.employee.quitDate)*/}
          {/*          : "-"}*/}
          {/*      </StatNumber>*/}
          {/*    </Stat>*/}

          {/*    <Stat m={1} p={2}>*/}
          {/*      <StatLabel>Current month</StatLabel>*/}
          {/*      <StatNumber>{employeeProfile.currentMonth}</StatNumber>*/}
          {/*    </Stat>*/}

          {/*    <Stat m={1} p={2}>*/}
          {/*      <StatLabel>Next month iteration</StatLabel>*/}
          {/*      <StatNumber>*/}
          {/*        {employeeProfile.nextMonthIteration*/}
          {/*          ? formatDate(employeeProfile.nextMonthIteration)*/}
          {/*          : "-"}*/}
          {/*      </StatNumber>*/}
          {/*    </Stat>*/}
          {/*  </StatGroup>*/}
          {/*</Box>*/}
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            mb={employeeProfile.salaryPaidInfoList.length ? 4 : 0}
          >
            <StatGroup>
              <Stat m={1} p={2}>
                <StatLabel>Total upwork profit</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.totalUpworkProfit)}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Upwork income</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.upworkIncome)}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Upwork fee</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.upworkFee)}
                </StatNumber>
              </Stat>
            </StatGroup>
            <StatGroup>
              <Stat m={1} p={2}>
                <StatLabel>Upwork refunds</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.upworkRefunds)}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Total profit</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.totalProfit)}
                </StatNumber>
              </Stat>

              <Stat m={1} p={2}>
                <StatLabel>Average by month</StatLabel>
                <StatNumber>
                  ${formatCurrencyFixed(employeeProfile.profitAverageByMonth)}
                </StatNumber>
              </Stat>
            </StatGroup>
          </Box>
          {employeeProfile.salaryPaidInfoList.length ? (
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              <Text mb={2}>Payments</Text>
              <List spacing={2}>
                {employeeProfile.salaryPaidInfoList.map((value, index) => {
                  return (
                    <ListItem key={index}>
                      {value.currencyCode}
                      {formatCurrencyFixed(value.total)}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Flex>
    </Box>
  );
};
