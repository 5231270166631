import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import { ApiResponse, Paged } from "../../../common/models/common";
import {
  connectUpworkAccount,
  connectUpworkAccountError,
  connectUpworkAccountSuccess,
  disconnectUpworkAccount,
  disconnectUpworkAccountError,
  disconnectUpworkAccountSuccess,
} from "../../actions/auth";
import { getUserProfile } from "../../actions/profile/profile.actions";

const apiUrl = `/api/upwork/auth/`;

function* workerConnectUpworkAccount(
  action: ReturnType<typeof connectUpworkAccount>
) {
  try {
    const result: ApiResponse<any> = yield call(
      API.get,
      `${apiUrl}${action.payload}`
    );
    if (result.success) {
      yield put(getUserProfile());
      yield put(connectUpworkAccountSuccess(result.value));
    } else {
      yield put(connectUpworkAccountError(result.errors));
    }
  } catch (e) {
    yield put(
      connectUpworkAccountError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerDisconnectUpworkAccount(
  action: ReturnType<typeof disconnectUpworkAccount>
) {
  try {
    const result: ApiResponse<any> = yield call(
      API.delete,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getUserProfile());
      yield put(disconnectUpworkAccountSuccess(result.value));
    } else {
      yield put(disconnectUpworkAccountError(result.errors));
    }
  } catch (e) {
    yield put(
      disconnectUpworkAccountError([{ key: "", errorMessage: e.message }])
    );
  }
}

export function* watchUpworkAuthSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(connectUpworkAccount.type, workerConnectUpworkAccount);
  yield takeEvery(disconnectUpworkAccount.type, workerDisconnectUpworkAccount);
}
