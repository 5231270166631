import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect } from "react";
import { getProject, resetSelectedProject } from "./store/actions";
import { Project } from "./Project";
import { useParams } from "react-router-dom";

export const ProjectContainer: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  // Queries
  const selectedProject = useSelector(
    (state: RootStore) => state.project.project
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetProject = (projectId: number) => dispatch(getProject(projectId));
  const onResetProject = () => dispatch(resetSelectedProject());

  useEffect(() => {
    onGetProject(+projectId);
    return () => {
      onResetProject();
    };
  }, []);

  return <Project project={selectedProject} />;
};
