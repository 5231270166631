import React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Skeleton,
  SkeletonCircle,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Contact } from "../types";
import { AuthImage, LabelValueList } from "../../../common/components";
import { formatDate } from "../../../common/utils";

interface IContactViewModalProps {
  selectedContact: Contact;
  isOpen: boolean;
  isLoading: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const ContactViewModal: React.FC<IContactViewModalProps> = ({
  selectedContact,
  isOpen,
  isLoading,
  onOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          <Flex>
            <Box w={"40%"}>
              {selectedContact.avatarStorageHash ? (
                <AuthImage
                  url={
                    `/api/storage/images/contact-avatar/` +
                    selectedContact.avatarStorageHash
                  }
                  childComponent={<Avatar size="3xl" />}
                />
              ) : (
                <Avatar size="3xl" />
              )}
            </Box>

            <Box w={"60%"} pl={10}>
              <Skeleton isLoaded={!isLoading}>
                <Heading size={"md"}>
                  {selectedContact.firstName} {selectedContact.lastName}
                </Heading>
                <Heading size={"sm"} mb={2} color={"gray.600"}>
                  {selectedContact.email}
                </Heading>

                <LabelValueList
                  labelValueList={[
                    { label: "Phone", value: selectedContact.phone },
                    {
                      label: "Birth date",
                      value: formatDate(selectedContact.birthDate),
                    },
                    { label: "Skype", value: selectedContact.skype },
                    { label: "Address", value: selectedContact.address },
                    { label: "Zip code", value: selectedContact.zipCode },
                    {
                      label: "Contact type",
                      value: selectedContact.contactTypeName,
                    },
                    {
                      label: "Gender",
                      value: selectedContact.genderName,
                    },
                    {
                      label: "City",
                      value: selectedContact.cityName,
                    },
                  ]}
                />
              </Skeleton>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
