import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../../../common/types";
import {
  UpworkEmployeeSnapshots,
  UpworkEmployeeSnapshotsRequest,
} from "../types";

export const getEmployeeUpworkSnapshots =
  createAction<UpworkEmployeeSnapshotsRequest>(
    "EMPLOYEE/GET_EMPLOYEE_UPWORK_SNAPSHOTS"
  );

export const getEmployeeUpworkSnapshotsSuccess =
  createAction<UpworkEmployeeSnapshots>(
    "EMPLOYEE/GET_EMPLOYEE_UPWORK_SNAPSHOTS_SUCCESS"
  );

export const getEmployeeUpworkSnapshotsError = createAction<PayloadError[]>(
  "EMPLOYEE/GET_EMPLOYEE_UPWORK_SNAPSHOTS_ERROR"
);

export const resetEmployeeUpworkSnapshots = createAction(
  "EMPLOYEE/RESET_EMPLOYEE_UPWORK_SNAPSHOTS"
);
