import { combineReducers } from "redux";
import contactsDirectoryReducer from "./contacts-directory.slice";
import contractsDirectoryReducer from "./contracts-directory.slice";
import rolesDirectoryReducer from "./roles-directory.slice";
import departmentsDirectoryReducer from "./departments-directory.slice";
import jobsDirectoryReducer from "./jobs-directory.slice";
import employeesDirectoryReducer from "./employees-directory.slice";
import partnersDirectoryReducer from "./partners-directory.slice";
import addressTypesDirectoryReducer from "./address-types-directory.slice";
import countriesTypesDirectoryReducer from "./countries-directory.slice";
import partnerTypesDirectoryReducer from "./partner-types-directory.slice";
import partnerIndustriesTypesDirectoryReducer from "./partner-industries-directory.slice";
import salaryPaymentsDirectoryReducer from "./salary-payments-directory.slice";
import teamsDirectoryReducer from "./teams-directory.slice";
import citiesDirectoryReducer from "./cities-directory.slice";
import gendersDirectoryReducer from "./genders-directory.slice";
import contactTypesDirectoryReducer from "./contact-types.slice";

export const directoriesReducer = combineReducers({
  contacts: contactsDirectoryReducer,
  roles: rolesDirectoryReducer,
  departments: departmentsDirectoryReducer,
  jobs: jobsDirectoryReducer,
  employees: employeesDirectoryReducer,
  partners: partnersDirectoryReducer,
  addressTypes: addressTypesDirectoryReducer,
  countries: countriesTypesDirectoryReducer,
  partnerTypes: partnerTypesDirectoryReducer,
  partnerIndustries: partnerIndustriesTypesDirectoryReducer,
  salaryPayments: salaryPaymentsDirectoryReducer,
  contracts: contractsDirectoryReducer,
  teams: teamsDirectoryReducer,
  cities: citiesDirectoryReducer,
  genders: gendersDirectoryReducer,
  contactTypes: contactTypesDirectoryReducer,
});
