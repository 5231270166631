import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ProjectCreate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { FaPlus } from "react-icons/fa";
import { SimpleDictionaryTable } from "../../../../common/components";
import { formatDate, parseDateToISO } from "../../../../common/utils";

const CFaPlus = chakra(FaPlus);

interface IProjectCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (project: ProjectCreate) => void;
  teams: DirectoryItem[];
  contracts: DirectoryItem[];
}

export const ProjectCreateModal: React.FC<IProjectCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  teams,
  contracts,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    getValues,
  } = useForm();

  const [selectedTeams, setSelectedTeams] = useState<DirectoryItem[]>([]);

  const handleRemoveTeam = (teamId: number) => {
    const filteredTeams = selectedTeams.filter((x) => x.value !== teamId);
    setSelectedTeams(filteredTeams);
  };

  const handleAddTeam = () => {
    const teamId = getValues().teamId;
    if (teamId) {
      const team = teams.find((x) => x.value === teamId);
      if (team) {
        setSelectedTeams([...selectedTeams, team]);
      }
    }
  };

  const watchTeam = watch("teamId");

  const handleSubmitCreate = (values: ProjectCreate) => {
    const selectedTeamsIds = selectedTeams.map((team) => team.value);
    handleCreate({
      ...values,
      teamsIds: selectedTeamsIds,
      startDate: parseDateToISO(getValues("startDate")),
      endDate: parseDateToISO(getValues("endDate")),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    setSelectedTeams([]);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New team</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="name" pr={3} isInvalid={errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input {...register("name", { required: true })} />
                      <FormErrorMessage>
                        {errors.name ? "Name is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Contract</FormLabel>
                      <HookSelect
                        control={control}
                        name={"contractId"}
                        options={contracts}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3}>
                      <FormLabel>Start date</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"startDate"}
                        placeholder={"Select date..."}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>End date</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"endDate"}
                        placeholder={"Select date..."}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="teamId" mr={3}>
                      <FormLabel>Team</FormLabel>
                      <HookSelect
                        control={control}
                        name={"teamId"}
                        options={teams}
                      />
                    </FormControl>
                    <Button
                      mt={"auto"}
                      variant={"outline"}
                      disabled={!watchTeam}
                      onClick={handleAddTeam}
                    >
                      <CFaPlus mr={2} /> Add
                    </Button>
                  </Flex>
                </Box>
              </Box>
              <SimpleDictionaryTable
                directoryEntities={selectedTeams}
                removeEntity={handleRemoveTeam}
              />
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
