import { createSlice } from "@reduxjs/toolkit";
import { Milestone } from "../types";
import { BaseState, Paged } from "../../../../../../common/models/common";
import {
  createMilestone,
  createMilestoneError,
  createMilestoneSuccess,
  getMilestones,
  getMilestonesError,
  getMilestonesSuccess,
  resetMilestones,
  updateMilestone,
  updateMilestoneError,
  updateMilestoneSuccess,
} from "./actions";

type InitialState = BaseState & {
  milestones: Paged<Milestone>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  milestones: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const milestonesSlice = createSlice({
  name: "projectMilestones",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMilestones, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getMilestonesSuccess, (state, action) => {
        state.milestones = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getMilestonesError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateMilestone, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateMilestoneSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateMilestoneError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createMilestone, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createMilestoneSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createMilestoneError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetMilestones, (state) => {
        state.milestones.entities = [];
      });
  },
});

export default milestonesSlice.reducer;
