import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getContactsDirectory,
  getContactsDirectoryError,
  getContactsDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  contacts: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  contacts: [],
  loaded: false,
};

const contactsDirectorySlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactsDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContactsDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.contacts = action.payload;
      })
      .addCase(getContactsDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default contactsDirectorySlice.reducer;
