import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { Milestone } from "../types";
import { Paged } from "../../../../../../common/models";
import { SharedPagination } from "../../../../../../common/components";
import {
  formatCurrencyFixed,
  formatDate,
} from "../../../../../../common/utils";

interface IMilestonesListProps {
  pagedMilestones: Paged<Milestone>;
  showEdit: (milestone: Milestone) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const MilestonesTable: React.FC<IMilestonesListProps> = ({
  pagedMilestones,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Title</Th>
            <Th>Cost</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedMilestones.entities.map((milestone, index) => {
            return (
              <Tr key={index}>
                <Td>{milestone.id}</Td>
                <Td>{milestone.title}</Td>
                <Td>
                  {milestone.currencySymbol}
                  {formatCurrencyFixed(milestone.cost)}
                </Td>
                <Td>{formatDate(milestone.startDate)}</Td>
                <Td>{formatDate(milestone.endDate)}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(milestone)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedMilestones.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
