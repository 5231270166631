import { all, AllEffect, ForkEffect } from "redux-saga/effects";
import watchContactsDirectorySaga from "./contacts-directory.saga";
import watchRolesDirectorySaga from "./roles-directory.saga";
import watchDepartmentsDirectorySaga from "./departments-directory.saga";
import watchJobsDirectorySaga from "./jobs-directory.saga";
import watchEmployeesDirectorySaga from "./employees-directory.saga";
import watchPartnersDirectorySaga from "./partners-directory.saga";
import watchAddressTypesDirectorySaga from "./address-types-directory.saga";
import watchCountriesDirectorySaga from "./countries-directory.saga";
import watchPartnerTypesDirectorySaga from "./partner-types-directory.saga";
import watchPartnerIndustriesDirectorySaga from "./partner-industries-directory.saga";
import watchSalaryPaymentsDirectorySaga from "./salary-payments-directory.saga";
import watchContractsDirectorySaga from "./contracts-directory.saga";
import watchTeamsDirectorySaga from "./teams-directory.saga";
import watchCitiesDirectorySaga from "./cities-directory.saga";
import watchContactTypesDirectorySaga from "./contact-types-directory.saga";
import watchGendersDirectorySaga from "./genders-directory.saga";

export default function* watchDirectoriesSaga(): Generator<
  AllEffect<Generator<ForkEffect<never>, void, unknown>>
> {
  yield all([
    watchContactsDirectorySaga(),
    watchRolesDirectorySaga(),
    watchDepartmentsDirectorySaga(),
    watchJobsDirectorySaga(),
    watchEmployeesDirectorySaga(),
    watchPartnersDirectorySaga(),
    watchAddressTypesDirectorySaga(),
    watchCountriesDirectorySaga(),
    watchPartnerTypesDirectorySaga(),
    watchPartnerIndustriesDirectorySaga(),
    watchSalaryPaymentsDirectorySaga(),
    watchContractsDirectorySaga(),
    watchTeamsDirectorySaga(),
    watchCitiesDirectorySaga(),
    watchContactTypesDirectorySaga(),
    watchGendersDirectorySaga(),
  ]);
}
