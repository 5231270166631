import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { Contract } from "../types";
import { Paged } from "../../../../common/models";
import { SharedPagination } from "../../../../common/components";
import { formatDate } from "../../../../common/utils";
import { extractLabelByValue } from "../../../../common/utils/const.utils";
import { CONTRACT_SOURCE_OPTIONS, CONTRACT_TYPE_OPTIONS } from "../const";

interface IContractsListProps {
  pagedContracts: Paged<Contract>;
  showEdit: (contract: Contract) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const ContractsTable: React.FC<IContractsListProps> = ({
  pagedContracts,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th>Source</Th>
            <Th>Type</Th>
            <Th>Partner</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedContracts.entities.map((contract, index) => {
            return (
              <Tr key={index}>
                <Td>{contract.name}</Td>
                <Td>{formatDate(contract.startDate)}</Td>
                <Td>{formatDate(contract.endDate)}</Td>
                <Td>
                  {extractLabelByValue(
                    CONTRACT_SOURCE_OPTIONS,
                    contract.source
                  )}
                </Td>
                <Td>
                  {extractLabelByValue(CONTRACT_TYPE_OPTIONS, contract.type)}
                </Td>
                <Td>{contract.partnerName}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(contract)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedContracts.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
