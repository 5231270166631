import { createSlice } from "@reduxjs/toolkit";
import { ContactUsLead } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  getContactUsLeads,
  getContactUsLeadsError,
  getContactUsLeadsSuccess,
  removeContactUsLead,
  removeContactUsLeadError,
  removeContactUsLeadSuccess,
  resetContactUsLeads,
  updateContactUsLead,
  updateContactUsLeadError,
  updateContactUsLeadSuccess,
} from "./actions";

type InitialState = BaseState & {
  contactUsLeads: Paged<ContactUsLead>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  contactUsLeads: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const contactUsLeadsSlice = createSlice({
  name: "contactUsLeads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactUsLeads, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContactUsLeadsSuccess, (state, action) => {
        state.contactUsLeads = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getContactUsLeadsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateContactUsLead, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateContactUsLeadSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateContactUsLeadError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(removeContactUsLead, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(removeContactUsLeadSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(removeContactUsLeadError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetContactUsLeads, (state) => {
        state.contactUsLeads.entities = [];
      });
  },
});

export default contactUsLeadsSlice.reducer;
