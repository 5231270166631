import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
// import { ProjectTransactions } from "../types";
// import { PagedType } from "../../../common/models";
// import { SharedPagination } from "../../../common/components";

interface IProjectTransactionsListProps {
  // pagedProjectTransactions: PagedType<ProjectTransactions>;
  // showEdit: (projectTransactions: ProjectTransactions) => void;
  // pageSize: number;
  // offsetChanged: (offset: number) => void;
  // currentPage: number;
  // setCurrentPage: (page: number) => void;
}

export const ProjectTransactionsTable: React.FC<IProjectTransactionsListProps> = ({}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {/*{pagedProjectTransactionss.entities.map((projectTransactions, index) => {*/}
          {/*  return (*/}
          {/*    <Tr key={index}>*/}
          {/*      <Td>{projectTransactions.id}</Td>*/}
          {/*      <Td isNumeric>*/}
          {/*        <Button*/}
          {/*          colorScheme="teal"*/}
          {/*          variant="ghost"*/}
          {/*          onClick={() => showEdit(projectTransactions)}*/}
          {/*        >*/}
          {/*          <FaPencilAlt />*/}
          {/*        </Button>*/}
          {/*      </Td>*/}
          {/*    </Tr>*/}
          {/*  );*/}
          {/*})}*/}
        </Tbody>
        {/*<SharedPagination*/}
        {/*  totalRecords={pagedProjectTransactionss.total}*/}
        {/*  pageLimit={pageSize}*/}
        {/*  pageNeighbours={2}*/}
        {/*  setOffset={offsetChanged}*/}
        {/*  currentPage={currentPage}*/}
        {/*  setCurrentPage={setCurrentPage}*/}
        {/*/>*/}
      </Table>
    </Box>
  );
};
