import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../../common/api/api.base";
import {
  createEmployeePayment,
  createEmployeePaymentError,
  createEmployeePaymentSuccess,
  getEmployeePayments,
  getEmployeePaymentsError,
  getEmployeePaymentsSuccess,
  updateEmployeePayment,
  updateEmployeePaymentError,
  updateEmployeePaymentSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../../../common/models/common";
import { EmployeePayment } from "../types";

const apiUrl = `/api/employee/salary-payments`;

function* workerGetEmployeePayments(
  action: ReturnType<typeof getEmployeePayments>
) {
  try {
    const result: ApiResponse<Paged<EmployeePayment>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeePaymentsSuccess(result.value));
    } else {
      yield put(getEmployeePaymentsError(result.errors));
    }
  } catch (e) {
    yield put(getEmployeePaymentsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateEmployeePayment(
  action: ReturnType<typeof updateEmployeePayment>
) {
  try {
    const result: ApiResponse<EmployeePayment> = yield call(
      API.put,
      apiUrl,
      action.payload.employeePayment
    );
    if (result.success) {
      yield put(updateEmployeePaymentSuccess());
      yield put(getEmployeePayments(action.payload.request));
    } else {
      yield put(updateEmployeePaymentError(result.errors));
    }
  } catch (e) {
    yield put(
      updateEmployeePaymentError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerCreateEmployeePayment(
  action: ReturnType<typeof createEmployeePayment>
) {
  try {
    const result: ApiResponse<EmployeePayment> = yield call(
      API.post,
      apiUrl,
      action.payload.employeePayment
    );
    if (result.success) {
      yield put(createEmployeePaymentSuccess(result.value));
      // Call update contacts list
      yield put(getEmployeePayments(action.payload.request));
    } else {
      yield put(createEmployeePaymentError(result.errors));
    }
  } catch (e) {
    yield put(
      createEmployeePaymentError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchEmployeePaymentsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getEmployeePayments.type, workerGetEmployeePayments);
  yield takeEvery(createEmployeePayment.type, workerCreateEmployeePayment);
  yield takeEvery(updateEmployeePayment.type, workerUpdateEmployeePayment);
}
