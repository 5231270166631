// import {ProjectTransaction} from "./ProjectTransaction";
import {useDispatch, useSelector} from "react-redux";
// import {RootStore} from "../../../../store";
import React, {useEffect, useState} from "react";
import {ProjectTransactionsTable} from "./components/ProjectTransactionsTable";
// import {PagedRequestType} from "../../../../common/models";

export const ProjectTransactionsContainer: React.FC = () => {
    // // Pagination
    // const [offset, setOffset] = useState<number>(0);
    // const [currentPage, setCurrentPage] = useState<number>(1);
    // const pageSize: number = 5;
    //
    // // Queries
    // const projectTransactions = useSelector((state: RootStore) => state.projectTransactions.projectTransactions);
    // const projectTransactionLoading = useSelector(
    //     (state: RootStore) => state.projectTransactions.loading
    // );
    //
    // // Dispatches
    // const dispatch = useDispatch();
    // const onGetProjectTransactions = (payload: PagedRequestType) =>
    //     dispatch(getProjectTransactions(payload));
    // const onUpdateProjectTransaction = (projectTransaction: ProjectTransaction) =>
    //     dispatch(
    //         updateProjectTransaction({
    //             projectTransaction,
    //             request: {offset: offset, take: pageSize},
    //         })
    //     );
    // const onCreateProjectTransaction = (projectTransaction: ProjectTransaction) =>
    //     dispatch(
    //         createProjectTransaction({
    //             projectTransaction,
    //             request: {offset: offset, take: pageSize},
    //         })
    //     );
    // const onResetProjectTransactions = () => dispatch(resetProjectTransactions());
    //
    // const offsetChanged = (offset: number) => {
    //     onResetProjectTransactions();
    //     setOffset(offset);
    //     onGetProjectTransactions({offset: offset, take: pageSize});
    // };

    return (
        <ProjectTransactionsTable/>
        // <ProjectTransactions
        //     pagedProjectTransactions={projectTransactions}
        //     selectedProjectTransaction={selectedProjectTransaction}
        //     projectTransactionLoading={projectTransactionLoading}
        //     createProjectTransaction={onCreateProjectTransaction}
        //     updateProjectTransaction={onUpdateProjectTransaction}
        //     pageSize={pageSize}
        //     offsetChanged={offsetChanged}
        //     setCurrentPage={setCurrentPage}
        //     currentPage={currentPage}
        // />
    );
};
