import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";

export const getPayoneerReport = createAction("PAYONEER/GET_PAYONEER_REPORT");

export const getPayoneerReportSuccess = createAction<any>(
  "PAYONEER/GET_PAYONEER_REPORT_SUCCESS"
);

export const getPayoneerReportError = createAction<PayloadError[]>(
  "PAYONEER/GET_PAYONEER_REPORT_ERROR"
);
