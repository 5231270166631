import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../../common/api/api.base";
import {
  getEmployeeUpworkSnapshots,
  getEmployeeUpworkSnapshotsError,
  getEmployeeUpworkSnapshotsSuccess,
} from "./actions";
import { ApiResponse } from "../../../../../../common/models/common";
import { UpworkEmployeeSnapshots } from "../types";

const apiUrl = `/api/employee/profile/upwork-snapshots`;

function* workerGetEmployeeUpworkSnapshots(
  action: ReturnType<typeof getEmployeeUpworkSnapshots>
) {
  try {
    const result: ApiResponse<UpworkEmployeeSnapshots> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeeUpworkSnapshotsSuccess(result.value));
    } else {
      yield put(getEmployeeUpworkSnapshotsError(result.errors));
    }
  } catch (e) {
    yield put(
      getEmployeeUpworkSnapshotsError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchEmployeeUpworkSnapshotsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    getEmployeeUpworkSnapshots.type,
    workerGetEmployeeUpworkSnapshots
  );
}
