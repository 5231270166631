import React from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { ContactUsLead } from "../types";

interface IContactViewModalProps {
  selectedContactUsLead: ContactUsLead;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const ContactUsLeadsViewModal: React.FC<IContactViewModalProps> = ({
  selectedContactUsLead,
  isOpen,
  onOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          <Box p={4}>{selectedContactUsLead.text}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
