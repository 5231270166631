import React, { ReactNode, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { FiFile } from "react-icons/fi";

interface IFileUploadProps {
  register: any;
  name: string;
  fileChanged: (file: File) => void;
  accept?: string;
  multiple?: boolean;
  placeholder?: string;
  isLoading?: boolean;
}

export const FileInput: React.FC<IFileUploadProps> = ({
  register,
  name,
  fileChanged,
  accept,
  multiple,
  placeholder = "Select avatar...",
  isLoading = false,
}) => {
  const [fileForUpload, setFileForUpload] = useState(null);

  const inputEl = useRef<HTMLHeadingElement>(null);
  const onInputClick = () => {
    // @ts-ignore
    inputEl.current.click();
    // @ts-ignore
    inputEl.current.value = "";
  };

  const handleFileChange = (e: any) => {
    const fr = new FileReader();
    const file = e.target.files[0];
    fr.onload = () => {
      setFileForUpload(file);
      fileChanged(file);
    };
    fr.readAsDataURL(file);
  };

  return (
    <InputGroup size="md">
      <input
        type={"file"}
        name={name}
        multiple={multiple || false}
        style={{ display: "none" }}
        // @ts-ignore
        ref={inputEl}
        accept={accept}
        onChange={handleFileChange}
        disabled={isLoading}
      />
      <InputLeftElement width="4.5rem">
        <Button
          ml={2}
          mr={2}
          h="1.75rem"
          size="sm"
          onClick={onInputClick}
          isLoading={isLoading}
        >
          Upload
        </Button>
      </InputLeftElement>
      <Input
        pl="4.5rem"
        placeholder={placeholder}
        onClick={onInputClick}
        disabled={isLoading}
      />
    </InputGroup>
  );
};
