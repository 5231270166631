import React, { useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Milestone, MilestoneCreate } from "../types";
import {
  HookDatePicker,
  HookSelect,
} from "../../../../../../common/components/libs";
import { formatDate, parseDateToISO } from "../../../../../../common/utils";
import { CURRENCY_OPTIONS } from "../../../../../../common/constants/currencies.const";

interface IMilestoneCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (milestone: MilestoneCreate) => void;
}

export const MilestoneCreateModal: React.FC<IMilestoneCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: MilestoneCreate) => {
    handleCreate({
      ...values,
      startDate: parseDateToISO(values.startDate),
      endDate: parseDateToISO(values.endDate),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New milestone</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Flex mb={3}>
                  <FormControl isInvalid={errors.title}>
                    <FormLabel>Title</FormLabel>
                    <Input {...register("title", { required: true })} />
                    <FormErrorMessage>
                      {errors.title ? "Title is required" : ""}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex mb={3}>
                  <FormControl pr={3} isInvalid={errors.cost}>
                    <FormLabel>Cost</FormLabel>
                    <Input
                      type={"number"}
                      step={"0.01"}
                      {...register("cost", { required: true })}
                    />
                    <FormErrorMessage>
                      {errors.cost ? "Cost is required" : ""}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl pr={3} isInvalid={errors.currencyId}>
                    <FormLabel>Currency</FormLabel>
                    <HookSelect
                      control={control}
                      name={"currencyId"}
                      options={CURRENCY_OPTIONS}
                      rules={{ required: true }}
                    />
                    <FormErrorMessage>
                      {errors.currencyId ? "Currency is required" : ""}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex>
                  <FormControl pr={3}>
                    <FormLabel>Start date</FormLabel>
                    <HookDatePicker control={control} name={"startDate"} />
                  </FormControl>
                  <FormControl pl={3}>
                    <FormLabel>End date</FormLabel>
                    <HookDatePicker control={control} name={"endDate"} />
                  </FormControl>
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
