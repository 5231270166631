// Get all
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { PagedRequest, Paged } from "../../../../common/models";
import { Team, TeamCreate, TeamUpdate } from "../types";

export const getTeams = createAction<PagedRequest>("WORK_TEAMS/GET_TEAMS");

export const getTeamsSuccess = createAction<Paged<any>>(
  "WORK_TEAMS/GET_TEAMS_SUCCESS"
);

export const getTeamsError = createAction<PayloadError[]>(
  "WORK_TEAMS/GET_TEAMS_ERROR"
);

// Get single
export const getTeam = createAction("WORK_TEAMS/GET_TEAM");

export const getTeamSuccess = createAction<any>("WORK_TEAMS/GET_TEAM_SUCCESS");

export const getTeamError = createAction<PayloadError[]>(
  "WORK_TEAMS/GET_TEAM_ERROR"
);

// Create
export const createTeam = createAction<{
  team: TeamCreate;
  request: PagedRequest;
}>("WORK_TEAMS/CREATE_TEAM");

export const createTeamSuccess = createAction<Team>(
  "WORK_TEAMS/CREATE_TEAM_SUCCESS"
);

export const createTeamError = createAction<PayloadError[]>(
  "WORK_TEAMS/CREATE_TEAM_ERROR"
);

// Update
export const updateTeam = createAction<{
  team: TeamUpdate;
  request: PagedRequest;
}>("WORK_TEAMS/UPDATE_TEAM");

export const updateTeamSuccess = createAction("WORK_TEAMS/UPDATE_TEAM_SUCCESS");

export const updateTeamError = createAction<PayloadError[]>(
  "WORK_TEAMS/UPDATE_TEAM_ERROR"
);

// Reset
export const resetSelectedTeam = createAction("WORK_TEAMS/RESET_SELECTED_TEAM");

export const resetTeams = createAction("WORK_TEAMS/RESET");
