import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Spacer,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { HookDatePicker } from "../../../../../../common/components/libs";
import { formatDate, parseDateToISO } from "../../../../../../common/utils";

const CFaPlus = chakra(FaPlus);

interface IEmployeeVacationsHeaderProps {
  onOpenEmployeeVacationCreate: () => void;
  handleCheckVacation: (selectedDate: string) => void;
  vacationDays: number | null;
}

export const EmployeeVacationsHeaders: React.FC<IEmployeeVacationsHeaderProps> =
  ({ onOpenEmployeeVacationCreate, handleCheckVacation, vacationDays }) => {
    const {
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm();

    const [selectedDate, setSelectedDate] = useState<string>("");
    const [checkTipOpen, setCheckTipOpen] = useState(false);

    const handleSubmitRequest = (values: { selectedDate: Date }) => {
      const { selectedDate } = values;
      setSelectedDate(formatDate(selectedDate));
      setCheckTipOpen(true);
      handleCheckVacation(parseDateToISO(selectedDate));
    };

    const closeCheckTip = () => {
      setCheckTipOpen(false);
    };

    return (
      <>
        <Flex mb={3}>
          <Box>
            <form onSubmit={handleSubmit(handleSubmitRequest)}>
              <Flex>
                <Box>
                  <FormControl>
                    <FormLabel>Date checker</FormLabel>
                    <HookDatePicker
                      control={control}
                      name={"selectedDate"}
                      required={true}
                    />
                  </FormControl>
                </Box>
                <Button mt={8} ml={2} type="submit">
                  Check
                </Button>
              </Flex>
            </form>
          </Box>
          <Spacer />
          <Flex mt={8}>
            <Button
              colorScheme="teal"
              onClick={() => onOpenEmployeeVacationCreate()}
            >
              <CFaPlus mr={2} /> Add vacation
            </Button>
          </Flex>
        </Flex>

        {(vacationDays || vacationDays === 0) && checkTipOpen ? (
          <Alert status="info" variant="subtle" mb={3}>
            <AlertIcon />
            <Box flex="1">
              <AlertDescription display="block">
                On <strong>{selectedDate}</strong> you will have {vacationDays}{" "}
                vacation days
              </AlertDescription>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={closeCheckTip}
            />
          </Alert>
        ) : (
          <></>
        )}
      </>
    );
  };
