import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { SalaryCreate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import { formatDate, parseDateToISO } from "../../../../common/utils";

interface ISalaryCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (salary: SalaryCreate) => void;
  employees: DirectoryItem[];
}

export const SalaryCreateModal: React.FC<ISalaryCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  employees,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: SalaryCreate) => {
    handleCreate({
      ...values,
      dateFrom: parseDateToISO(values.dateFrom),
      dateTo: parseDateToISO(values.dateTo),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New salary</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="employeeId"
                      pr={3}
                      isInvalid={errors.employeeId}
                    >
                      <FormLabel>Employee</FormLabel>
                      <HookSelect
                        control={control}
                        name={"employeeId"}
                        options={employees}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.test ? "Employee is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="amount" pl={3} isInvalid={errors.amount}>
                      <FormLabel>Salary</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          type={"number"}
                          step={"0.01"}
                          placeholder={"Enter amount..."}
                          {...register("amount", { required: true })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.amount ? "Salary is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="dateFrom" pr={3}>
                      <FormLabel>From</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"dateFrom"}
                        placeholder={"Date from..."}
                      />
                    </FormControl>
                    <FormControl id="dateTo" pl={3}>
                      <FormLabel>To</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"dateTo"}
                        placeholder={"Date to..."}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
