import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getContactsDirectory,
  getContactsDirectoryError,
  getContactsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/contacts`;

function* workerGetContactsDirectory(
  action: ReturnType<typeof getContactsDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getContactsDirectorySuccess(result.value.entities));
    } else {
      yield put(getContactsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getContactsDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchContactsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContactsDirectory.type, workerGetContactsDirectory);
}
