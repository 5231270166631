import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  createProject,
  createProjectError,
  createProjectSuccess,
  getProjects,
  getProjectsError,
  getProjectsSuccess,
  updateProject,
  updateProjectError,
  updateProjectSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { ProjectSimple } from "../types";

const apiUrl = `/api/projects/`;

function* workerGetProjects(action: ReturnType<typeof getProjects>) {
  try {
    const result: ApiResponse<Paged<ProjectSimple>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getProjectsSuccess(result.value));
    } else {
      yield put(getProjectsError(result.errors));
    }
  } catch (e) {
    yield put(getProjectsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateProject(action: ReturnType<typeof updateProject>) {
  try {
    const result: ApiResponse<ProjectSimple> = yield call(
      API.put,
      apiUrl,
      action.payload.project
    );
    if (result.success) {
      yield put(updateProjectSuccess());
      yield put(getProjects(action.payload.request));
    } else {
      yield put(updateProjectError(result.errors));
    }
  } catch (e) {
    yield put(updateProjectError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateProject(action: ReturnType<typeof createProject>) {
  try {
    const result: ApiResponse<ProjectSimple> = yield call(
      API.post,
      apiUrl,
      action.payload.project
    );
    if (result.success) {
      yield put(createProjectSuccess(result.value));
      // Call update contacts list
      yield put(getProjects(action.payload.request));
    } else {
      yield put(createProjectError(result.errors));
    }
  } catch (e) {
    yield put(createProjectError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchProjectsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getProjects.type, workerGetProjects);
  yield takeEvery(createProject.type, workerCreateProject);
  yield takeEvery(updateProject.type, workerUpdateProject);
}
