import React from "react";
import { ProjectFull } from "./types";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { formatCurrency, formatDate } from "../../../common/utils";
import { FaArrowRight } from "react-icons/all";
import { useHistory } from "react-router-dom";
import { ProjectTransactionsContainer } from "./modules/Transactions/ProjectTransactions.container";
import { MilestonesContainer } from "./modules/Milestones/Milestones.container";

const CFaArrowRight = chakra(FaArrowRight);

interface IProjectProps {
  project: ProjectFull;
}

export const Project: React.FC<IProjectProps> = ({ project }) => {
  const history = useHistory();
  return (
    <>
      <Flex mb={3}>
        <Box>
          <StatGroup>
            <Stat mr={8}>
              <StatLabel>Project</StatLabel>
              <StatNumber>{project.name}</StatNumber>
            </Stat>

            <Stat mr={8}>
              <StatLabel>Contract</StatLabel>
              <StatNumber>{project.contractName}</StatNumber>
            </Stat>

            <Stat mr={8}>
              <StatLabel>Start date</StatLabel>
              <StatNumber>{formatDate(project.startDate)}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>End date</StatLabel>
              <StatNumber>{formatDate(project.endDate)}</StatNumber>
            </Stat>
          </StatGroup>
        </Box>
        <Spacer />
        <Box>
          <Button
            colorScheme={"gray"}
            onClick={() => history.push("../projects")}
          >
            <CFaArrowRight mr={2} />
            Back
          </Button>
        </Box>
      </Flex>
      {/*<Tabs defaultActiveKey="profile" mb={3}>*/}
      {/*  <Tab eventKey="profile" title="Milestones">*/}
      {/*    /!*<MilestonesContainer />*!/*/}
      {/*  </Tab>*/}
      {/*  <Tab eventKey="contact" title="Transactions">*/}
      {/*    /!*<ProjectTransactionsContainer />*!/*/}
      {/*  </Tab>*/}
      {/*</Tabs>*/}
      <Tabs>
        <TabList>
          <Tab>Milestones</Tab>
          <Tab>Transactions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MilestonesContainer />
          </TabPanel>
          <TabPanel>
            <ProjectTransactionsContainer />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
