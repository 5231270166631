import React, { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { Paged } from "../../common/models";
import {
  UserProfile,
  UserProfileUpdate,
} from "../../common/types/profile.types";
import { ProfileForm } from "./components/ProfileForm";
import { ProfileUpwork } from "./components/ProfileUpwork";
import { FocusableElement } from "@chakra-ui/utils/dist/types/tabbable";
import { ProfileUpworkConnectModal } from "./components/ProfileUpworkConnectModal";

// import {ProfileEditModal, ProfilesTable, ProfileCreateModal} from "./components";

interface IProfilesProps {
  profile: UserProfile;
  profileLoading: boolean;
  updateProfile: (profile: UserProfileUpdate) => void;
  getUpworkLink: () => void;
  connectUpworkAccount: (object: any) => void;
  disconnectUpworkAccount: () => void;
}

export const Profile: React.FC<IProfilesProps> = ({
  profile,
  profileLoading,
  updateProfile,
  getUpworkLink,
  connectUpworkAccount,
  disconnectUpworkAccount,
}) => {
  const {
    isOpen: isConnectUpworkOpen,
    onOpen: onOpenConnectUpwork,
    onClose: onCloseConnectUpwork,
  } = useDisclosure();

  const { colorMode, toggleColorMode } = useColorMode();
  const [isDisconnectOpen, setIsDisconnectOpen] = React.useState(false);
  const onClose = () => setIsDisconnectOpen(false);
  const cancelRef = useRef<any>();

  const onConnectUpworkAccount = (oauthVerifier: string) => {
    connectUpworkAccount(oauthVerifier);
    onCloseConnectUpwork();
  };

  const onGetUpworkLink = () => {
    onOpenConnectUpwork();
    getUpworkLink();
  };

  const onShowDisconnectAccount = () => {
    setIsDisconnectOpen(true);
  };

  const onDisconnectUpworkAccount = () => {
    onClose();
    disconnectUpworkAccount();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Profile</Heading>
        <Spacer />
        <Box>
          <Button onClick={toggleColorMode}>
            Toggle {colorMode === "light" ? "Dark" : "Light"}
          </Button>
        </Box>
      </Flex>
      <Flex>
        <Box w={"50%"}>
          <ProfileUpwork
            getUpworkLink={onGetUpworkLink}
            profile={profile}
            disconnectUpworkAccount={onShowDisconnectAccount}
          />
        </Box>
        <Box w={"50%"}>
          <ProfileForm handleUpdate={updateProfile} />
        </Box>
      </Flex>
      <AlertDialog
        isOpen={isDisconnectOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Disconnect account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You cant undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onDisconnectUpworkAccount}
                ml={3}
              >
                Disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <ProfileUpworkConnectModal
        isOpen={isConnectUpworkOpen}
        onOpen={onOpenConnectUpwork}
        onClose={onCloseConnectUpwork}
        handleSubmitToken={onConnectUpworkAccount}
      />
    </>
  );
};
