import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const Button = {
  defaultProps: {
    colorScheme: "teal",
  },
};

const Heading = {
  defaultProps: {
    color: "gray.300",
  },
};

const Input = {
  defaultProps: {
    focusBorderColor: "gray.400",
  },
};

const Tr = {
  defaultProps: {
    variants: {
      // @ts-ignore
      solid({ colorScheme }) {
        return {
          _hover: {
            bg: `${colorScheme}.300`,
          },
          _active: { bg: `${colorScheme}.200` },
        };
      },
    },
  },
};

const theme = extendTheme({
  config,
  components: {
    Button,
    Heading,
    Input,
    Tr,
  },
  fonts: {
    heading: "Open Sans",
    body: "Roboto",
  },
});
export default theme;
