// Get all
import { createAction } from "@reduxjs/toolkit";
import {
  PayloadError,
  UserProfileShort,
  UserProfile,
} from "../../../common/types";

// Get single
export const getUserProfile = createAction("USER_PROFILE/GET_USER_PROFILE");

export const getUserProfileSuccess = createAction<UserProfile>(
  "USER_PROFILE/GET_USER_PROFILE_SUCCESS"
);

export const getUserProfileError = createAction<PayloadError[]>(
  "USER_PROFILE/GET_USER_PROFILE_ERROR"
);

// Get single
export const getUserProfileShort = createAction(
  "USER_PROFILE/GET_USER_PROFILE_SHORT"
);

export const getUserProfileShortSuccess = createAction<UserProfileShort>(
  "USER_PROFILE/GET_USER_PROFILE_SHORT_SUCCESS"
);

export const getUserProfileShortError = createAction<PayloadError[]>(
  "USER_PROFILE/GET_USER_PROFILE_SHORT_ERROR"
);

// Update
export const updateUserProfile = createAction<UserProfile>(
  "USER_PROFILE/UPDATE_USER_PROFILE"
);

export const updateUserProfileSuccess = createAction(
  "USER_PROFILE/UPDATE_USER_PROFILE_SUCCESS"
);

export const updateUserProfileError = createAction<PayloadError[]>(
  "USER_PROFILE/UPDATE_USER_PROFILE_ERROR"
);
