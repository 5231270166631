import { Login } from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth/auth.actions";
import { LoginPayload } from "../../common/types";
import { SubmitHandler } from "react-hook-form";
import { RootStore } from "../../store";
import React from "react";

export const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state: RootStore) => state.auth.loading);
  const errors = useSelector((state: RootStore) => state.auth.errors);

  const onSubmit: SubmitHandler<LoginPayload> = (data) => {
    dispatch(login(data));
  };

  return <Login onSubmit={onSubmit} loading={loading} errors={errors} />;
};
