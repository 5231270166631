import React from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { ContactsTable } from "./components/ContactsTable";
import { Paged } from "../../common/models";
import { Contact, ContactCreate } from "./types";
import { ContactViewModal } from "./components/ContactViewModal";
import { ContactCreateModal } from "./components/ContactCreateModal";
import { ContactEditModal } from "./components/ContactEditModal";
import { DirectoryItem } from "../../common/types";

const CFaUserAlt = chakra(FaSearch);
const CFaPlus = chakra(FaPlus);

interface IContactsProps {
  pagedContacts: Paged<Contact>;
  selectedContact: Contact;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createContact: (contact: ContactCreate) => void;
  updateContact: (contact: Contact) => void;
  setSelectedContactId: (id: number) => void;
  contactLoading: boolean;
  genders: DirectoryItem[];
  cities: DirectoryItem[];
  contactTypes: DirectoryItem[];
}

export const Contacts: React.FC<IContactsProps> = ({
  pagedContacts,
  selectedContact,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createContact,
  updateContact,
  setSelectedContactId,
  contactLoading,
  genders,
  cities,
  contactTypes,
}) => {
  const {
    isOpen: isOpenContactView,
    onOpen: onOpenContactView,
    onClose: onCloseContactView,
  } = useDisclosure();
  const {
    isOpen: isOpenContactEdit,
    onOpen: onOpenContactEdit,
    onClose: onCloseContactEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenContactCreate,
    onOpen: onOpenContactCreate,
    onClose: onCloseContactCreate,
  } = useDisclosure();

  const handleOpenView = (contact: Contact) => {
    setSelectedContactId(contact.id);
    onOpenContactView();
  };

  const handleOpenEdit = (contact: Contact) => {
    setSelectedContactId(contact.id);
    onOpenContactEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Contacts</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenContactCreate()}>
            <CFaPlus mr={2} /> Add contact
          </Button>
        </Box>
      </Flex>
      <ContactsTable
        pagedContacts={pagedContacts}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
        showView={handleOpenView}
      />

      <ContactViewModal
        selectedContact={selectedContact}
        isLoading={contactLoading}
        isOpen={isOpenContactView}
        onOpen={onOpenContactView}
        onClose={onCloseContactView}
      />

      <ContactCreateModal
        handleCreate={createContact}
        isOpen={isOpenContactCreate}
        onOpen={onOpenContactCreate}
        onClose={onCloseContactCreate}
        genders={genders}
        cities={cities}
        contactTypes={contactTypes}
      />

      <ContactEditModal
        handleUpdate={updateContact}
        selectedContact={selectedContact}
        isOpen={isOpenContactEdit}
        onOpen={onOpenContactEdit}
        onClose={onCloseContactEdit}
        genders={genders}
        cities={cities}
        contactTypes={contactTypes}
      />
    </>
  );
};
