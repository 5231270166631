import { createSlice } from "@reduxjs/toolkit";
import {
  UserProfile,
  UserProfileShort,
} from "../../../common/types/profile.types";
import { BaseState } from "../../../common/models/common";
import {
  getUserProfile,
  getUserProfileError,
  getUserProfileShort,
  getUserProfileShortError,
  getUserProfileShortSuccess,
  getUserProfileSuccess,
  updateUserProfile,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "../../actions/profile/profile.actions";

type InitialState = BaseState & {
  profile: UserProfile;
  generalProfile: UserProfileShort;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  profile: {
    id: 0,
    upworkProfilePhotoString: "",
    upworkProfileCity: "",
    upworkProfileCountry: "",
    upworkProfileName: "",
    upworkProfileUrl: "",
    upworkProfilePhotoUrl: "",
    hasUpworkProfile: false,
  },
  generalProfile: {
    avatarExtension: "",
    avatarHash: "",
    firstName: "",
    lastName: "",
  },
  loaded: false,
};

const userProfilesSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getUserProfileSuccess, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getUserProfileError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(getUserProfileShort, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getUserProfileShortSuccess, (state, action) => {
        state.generalProfile = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getUserProfileShortError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateUserProfile, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateUserProfileSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateUserProfileError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      });
  },
});

export default userProfilesSlice.reducer;
