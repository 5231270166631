import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../../common/api/api.base";
import {
  createMilestone,
  createMilestoneError,
  createMilestoneSuccess,
  getMilestones,
  getMilestonesError,
  getMilestonesSuccess,
  updateMilestone,
  updateMilestoneError,
  updateMilestoneSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../../../common/models/common";
import { Milestone } from "../types";

const apiUrl = `/api/milestones`;

function* workerGetMilestones(action: ReturnType<typeof getMilestones>) {
  try {
    const result: ApiResponse<Paged<Milestone>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getMilestonesSuccess(result.value));
    } else {
      yield put(getMilestonesError(result.errors));
    }
  } catch (e) {
    yield put(getMilestonesError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateMilestone(action: ReturnType<typeof updateMilestone>) {
  try {
    const result: ApiResponse<Milestone> = yield call(
      API.put,
      apiUrl,
      action.payload.milestone
    );
    if (result.success) {
      yield put(updateMilestoneSuccess());
      yield put(getMilestones(action.payload.request));
    } else {
      yield put(updateMilestoneError(result.errors));
    }
  } catch (e) {
    yield put(updateMilestoneError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateMilestone(action: ReturnType<typeof createMilestone>) {
  try {
    const result: ApiResponse<Milestone> = yield call(
      API.post,
      apiUrl,
      action.payload.milestone
    );
    if (result.success) {
      yield put(createMilestoneSuccess(result.value));
      // Call update contacts list
      yield put(getMilestones(action.payload.request));
    } else {
      yield put(createMilestoneError(result.errors));
    }
  } catch (e) {
    yield put(createMilestoneError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchProjectMilestonesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getMilestones.type, workerGetMilestones);
  yield takeEvery(createMilestone.type, workerCreateMilestone);
  yield takeEvery(updateMilestone.type, workerUpdateMilestone);
}
