import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getSalaryPaymentsDirectory,
  getSalaryPaymentsDirectoryError,
  getSalaryPaymentsDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  salaryPayments: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  salaryPayments: [],
  loaded: false,
};

const salaryPaymentsDirectorySlice = createSlice({
  name: "salaryPayments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalaryPaymentsDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getSalaryPaymentsDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.salaryPayments = action.payload;
      })
      .addCase(getSalaryPaymentsDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default salaryPaymentsDirectorySlice.reducer;
