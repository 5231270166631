import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { Paged, PagedRequest } from "../../../../common/models";
import { ContactUsLead } from "../types";

export const getContactUsLeads = createAction<PagedRequest>(
  "LEADS/GET_CONTACT_US_LEADS"
);

export const getContactUsLeadsSuccess = createAction<Paged<ContactUsLead>>(
  "LEADS/GET_CONTACT_US_LEADS_SUCCESS"
);

export const getContactUsLeadsError = createAction<PayloadError[]>(
  "LEADS/GET_CONTACT_US_LEADS_ERROR"
);

export const updateContactUsLead = createAction<{
  lead: ContactUsLead;
  request: PagedRequest;
}>("LEADS/UPDATE_CONTACT_US_LEAD");

export const updateContactUsLeadSuccess = createAction(
  "LEADS/UPDATE_CONTACT_US_LEAD_SUCCESS"
);

export const updateContactUsLeadError = createAction<PayloadError[]>(
  "LEADS/UPDATE_CONTACT_US_LEAD_ERROR"
);

export const removeContactUsLead = createAction<{
  contactUsLeadId: number;
  request: PagedRequest;
}>("LEADS/REMOVE_CONTACT_US_LEAD");

export const removeContactUsLeadSuccess = createAction(
  "LEADS/REMOVE_CONTACT_US_LEAD_SUCCESS"
);

export const removeContactUsLeadError = createAction<PayloadError[]>(
  "LEADS/REMOVE_CONTACT_US_LEAD_ERROR"
);

export const resetContactUsLeads = createAction("LEADS/RESET_CONTACT_US_LEADS");
