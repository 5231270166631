import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ContractCreate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { CONTRACT_SOURCE_OPTIONS, CONTRACT_TYPE_OPTIONS } from "../const";
import { DirectoryItem } from "../../../../common/types";
import { parseDateToISO } from "../../../../common/utils";

interface IContractCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (contract: ContractCreate) => void;
  partners: DirectoryItem[];
}

export const ContractCreateModal: React.FC<IContractCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  partners,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: ContractCreate) => {
    handleCreate({
      ...values,
      startDate: parseDateToISO(values.startDate),
      endDate: parseDateToISO(values.endDate),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New contract</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Flex mb={3}>
                  <FormControl id="name" pr={3} isInvalid={errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input {...register("name", { required: true })} />
                    <FormErrorMessage>
                      {errors.name ? "Name is required" : ""}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="partnerId"
                    pl={3}
                    isInvalid={errors.partnerId}
                  >
                    <FormLabel>Partner</FormLabel>
                    <HookSelect
                      control={control}
                      name={"partnerId"}
                      options={partners}
                      rules={{ required: true }}
                    />
                    <FormErrorMessage>
                      {errors.partner ? "Partner is required" : ""}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex mb={3}>
                  <FormControl pr={3}>
                    <FormLabel>Start date</FormLabel>
                    <HookDatePicker
                      control={control}
                      name={"startDate"}
                      placeholder={"Select date..."}
                    />
                  </FormControl>
                  <FormControl pl={3}>
                    <FormLabel>End date</FormLabel>
                    <HookDatePicker
                      control={control}
                      name={"endDate"}
                      placeholder={"Select date..."}
                    />
                  </FormControl>
                </Flex>
                <Flex>
                  <FormControl pr={3}>
                    <FormLabel>Source</FormLabel>
                    <HookSelect
                      control={control}
                      name={"source"}
                      options={CONTRACT_SOURCE_OPTIONS}
                    />
                  </FormControl>
                  <FormControl pl={3}>
                    <FormLabel>Type</FormLabel>
                    <HookSelect
                      control={control}
                      name={"type"}
                      options={CONTRACT_TYPE_OPTIONS}
                    />
                  </FormControl>
                </Flex>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
