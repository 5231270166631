import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getEmployeesDirectory,
  getEmployeesDirectoryError,
  getEmployeesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/employees`;

function* workerGetEmployees(action: ReturnType<typeof getEmployeesDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeesDirectorySuccess(result.value.entities));
    } else {
      yield put(getEmployeesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getEmployeesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchEmployeesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getEmployeesDirectory.type, workerGetEmployees);
}
