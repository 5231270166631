import React from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaPencilAlt } from "react-icons/fa";
import { Team } from "../types";
import { Paged } from "../../../../common/models";
import { AuthImage, SharedPagination } from "../../../../common/components";
import { extractLabelByValue } from "../../../../common/utils/const.utils";
import { TEAM_STATUS_OPTIONS } from "../teams.const";
import { TeamStatusIcon } from "./TeamStatusIcon";

interface ITeamsListProps {
  pagedTeams: Paged<Team>;
  showEdit: (team: Team) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const TeamsTable: React.FC<ITeamsListProps> = ({
  pagedTeams,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Members</Th>
            <Th>Status</Th>
            <Th>Owner</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedTeams.entities.map((team, index) => {
            return (
              <Tr key={index}>
                <Td>{team.name}</Td>
                <Td>
                  <AvatarGroup size="md" max={5}>
                    {team.members.map((member, index) => {
                      return member.avatarHash ? (
                        <AuthImage
                          key={index}
                          url={`/api/storage/images/contact-avatar/${member.avatarHash}${member.avatarExtension}`}
                          childComponent={<Avatar />}
                        />
                      ) : (
                        <Avatar key={index} />
                      );
                    })}
                  </AvatarGroup>
                </Td>
                <Td>
                  <TeamStatusIcon status={team.status} />
                </Td>
                <td>
                  {team.ownerFirstName} {team.ownerLastName}
                </td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(team)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedTeams.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
