import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import authReducer from "./auth/auth.slice";
import contactsReducer from "../../pages/Contacts/store/slice";
import contactUsLeadsReducer from "../../pages/Leads/ContactUsLeads/store/slice";
import employeesReducer from "../../pages/Work/Employees/store/slice";
import employeeReducer from "../../pages/Work/Employee/store/slice";
import usersReducer from "../../pages/Users/store/slice";
import salariesReducer from "../../common/modules/Salaries/store/slice";
import teamsReducer from "../../pages/Work/Teams/store/slice";
import payoneerReducer from "../../pages/Payoneer/store/slice";
import partnersReducer from "../../pages/Job/Partners/store/slice";
import contractsReducer from "../../pages/Job/Contracts/store/slice";
import projectsReducer from "../../pages/Job/Projects/store/slice";
import projectReducer from "../../pages/Job/Project/store/slice";
import profileReducer from "./profile/profile.slice";
import projectMilestonesReducer from "../../pages/Job/Project/modules/Milestones/store/slice";
import { directoriesReducer } from "./directories";

const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    contacts: contactsReducer,
    employees: employeesReducer,
    employee: employeeReducer,
    directories: directoriesReducer,
    users: usersReducer,
    salaries: salariesReducer,
    teams: teamsReducer,
    payoneer: payoneerReducer,
    partners: partnersReducer,
    contracts: contractsReducer,
    projects: projectsReducer,
    userProfile: profileReducer,
    project: projectReducer,
    projectMilestones: projectMilestonesReducer,
    contactUsLeads: contactUsLeadsReducer,
  });

export default rootReducer;
