import React, { useEffect, useState } from "react";
// import Loading from "../../../assets/icons/Loading";
import { LoginPayload, PayloadError } from "../../../common/types";
import { SubmitHandler, useForm, Resolver, Controller } from "react-hook-form";
import loginSchema from "../../../common/schemes/login.schema";
import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

interface ILoginFormProps {
  loading?: boolean;
  onSubmit: SubmitHandler<LoginPayload>;
  stateErrors: PayloadError[];
}

export const LoginForm: React.FC<ILoginFormProps> = ({
  loading,
  onSubmit,
  stateErrors,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LoginPayload>({});

  const bgColor = useColorModeValue("whiteAlpha.900", "#1a202c");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={4}
        p="2rem"
        backgroundColor={bgColor}
        boxShadow="md"
        rounded={"lg"}
      >
        <Heading color="teal.400" size={"lg"} alignSelf={"center"}>
          Welcome to Legio
        </Heading>
        <FormControl isInvalid={!!errors.username}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<CFaUserAlt color="gray.300" />}
            />
            <Input
              type="text"
              {...register("username", { required: true })}
              placeholder="Username"
            />
          </InputGroup>
          {errors.username && (
            <FormErrorMessage>Username is required</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              children={<CFaLock color="gray.300" />}
            />
            <Input
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
            />
            <InputRightElement width="4.5rem">
              <Button
                colorScheme="gray"
                h="1.75rem"
                size="sm"
                onClick={handleShowClick}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {errors.password && (
            <FormErrorMessage>Password is required</FormErrorMessage>
          )}
        </FormControl>
        <Button
          borderRadius={0}
          type="submit"
          variant="solid"
          isLoading={loading}
          loadingText="Signing in..."
          width="full"
          rounded={"md"}
        >
          Login
        </Button>
      </Stack>
    </form>
    // <form onSubmit={handleSubmit(onSubmit)}>
    // 	<div className="my-3">
    // 		<Controller
    // 			name="email"
    // 			control={control}
    // 			defaultValue=""
    // 			render={({ field }) => (
    // 				<Input
    // 					placeholder="Email Address"
    // 					type="email"
    // 					invalid={!!errors.email}
    // 					{...field}
    // 				/>
    // 			)}
    // 		/>
    // 		{/*<FormFeedback>{errors.email?.message}</FormFeedback>*/}
    // 	</div>
    // 	<div className="my-3">
    // 		<Controller
    // 			name="password"
    // 			control={control}
    // 			defaultValue=""
    // 			render={({ field }) => (
    // 				<Input
    // 					placeholder="Password"
    // 					type="password"
    // 					invalid={!!errors.password}
    // 					{...field}
    // 				/>
    // 			)}
    // 		/>
    // 		{/*<FormFeedback>{errors.password?.message}</FormFeedback>*/}
    // 	</div>
    // 	<button
    // 		type="submit"
    // 		className="button button__full-width bt button__increase_font login-button">
    // 		{loading ? <div>12345</div> : "Login"}
    // 	</button>
    // </form>
  );
};
