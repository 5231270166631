import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import reportReducer from "../Report/store/slice";
import transactionsReducer from "../Transactions/store/slice";
import uploadDataReducer from "../UploadData/store/slice";

export default combineReducers({
  report: reportReducer,
  transactions: transactionsReducer,
  uploadData: uploadDataReducer,
});
