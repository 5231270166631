import React from "react";
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { DirectoryItem } from "../../../types";

interface ISimpleDictionaryTableProps {
  directoryEntities: DirectoryItem[];
  removeEntity: (entityId: number) => void;
}

export const SimpleDictionaryTable: React.FC<ISimpleDictionaryTableProps> = ({
  directoryEntities,
  removeEntity,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {directoryEntities.map((entity, index) => {
            return (
              <Tr key={index}>
                <Td>{entity.value}</Td>
                <Td>{entity.label}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => removeEntity(entity.value)}
                  >
                    <FaTrash />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
