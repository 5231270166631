import React, { useEffect } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import { FaPlus, FaUser } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";

const CFaPlus = chakra(FaPlus);
const CFaUser = chakra(FaUser);

interface ISalariesHeaderProps {
  selectedEmployeeChanged?: (employeeId: number) => void;
  onOpenSalaryCreate: () => void;
  employees: DirectoryItem[];
  showFilters?: boolean;
  showHeader?: boolean;
}

export const SalariesHeaders: React.FC<ISalariesHeaderProps> = ({
  selectedEmployeeChanged,
  onOpenSalaryCreate,
  employees,
  showFilters = true,
  showHeader = true,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const employeeId = useWatch({ control, name: "employeeId" });

  useEffect(() => {
    if (employeeId && selectedEmployeeChanged) {
      selectedEmployeeChanged(employeeId);
    }
  }, [employeeId]);

  return (
    <>
      {showHeader ? (
        <Heading size={"lg"} mb={3}>
          Salaries
        </Heading>
      ) : (
        ""
      )}

      <Flex mb={3}>
        {showFilters ? (
          <Box w={"30%"}>
            <form>
              <FormControl id="employeeId" pr={3}>
                <HookSelect
                  control={control}
                  name={"employeeId"}
                  options={employees}
                  placeholder={"Select employee..."}
                />
              </FormControl>
            </form>
          </Box>
        ) : (
          ""
        )}
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenSalaryCreate()}>
            <CFaPlus mr={2} /> Add salary
          </Button>
        </Box>
      </Flex>
    </>
  );
};
