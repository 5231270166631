import React, { HTMLAttributes } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/date-picker.scss";

interface Props {
  isClearable?: boolean;
  placeholder?: string;
  name: string;
  control: any;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
}

export const HookDatePicker = ({
  control,
  name,
  isClearable = false,
  placeholder,
  minDate,
  maxDate,
  required = false,
}: Props & HTMLAttributes<HTMLElement>) => {
  return (
    <Box className={useColorMode().colorMode + "-theme"}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            onChange={(date) => field.onChange(date)}
            selected={field.value}
            dateFormat="dd.MM.yyyy"
            showMonthDropdown
            showYearDropdown
            showPopperArrow={false}
            isClearable={isClearable}
            dropdownMode="select"
            placeholderText={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            required={required}
            calendarStartDay={1}
            showDisabledMonthNavigation
            className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
          />
        )}
      />
    </Box>
  );
};
