import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Paged } from "../../../../../common/models";
import { Milestone, MilestoneCreate, MilestoneUpdate } from "./types";
import {
  MilestoneCreateModal,
  MilestoneEditModal,
  MilestonesTable,
} from "./components";

const CFaPlus = chakra(FaPlus);

interface IMilestonesProps {
  pagedMilestones: Paged<Milestone>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createMilestone: (milestone: MilestoneCreate) => void;
  updateMilestone: (milestone: MilestoneUpdate) => void;
  milestoneLoading: boolean;
}

export const Milestones: React.FC<IMilestonesProps> = ({
  pagedMilestones,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createMilestone,
  updateMilestone,
  milestoneLoading,
}) => {
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone | null>(
    null
  );
  const {
    isOpen: isOpenMilestoneEdit,
    onOpen: onOpenMilestoneEdit,
    onClose: onCloseMilestoneEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenMilestoneCreate,
    onOpen: onOpenMilestoneCreate,
    onClose: onCloseMilestoneCreate,
  } = useDisclosure();

  const handleOpenEdit = (milestone: Milestone) => {
    setSelectedMilestone(milestone);
    onOpenMilestoneEdit();
  };

  return (
    <>
      <Flex mb={3}>
        {/*<Heading size={"lg"}>Milestones</Heading>*/}
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenMilestoneCreate()}>
            <CFaPlus mr={2} /> Add milestone
          </Button>
        </Box>
      </Flex>

      <MilestonesTable
        pagedMilestones={pagedMilestones}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <MilestoneCreateModal
        handleCreate={createMilestone}
        isOpen={isOpenMilestoneCreate}
        onOpen={onOpenMilestoneCreate}
        onClose={onCloseMilestoneCreate}
      />

      <MilestoneEditModal
        handleUpdate={updateMilestone}
        selectedMilestone={selectedMilestone}
        isOpen={isOpenMilestoneEdit}
        onOpen={onOpenMilestoneEdit}
        onClose={onCloseMilestoneEdit}
      />
    </>
  );
};
