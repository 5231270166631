import React, { HTMLAttributes, useEffect, useState } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { Control, Controller, FieldValues } from "react-hook-form";
import Select, { StylesConfig } from "react-select";
import { DirectoryItem } from "../../types";

interface Props {
  name: string;
  options: { value: string | number; label: string }[];
  control: any;
  rules?: {};
  placeholder?: string;
  initialValue?: number;
  isClearable?: boolean;
}

const customStyles: StylesConfig<DirectoryItem[], false> = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "teal"
      : state.isFocused
      ? "lightblue"
      : "",
  }),
  control: (styles) => ({ ...styles }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export const HookSelect = ({
  control,
  options = [],
  name,
  rules = {},
  placeholder = "Select...",
  initialValue,
  isClearable = false,
}: Props & HTMLAttributes<HTMLElement>) => {
  return (
    <Box className={useColorMode().colorMode + "-theme"}>
      <Controller
        control={control}
        defaultValue={initialValue}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            inputRef={ref}
            // @ts-ignore
            styles={customStyles}
            value={options.find((c) => c.value === value)}
            placeholder={placeholder}
            onChange={(val) => onChange(val ? val.value : undefined)}
            options={options}
            isClearable={false}
          />
        )}
      />
    </Box>
  );
};
