import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getDepartmentsDirectory,
  getDepartmentsDirectoryError,
  getDepartmentsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/departments`;

function* workerGetDepartments(
  action: ReturnType<typeof getDepartmentsDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getDepartmentsDirectorySuccess(result.value.entities));
    } else {
      yield put(getDepartmentsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getDepartmentsDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchDepartmentsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getDepartmentsDirectory.type, workerGetDepartments);
}
