import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import {
  bindPayoneerTransaction,
  bindPayoneerTransactionError,
  bindPayoneerTransactionSuccess,
  createPayoneerTransaction,
  createPayoneerTransactionError,
  createPayoneerTransactionSuccess,
  getPayoneerTransactions,
  getPayoneerTransactionsError,
  getPayoneerTransactionsSuccess,
  updatePayoneerTransaction,
  updatePayoneerTransactionError,
  updatePayoneerTransactionSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { PayoneerTransaction } from "../types";

const apiUrl = `/api/payoneer/transaction`;

function* workerGetPayoneerTransactions(
  action: ReturnType<typeof getPayoneerTransactions>
) {
  try {
    const result: ApiResponse<Paged<PayoneerTransaction>> = yield call(
      API.get,
      apiUrl,
      {
        offset: action.payload.offset,
        take: action.payload.take,
        ...action.payload.filters,
      }
    );
    if (result.success) {
      yield put(getPayoneerTransactionsSuccess(result.value));
    } else {
      yield put(getPayoneerTransactionsError(result.errors));
    }
  } catch (e) {
    yield put(
      getPayoneerTransactionsError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerUpdatePayoneerTransaction(
  action: ReturnType<typeof updatePayoneerTransaction>
) {
  try {
    const result: ApiResponse<PayoneerTransaction> = yield call(
      API.put,
      `${apiUrl}/purpose`,
      action.payload.payoneerTransaction
    );
    if (result.success) {
      yield put(updatePayoneerTransactionSuccess());
      yield put(getPayoneerTransactions(action.payload.request));
    } else {
      yield put(updatePayoneerTransactionError(result.errors));
    }
  } catch (e) {
    yield put(
      updatePayoneerTransactionError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerBindPayoneerTransaction(
  action: ReturnType<typeof bindPayoneerTransaction>
) {
  try {
    const result: ApiResponse = yield call(
      API.put,
      `${apiUrl}/bindings`,
      action.payload.transactionBinding
    );
    if (result.success) {
      yield put(bindPayoneerTransactionSuccess());
      yield put(getPayoneerTransactions(action.payload.request));
    } else {
      yield put(bindPayoneerTransactionError(result.errors));
    }
  } catch (e) {
    yield put(
      bindPayoneerTransactionError([{ key: "", errorMessage: e.message }])
    );
  }
}

function* workerCreatePayoneerTransaction(
  action: ReturnType<typeof createPayoneerTransaction>
) {
  try {
    const result: ApiResponse<PayoneerTransaction> = yield call(
      API.post,
      apiUrl,
      action.payload.payoneerTransaction
    );
    if (result.success) {
      yield put(createPayoneerTransactionSuccess(result.value));
      // Call update contacts list
      yield put(getPayoneerTransactions(action.payload.request));
    } else {
      yield put(createPayoneerTransactionError(result.errors));
    }
  } catch (e) {
    yield put(
      createPayoneerTransactionError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchPayoneerTransactionsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getPayoneerTransactions.type, workerGetPayoneerTransactions);
  yield takeEvery(
    createPayoneerTransaction.type,
    workerCreatePayoneerTransaction
  );
  yield takeEvery(
    updatePayoneerTransaction.type,
    workerUpdatePayoneerTransaction
  );
}
