import React from "react";
import { Badge } from "@chakra-ui/react";
import { TEAM_STATUS } from "../teams.const";

interface ITeamStatusIconProps {
  status: number | null;
}

export const TeamStatusIcon: React.FC<ITeamStatusIconProps> = ({ status }) => {
  switch (status) {
    case TEAM_STATUS.Active:
      return <Badge colorScheme="green">Active</Badge>;
    case TEAM_STATUS.Paused:
      return <Badge colorScheme="yellow">Paused</Badge>;
    default:
      return <></>;
  }
};
