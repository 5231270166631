import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getJobsDirectory,
  getJobsDirectoryError,
  getJobsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/jobs`;

function* workerGetJobs(action: ReturnType<typeof getJobsDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getJobsDirectorySuccess(result.value.entities));
    } else {
      yield put(getJobsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(getJobsDirectoryError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchJobsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getJobsDirectory.type, workerGetJobs);
}
