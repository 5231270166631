import { call, ForkEffect, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { ApiResponse, Paged } from "../../../../common/models/common";
import { TEmployeeProfile } from "../types";
import {
  getEmployeeProfile,
  getEmployeeProfileError,
  getEmployeeProfileSuccess,
} from "./actions";
import watchEmployeePaymentsSaga from "../modules/EmployeePayments/store/saga";
import watchEmployeeUpworkSnapshotsSaga from "../modules/EmployeeSnapshots/store/saga";
import watchEmployeeVacationsSaga from "../modules/EmployeeVacations/store/saga";

const apiUrl = `/api/employees/profile/general-info`;

function* workerGetEmployeeProfile(
  action: ReturnType<typeof getEmployeeProfile>
) {
  try {
    const result: ApiResponse<TEmployeeProfile> = yield call(
      API.get,
      `${apiUrl}`,
      action.payload
    );
    if (result.success) {
      yield put(getEmployeeProfileSuccess(result.value));
    } else {
      yield put(getEmployeeProfileError(result.errors));
    }
  } catch (e) {
    yield put(getEmployeeProfileError([{ key: "", errorMessage: e.message }]));
  }
}

function* watchEmployeeProfileSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getEmployeeProfile.type, workerGetEmployeeProfile);
}

export default function* watchEmployeeSaga() {
  yield all([
    watchEmployeeProfileSaga(),
    watchEmployeePaymentsSaga(),
    watchEmployeeUpworkSnapshotsSaga(),
    watchEmployeeVacationsSaga(),
  ]);
}
