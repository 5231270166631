import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getCitiesDirectory = createAction("DIRECTORIES/GET_CITIES");

export const getCitiesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_CITIES_SUCCESS"
);

export const getCitiesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_CITIES_ERROR"
);
