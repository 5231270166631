import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getContactsDirectory = createAction("DIRECTORIES/GET_CONTACTS");

export const getContactsDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_CONTACTS_SUCCESS"
);

export const getContactsDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_CONTACTS_ERROR"
);
