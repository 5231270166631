import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { PayoneerReport } from "./types";
import { ReportUser } from "./components/ReportUser";
import { ReportTotal } from "./components/ReportTotal";

interface IReportsProps {
  report: PayoneerReport;
  reportLoading: boolean;
}

export const Report: React.FC<IReportsProps> = ({ report, reportLoading }) => {
  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Payoneer report</Heading>
      </Flex>

      <Flex mb={3}>
        <Box mr={4} w={"50%"}>
          <ReportUser
            userName={"Aragorn"}
            personalIncome={report.stats.aragornPersonalIncome}
            amount={report.stats.aragornAmount}
            income={report.stats.aragornIncome}
            personalExpenses={report.stats.aragornPersonalExpenses}
          />
        </Box>

        <Box w={"50%"}>
          <ReportUser
            userName={"Gandalf"}
            personalIncome={report.stats.gandalfPersonalIncome}
            amount={report.stats.gandalfAmount}
            income={report.stats.gandalfIncome}
            personalExpenses={report.stats.gandalfPersonalExpenses}
          />
        </Box>
      </Flex>

      <Box>
        <ReportTotal
          gandalfOwesAragorn={report.gandalfOwesAragorn}
          aragornOwesGandalf={report.aragornOwesGandalf}
          gandalfTotal={report.gandalfTotal}
          aragornTotal={report.aragornTotal}
        />
      </Box>
    </>
  );
};
