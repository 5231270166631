import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  chakra,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  FaArrowAltCircleDown,
  FaArrowCircleUp,
  FaCheck,
  FaCompressArrowsAlt,
  FaPortrait,
  FaTimes,
} from "react-icons/fa";
import { PayoneerTransaction, PayoneerTransactionUpdate } from "../types";
import { Paged } from "../../../../common/models";
import { SharedPagination } from "../../../../common/components";
import { EXTENDED_DATE_FORMAT } from "../../../../common/constants/dates.const";
import { formatCurrency, formatDate } from "../../../../common/utils";
import { extractLabelByValue } from "../../../../common/utils/const.utils";
import Select from "react-select";
import {
  PAYONEER_TRANSACTION_PURPOSES,
  PAYONEER_TRANSACTION_PURPOSES_OPTIONS,
  PAYONEER_TRANSACTION_STATUSES,
  PAYONEER_TRANSACTION_TYPES,
} from "../const/payoneer-transactions.const";
import { DirectoryItem } from "../../../../common/types";

const CFaArrowCircleUp = chakra(FaArrowCircleUp);
const CFaArrowAltCircleDown = chakra(FaArrowAltCircleDown);
const CFaCheck = chakra(FaCheck);
const CFaTimes = chakra(FaTimes);
const CFaPortrait = chakra(FaPortrait);

interface ITransactionsListProps {
  pagedTransactions: Paged<PayoneerTransaction>;
  showBinding: (transaction: PayoneerTransaction) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  updateTransaction: (transaction: PayoneerTransactionUpdate) => void;
}

export const TransactionsTable: React.FC<ITransactionsListProps> = ({
  pagedTransactions,
  showBinding,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  updateTransaction,
}) => {
  const getStatusBadge = (statusId: number) => {
    return statusId === PAYONEER_TRANSACTION_STATUSES.Completed ? (
      <Badge colorScheme="green">
        <Flex alignItems={"center"}>
          <CFaCheck mr={1} />
          <Text>Completed</Text>
        </Flex>
      </Badge>
    ) : (
      <Badge colorScheme="red">
        <Flex alignItems={"center"}>
          <CFaTimes mr={1} />
          <Text>Cancelled</Text>
        </Flex>
      </Badge>
    );
  };

  const getTypeBadge = (type: number) => {
    return type === PAYONEER_TRANSACTION_TYPES.Refill ? (
      <Badge colorScheme="teal">
        <Flex alignItems={"center"}>
          <CFaArrowCircleUp mr={1} />
          <Text>Refill</Text>
        </Flex>
      </Badge>
    ) : (
      <Badge colorScheme="orange">
        <Flex alignItems={"center"}>
          <CFaArrowAltCircleDown mr={1} />
          <Text>Withdrawal</Text>
        </Flex>
      </Badge>
    );
  };

  const [purposeOptions] = useState(PAYONEER_TRANSACTION_PURPOSES_OPTIONS);

  const getPurposeOption = (purposeId: number) => {
    return PAYONEER_TRANSACTION_PURPOSES_OPTIONS.find(
      (option) => option.value === purposeId
    );
  };

  const setTransactionPurpose = (
    selectedOption: DirectoryItem | null,
    transactionId: number
  ) => {
    if (selectedOption) {
      updateTransaction({ id: transactionId, purpose: selectedOption.value });
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflowX={"auto"}>
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>Transaction date</Th>
            <Th>Transaction id</Th>
            <Th>Description</Th>
            <Th>Purpose</Th>
            <Th>Amount</Th>
            <Th>Balance Amount</Th>
            <Th>Type</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pagedTransactions.entities.map((payoneerTransaction, index) => {
            return (
              <Tr key={index}>
                <Td>
                  {formatDate(
                    payoneerTransaction.transactionDate,
                    EXTENDED_DATE_FORMAT
                  )}
                </Td>
                <Td>{payoneerTransaction.transactionId}</Td>
                <Td>{payoneerTransaction.description}</Td>
                <Td>
                  {payoneerTransaction.purpose ===
                  PAYONEER_TRANSACTION_PURPOSES.Pending ? (
                    <Box minWidth={"200px"}>
                      <Select
                        value={getPurposeOption(payoneerTransaction.purpose)}
                        menuPortalTarget={document.body}
                        onChange={(event) =>
                          setTransactionPurpose(event, payoneerTransaction.id)
                        }
                        options={purposeOptions}
                      />
                    </Box>
                  ) : (
                    extractLabelByValue(
                      PAYONEER_TRANSACTION_PURPOSES_OPTIONS,
                      payoneerTransaction.purpose
                    )
                  )}
                </Td>
                <Td>{formatCurrency(payoneerTransaction.amount)}</Td>
                <Td>{formatCurrency(payoneerTransaction.balanceAmount)}</Td>
                <Td>{getTypeBadge(payoneerTransaction.type)}</Td>
                <Td>{getStatusBadge(payoneerTransaction.status)}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showBinding(payoneerTransaction)}
                  >
                    <CFaPortrait />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedTransactions.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
