import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../common/types";
import { TEmployeeProfile } from "../types";

export const getEmployeeProfile = createAction<{ employeeId: number }>(
  "WORK_EMPLOYEES/GET_EMPLOYEE_PROFILE"
);

export const getEmployeeProfileSuccess = createAction<TEmployeeProfile>(
  "WORK_EMPLOYEES/GET_EMPLOYEE_PROFILE_SUCCESS"
);

export const getEmployeeProfileError = createAction<PayloadError[]>(
  "WORK_EMPLOYEES/GET_EMPLOYEE_PROFILE_ERROR"
);

// Reset
export const resetSelectedEmployee = createAction(
  "WORK_EMPLOYEES/RESET_SELECTED_EMPLOYEE"
);
