import { createSlice } from "@reduxjs/toolkit";
import { DirectoryItem } from "../../../common/types";
import { BaseState } from "../../../common/models/common";
import {
  getDepartmentsDirectory,
  getDepartmentsDirectoryError,
  getDepartmentsDirectorySuccess,
} from "../../actions/directories";

type InitialState = BaseState & {
  departments: DirectoryItem[];
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  departments: [],
  loaded: false,
};

const departmentsDirectorySlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentsDirectory, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getDepartmentsDirectorySuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.departments = action.payload;
      })
      .addCase(getDepartmentsDirectoryError, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.errors = action.payload;
      });
  },
});

export default departmentsDirectorySlice.reducer;
