import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Salary, SalaryUpdate } from "../types";
import { HookDatePicker } from "../../../../common/components/libs";
import { useForm } from "react-hook-form";
import { parseDateToISO, parseStringToDate } from "../../../../common/utils";

interface ISalaryEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedSalary: Salary | null;
  handleUpdate: (salary: SalaryUpdate) => void;
}

export const SalaryEditModal: React.FC<ISalaryEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedSalary,
  handleUpdate,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitUpdate = (values: SalaryUpdate) => {
    handleUpdate({
      id: selectedSalary ? selectedSalary.id : null,
      dateTo: parseDateToISO(values.dateTo),
      dateFrom: parseDateToISO(values.dateFrom),
      amount: values.amount,
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedSalary,
      dateFrom: parseStringToDate(
        selectedSalary ? selectedSalary.dateFrom : ""
      ),
      dateTo: parseStringToDate(selectedSalary ? selectedSalary.dateTo : ""),
    });
  }, [selectedSalary]);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit salary</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="amount" isInvalid={errors.amount}>
                      <FormLabel>Salary</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                          children="$"
                        />
                        <Input
                          type={"number"}
                          step={"0.01"}
                          placeholder={"Enter amount..."}
                          {...register("amount", { required: true })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.amount ? "Salary is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="dateFrom" pr={3}>
                      <FormLabel>From</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"dateFrom"}
                        placeholder={"Date from..."}
                      />
                    </FormControl>
                    <FormControl id="dateTo" pl={3}>
                      <FormLabel>To</FormLabel>
                      <HookDatePicker
                        control={control}
                        name={"dateTo"}
                        placeholder={"Date to..."}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
