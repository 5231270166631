// Get all
import { createAction } from "@reduxjs/toolkit";
import { PagedRequest, Paged } from "../../../../common/models";
import { PayloadError } from "../../../../common/types";
import { SalariesRequest, Salary, SalaryCreate, SalaryUpdate } from "../types";

export const getSalaries = createAction<SalariesRequest>(
  "WORK_SALARIES/GET_SALARIES"
);

export const getSalariesSuccess = createAction<Paged<any>>(
  "WORK_SALARIES/GET_SALARIES_SUCCESS"
);

export const getSalariesError = createAction<PayloadError[]>(
  "WORK_SALARIES/GET_SALARIES_ERROR"
);

// Create
export const createSalary = createAction<{
  salary: SalaryCreate;
  request: SalariesRequest;
}>("WORK_SALARIES/CREATE_SALARY");

export const createSalarySuccess = createAction<Salary>(
  "WORK_SALARIES/CREATE_SALARY_SUCCESS"
);

export const createSalaryError = createAction<PayloadError[]>(
  "WORK_SALARIES/CREATE_SALARY_ERROR"
);

// Update
export const updateSalary = createAction<{
  salary: SalaryUpdate;
  request: SalariesRequest;
}>("WORK_SALARIES/UPDATE_SALARY");

export const updateSalarySuccess = createAction(
  "WORK_SALARIES/UPDATE_SALARY_SUCCESS"
);

export const updateSalaryError = createAction<PayloadError[]>(
  "WORK_SALARIES/UPDATE_SALARY_ERROR"
);

// Reset
export const resetSelectedSalary = createAction(
  "WORK_SALARIES/RESET_SELECTED_SALARY"
);

export const resetSalaries = createAction("WORK_SALARIES/RESET");
