import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../../common/api/api.base";
import { getProject, getProjectError, getProjectSuccess } from "./actions";
import { ApiResponse } from "../../../../common/models/common";
import { ProjectFull } from "../types";

const apiUrl = `/api/projects/`;

function* workerGetProject(action: ReturnType<typeof getProject>) {
  try {
    const result: ApiResponse<{ project: ProjectFull }> = yield call(
      API.get,
      `${apiUrl}${action.payload}`
    );
    if (result.success) {
      yield put(getProjectSuccess(result.value.project));
    } else {
      yield put(getProjectError(result.errors));
    }
  } catch (e) {
    yield put(getProjectError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchProjectSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getProject.type, workerGetProject);
}
