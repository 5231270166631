import { createSlice } from "@reduxjs/toolkit";
import { PayoneerTransaction } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  bindPayoneerTransaction,
  bindPayoneerTransactionError,
  bindPayoneerTransactionSuccess,
  createPayoneerTransaction,
  createPayoneerTransactionError,
  createPayoneerTransactionSuccess,
  getPayoneerTransactions,
  getPayoneerTransactionsError,
  getPayoneerTransactionsSuccess,
  resetPayoneerTransactions,
  updatePayoneerTransaction,
  updatePayoneerTransactionError,
  updatePayoneerTransactionSuccess,
} from "./actions";

type InitialState = BaseState & {
  payoneerTransactions: Paged<PayoneerTransaction>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  payoneerTransactions: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const payoneerTransactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayoneerTransactions, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getPayoneerTransactionsSuccess, (state, action) => {
        state.payoneerTransactions = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getPayoneerTransactionsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(bindPayoneerTransaction, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(bindPayoneerTransactionSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(bindPayoneerTransactionError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updatePayoneerTransaction, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updatePayoneerTransactionSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updatePayoneerTransactionError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createPayoneerTransaction, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createPayoneerTransactionSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createPayoneerTransactionError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetPayoneerTransactions, (state) => {
        state.payoneerTransactions.entities = [];
      });
  },
});

export default payoneerTransactionsSlice.reducer;
