import React, { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  chakra,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { ContactUsLeadsTable } from "./components/ContactUsLeadsTable";
import { Paged } from "../../../common/models";
import { ContactUsLead } from "./types";
import { ContactUsLeadsViewModal } from "./components/ContactUsLeadsViewModal";

interface IContactUsLeadsProps {
  pagedContactUsLeads: Paged<ContactUsLead>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  updateContactUsLead: (lead: ContactUsLead) => void;
  removeContactUsLead: (contactUsLeadId: number) => void;
}

export const ContactUsLeads: React.FC<IContactUsLeadsProps> = ({
  pagedContactUsLeads,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  updateContactUsLead,
  removeContactUsLead,
}) => {
  const {
    isOpen: isOpenContactUsLeadView,
    onOpen: onOpenContactUsLeadView,
    onClose: onCloseContactUsLeadView,
  } = useDisclosure();

  const {
    isOpen: isOpenContactUsLeadRemove,
    onOpen: onOpenContactUsLeadRemove,
    onClose: onCloseContactUsLeadRemove,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  const [selectedContactUsLead, setSelectedContactUsLead] =
    useState<ContactUsLead>({
      id: 0,
      createdAt: new Date(),
      email: "",
      name: "",
      text: "",
      title: "",
      isRead: false,
    });

  const handleOpenView = (lead: ContactUsLead) => {
    setSelectedContactUsLead(lead);
    onOpenContactUsLeadView();
  };

  const handleOpenRemoveView = (lead: ContactUsLead) => {
    setSelectedContactUsLead(lead);
    onOpenContactUsLeadRemove();
  };

  const markAsRead = (lead: ContactUsLead) => {
    updateContactUsLead({ ...lead, isRead: !lead.isRead });
  };

  const confirmRemoveContactUsLead = () => {
    onCloseContactUsLeadRemove();
    removeContactUsLead(selectedContactUsLead.id);
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Contact Us</Heading>
      </Flex>
      <ContactUsLeadsTable
        pagedContactUsLeads={pagedContactUsLeads}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showView={handleOpenView}
        showRemove={handleOpenRemoveView}
        markAsRead={markAsRead}
      />

      <ContactUsLeadsViewModal
        selectedContactUsLead={selectedContactUsLead}
        isOpen={isOpenContactUsLeadView}
        onOpen={onOpenContactUsLeadView}
        onClose={onCloseContactUsLeadView}
      />

      <AlertDialog
        isOpen={isOpenContactUsLeadRemove}
        leastDestructiveRef={cancelRef}
        onClose={onCloseContactUsLeadRemove}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Contact Us lead
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can t undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseContactUsLeadRemove}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmRemoveContactUsLead}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
