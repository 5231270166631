import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { Partner } from "../types";
import { Paged } from "../../../../common/models";
import { AuthImage, SharedPagination } from "../../../../common/components";
import { extractLabelByValue } from "../../../../common/utils/const.utils";
import { DirectoryItem } from "../../../../common/types";

interface IPartnersListProps {
  pagedPartners: Paged<Partner>;
  showEdit: (partner: Partner) => void;
  showView: (partner: Partner) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  countries: DirectoryItem[];
  partnerTypes: DirectoryItem[];
  partnerIndustries: DirectoryItem[];
}

export const PartnersTable: React.FC<IPartnersListProps> = ({
  pagedPartners,
  showEdit,
  showView,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  countries,
  partnerTypes,
  partnerIndustries,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>Logo</Th>
            <Th>Name</Th>
            <Th>Responsible</Th>
            <Th>Email</Th>
            <Th>Country</Th>
            <Th>Type</Th>
            <Th>Industry</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedPartners.entities.map((partner, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Center>
                    {partner.logoStorageHash ? (
                      <AuthImage
                        url={
                          `/api/storage/images/partner-logo/` +
                          partner.logoStorageHash
                        }
                        childComponent={
                          <Avatar
                            size={"lg"}
                            style={{ cursor: "pointer" }}
                            onClick={() => showView(partner)}
                          />
                        }
                      />
                    ) : (
                      <Avatar
                        size={"lg"}
                        style={{ cursor: "pointer" }}
                        onClick={() => showView(partner)}
                      />
                    )}
                  </Center>
                </Td>
                <Td>{partner.name}</Td>
                <Td>{partner.responsibleContactName}</Td>
                <Td>{partner.email}</Td>
                <Td>{extractLabelByValue(countries, partner.countryId)}</Td>
                <Td>
                  {extractLabelByValue(partnerTypes, partner.partnerTypeId)}
                </Td>
                <Td>
                  {extractLabelByValue(
                    partnerIndustries,
                    partner.partnerIndustryId
                  )}
                </Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(partner)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showView(partner)}
                  >
                    <FaEye />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedPartners.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
