import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Employee, EmployeeCreate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { DirectoryItem } from "../../../../common/types";
import {
  formatDate,
  parseDateToISO,
  parseStringToDate,
} from "../../../../common/utils/date-parser";
import { EMPLOYEE_STATUSES_OPTIONS } from "../../const/employee.const";

interface IEmployeeCreateModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleCreate: (employee: EmployeeCreate) => void;
  jobs: DirectoryItem[];
  departments: DirectoryItem[];
  contacts: DirectoryItem[];
}

export const EmployeeCreateModal: React.FC<IEmployeeCreateModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleCreate,
  jobs,
  departments,
  contacts,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const handleSubmitCreate = (values: EmployeeCreate) => {
    handleCreate({
      ...values,
      hireDate: parseDateToISO(getValues("hireDate")),
      quitDate: parseDateToISO(getValues("quitDate")),
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New employee</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <ModalBody>
              <Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl
                      id="contactId"
                      pr={3}
                      isInvalid={errors.contactId}
                    >
                      <FormLabel>Contact</FormLabel>
                      <HookSelect
                        control={control}
                        name={"contactId"}
                        options={contacts}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.test ? "Contact is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="status" pr={3} isInvalid={errors.status}>
                      <FormLabel>Status</FormLabel>
                      <HookSelect
                        control={control}
                        name={"status"}
                        options={EMPLOYEE_STATUSES_OPTIONS}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.test ? "Status is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl id="jobId" pr={3}>
                      <FormLabel>Job</FormLabel>
                      <HookSelect
                        control={control}
                        name={"jobId"}
                        options={jobs}
                      />
                    </FormControl>
                    <FormControl id="department" pr={3}>
                      <FormLabel>Department</FormLabel>
                      <HookSelect
                        control={control}
                        name={"departmentId"}
                        options={departments}
                      />
                    </FormControl>
                  </Flex>
                </Box>
                <Box mb={3}>
                  <Flex>
                    <FormControl pr={3}>
                      <FormLabel>Hire date</FormLabel>
                      <HookDatePicker control={control} name={"hireDate"} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Quit date</FormLabel>
                      <HookDatePicker control={control} name={"quitDate"} />
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Create
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
