// Get all
import { Paged } from "../../../../../../common/models";
import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../../../../common/types";
import {
  EmployeePayment,
  EmployeePaymentCreate,
  EmployeePaymentsRequest,
  EmployeePaymentUpdate,
} from "../types";

export const getEmployeePayments = createAction<EmployeePaymentsRequest>(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENTS"
);

export const getEmployeePaymentsSuccess = createAction<Paged<EmployeePayment>>(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENTS_SUCCESS"
);

export const getEmployeePaymentsError = createAction<PayloadError[]>(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENTS_ERROR"
);

// Get single
export const getEmployeePayment = createAction(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENT"
);

export const getEmployeePaymentSuccess = createAction<any>(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENT_SUCCESS"
);

export const getEmployeePaymentError = createAction<PayloadError[]>(
  "EMPLOYEE_PAYMENTS/GET_EMPLOYEE_PAYMENT_ERROR"
);

// Create
export const createEmployeePayment = createAction<{
  employeePayment: EmployeePaymentCreate;
  request: EmployeePaymentsRequest;
}>("EMPLOYEE_PAYMENTS/CREATE_EMPLOYEE_PAYMENT");

export const createEmployeePaymentSuccess = createAction<EmployeePayment>(
  "EMPLOYEE_PAYMENTS/CREATE_EMPLOYEE_PAYMENT_SUCCESS"
);

export const createEmployeePaymentError = createAction<PayloadError[]>(
  "EMPLOYEE_PAYMENTS/CREATE_EMPLOYEE_PAYMENT_ERROR"
);

// Update
export const updateEmployeePayment = createAction<{
  employeePayment: EmployeePaymentUpdate;
  request: EmployeePaymentsRequest;
}>("EMPLOYEE_PAYMENTS/UPDATE_EMPLOYEE_PAYMENT");

export const updateEmployeePaymentSuccess = createAction(
  "EMPLOYEE_PAYMENTS/UPDATE_EMPLOYEE_PAYMENT_SUCCESS"
);

export const updateEmployeePaymentError = createAction<PayloadError[]>(
  "EMPLOYEE_PAYMENTS/UPDATE_EMPLOYEE_PAYMENT_ERROR"
);

// Reset
export const resetSelectedEmployeePayment = createAction(
  "EMPLOYEE_PAYMENTS/RESET_SELECTED_EMPLOYEE_PAYMENT"
);

export const resetEmployeePayments = createAction("EMPLOYEE_PAYMENTS/RESET");
