import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getRolesDirectory,
  getRolesDirectoryError,
  getRolesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/roles`;

function* workerGetRoles(action: ReturnType<typeof getRolesDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getRolesDirectorySuccess(result.value.entities));
    } else {
      yield put(getRolesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(getRolesDirectoryError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchRolesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getRolesDirectory.type, workerGetRoles);
}
