import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
} from "@chakra-ui/react";
import Cropper from "react-easy-crop";
import { Point } from "react-easy-crop/types";
import { FaHistory, FaSearch } from "react-icons/fa";
import getCroppedImg from "./cropped-image.helper";

interface IImageCropModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  sourceImage: string;
  handleImageCropped: (crop: any) => void;
}

export const ImageCropModal: React.FC<IImageCropModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  handleImageCropped,
  sourceImage,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // useEffect(() => {
  //   getCroppedImage();
  // }, [croppedAreaPixels]);

  const handleSubmitCrop = () => {
    getCroppedImage().then(() => {});
  };

  const handleClose = () => {
    onClose();
  };

  const getCroppedImage = useCallback(async () => {
    try {
      if (sourceImage) {
        const croppedImage = await getCroppedImg(
          sourceImage,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
        handleImageCropped(croppedImage);
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Select image area</ModalHeader>
        <ModalBody p={0}>
          <Flex h={"500px"}>
            <Box w={"100%"} position={"relative"}>
              <Cropper
                image={sourceImage}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                showGrid={false}
                cropShape="round"
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </Box>
          </Flex>
          <Flex pt={3} pr={8} pl={8}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(value) => setZoom(value)}
              aria-labelledby="Zoom"
              colorScheme={"teal"}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={8}>
                <Box color="teal.500" as={FaSearch} />
              </SliderThumb>
            </Slider>
            <Spacer mr={8} ml={8} />
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              onChange={(value) => setRotation(value)}
              aria-labelledby="Zoom"
              colorScheme={"teal"}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={8}>
                <Box color="teal.500" as={FaHistory} />
              </SliderThumb>
            </Slider>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleSubmitCrop}>
            Save
          </Button>
          <Button colorScheme={"gray"} onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
