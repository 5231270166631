import { Report } from "./Report";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { getPayoneerReport } from "./store/actions";

export const ReportContainer: React.FC = () => {
  useEffect(() => {
    onGetReport();
  }, []);

  // Queries
  const report = useSelector(
    (state: RootStore) => state.payoneer.report.report
  );
  const reportLoading = useSelector(
    (state: RootStore) => state.payoneer.report.loading
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetReport = () => dispatch(getPayoneerReport());

  return <Report report={report} reportLoading={reportLoading} />;
};
