import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  createUser,
  createUserError,
  createUserSuccess,
  getUsers,
  getUsersError,
  getUsersSuccess,
  updateUser,
  updateUserError,
  updateUserSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../common/models/common";
import { User } from "../types";

const apiUrl = `/api/users/`;

function* workerGetUsers(action: ReturnType<typeof getUsers>) {
  try {
    const result: ApiResponse<Paged<User>> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getUsersSuccess(result.value));
    } else {
      yield put(getUsersError(result.errors));
    }
  } catch (e) {
    yield put(getUsersError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateUser(action: ReturnType<typeof updateUser>) {
  try {
    const result: ApiResponse<User> = yield call(
      API.put,
      apiUrl,
      action.payload.user
    );
    if (result.success) {
      yield put(updateUserSuccess());
      yield put(getUsers(action.payload.request));
    } else {
      yield put(updateUserError(result.errors));
    }
  } catch (e) {
    yield put(updateUserError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateUser(action: ReturnType<typeof createUser>) {
  try {
    const result: ApiResponse<User> = yield call(
      API.post,
      apiUrl,
      action.payload.user
    );
    if (result.success) {
      yield put(createUserSuccess(result.value));
      // Call update contacts list
      yield put(getUsers(action.payload.request));
    } else {
      yield put(createUserError(result.errors));
    }
  } catch (e) {
    yield put(createUserError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchUsersSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getUsers.type, workerGetUsers);
  yield takeEvery(createUser.type, workerCreateUser);
  yield takeEvery(updateUser.type, workerUpdateUser);
}
