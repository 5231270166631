import { all } from "redux-saga/effects";
import watchPayoneerReportSaga from "../Report/store/saga";
import watchPayoneerTransactionsSaga from "../Transactions/store/saga";
import watchUploadPayoneerDataSaga from "../UploadData/store/saga";

export default function* payoneerSaga() {
  yield all([
    watchPayoneerReportSaga(),
    watchPayoneerTransactionsSaga(),
    watchUploadPayoneerDataSaga(),
  ]);
}
