import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getContactTypesDirectory,
  getContactTypesDirectoryError,
  getContactTypesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/contact-types`;

function* workerGetContactTypes(
  action: ReturnType<typeof getContactTypesDirectory>
) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getContactTypesDirectorySuccess(result.value.entities));
    } else {
      yield put(getContactTypesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getContactTypesDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchContactTypesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContactTypesDirectory.type, workerGetContactTypes);
}
