import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { Salary } from "../types";
import { Paged } from "../../../../common/models";
import { SharedPagination } from "../../../../common/components";
import { formatCurrency, formatDate } from "../../../../common/utils";

interface ISalariesListProps {
  pagedSalaries: Paged<Salary>;
  showEdit: (salary: Salary) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const SalariesTable: React.FC<ISalariesListProps> = ({
  pagedSalaries,
  showEdit,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Date From</Th>
            <Th>Date To</Th>
            <Th>Employee</Th>
            <Th>Salary/Month</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedSalaries.entities.map((salary, index) => {
            return (
              <Tr key={index}>
                <Td>{salary.id}</Td>
                <Td>{formatDate(salary.dateFrom)}</Td>
                <Td>{formatDate(salary.dateTo)}</Td>
                <Td>{salary.employeeName}</Td>
                <Td>{formatCurrency(salary.amount)}</Td>
                <Td isNumeric>
                  <Button
                    disabled={!salary.isEditEnabled}
                    colorScheme={salary.isEditEnabled ? "teal" : "gray"}
                    variant="ghost"
                    onClick={() => showEdit(salary)}
                  >
                    <FaPencilAlt />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedSalaries.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
