import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../../common/types";
import { Paged, PagedRequest } from "../../../common/models";
import { Contact, ContactCreate } from "../types";

export const getContacts = createAction<PagedRequest>("CONTACTS/GET_CONTACTS");

export const getContactsSuccess = createAction<Paged<Contact>>(
  "CONTACTS/GET_CONTACTS_SUCCESS"
);

export const getContactsError = createAction<PayloadError[]>(
  "CONTACTS/GET_CONTACTS_ERROR"
);

export const getContact = createAction<number>("CONTACTS/GET_CONTACT");

export const getContactSuccess = createAction<Contact>(
  "CONTACTS/GET_CONTACT_SUCCESS"
);

export const getContactError = createAction<PayloadError[]>(
  "CONTACTS/GET_CONTACT_ERROR"
);

export const updateContact = createAction<{
  contact: Contact;
  request: PagedRequest;
}>("CONTACTS/UPDATE_CONTACT");

export const updateContactSuccess = createAction(
  "CONTACTS/UPDATE_CONTACT_SUCCESS"
);

export const updateContactError = createAction<PayloadError[]>(
  "CONTACTS/UPDATE_CONTACT_ERROR"
);

export const createContact = createAction<{
  contact: ContactCreate;
  request: PagedRequest;
}>("CONTACTS/CREATE_CONTACT");

export const createContactSuccess = createAction<Contact>(
  "CONTACTS/CREATE_CONTACT_SUCCESS"
);

export const createContactError = createAction<PayloadError[]>(
  "CONTACTS/CREATE_CONTACT_ERROR"
);

export const resetSelectedContact = createAction(
  "CONTACTS/RESET_SELECTED_CONTACT"
);

export const resetContacts = createAction("CONTACTS/RESET");
