import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import React, { useEffect, useState } from "react";
import { PagedRequest } from "../../../common/models";
import { ProjectCreate, ProjectsRequest, ProjectUpdate } from "./types";
import {
  createProject,
  getProjects,
  resetProjects,
  updateProject,
} from "./store/actions";
import { Projects } from "./Projects";
import {
  getContractsDirectory,
  getTeamsDirectory,
} from "../../../store/actions/directories";

export const ProjectsContainer: React.FC = () => {
  // Pagination
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize: number = 5;

  const [selectedContractId, setSelectedContractId] = useState<
    number | undefined
  >();

  // Queries
  const projects = useSelector((state: RootStore) => state.projects.projects);
  const projectLoading = useSelector(
    (state: RootStore) => state.projects.loading
  );
  const contracts = useSelector(
    (state: RootStore) => state.directories.contracts.contracts
  );
  const teams = useSelector(
    (state: RootStore) => state.directories.teams.teams
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetProjects = (payload: ProjectsRequest) =>
    dispatch(getProjects(payload));
  const onGetTeamsDirectory = () => dispatch(getTeamsDirectory());
  const onGetContractsDirectory = () => dispatch(getContractsDirectory());
  const onUpdateProject = (project: ProjectUpdate) =>
    dispatch(
      updateProject({
        project,
        request: {
          offset: offset,
          take: pageSize,
          contractId: selectedContractId,
        },
      })
    );
  const onCreateProject = (project: ProjectCreate) =>
    dispatch(
      createProject({
        project,
        request: {
          offset: offset,
          take: pageSize,
          contractId: selectedContractId,
        },
      })
    );
  const onResetProjects = () => dispatch(resetProjects());

  useEffect(() => {
    onGetContractsDirectory();
    onGetTeamsDirectory();
    return () => {
      onResetProjects();
    };
  }, []);

  const offsetChanged = (offset: number) => {
    setOffset(offset);
    if (selectedContractId) {
      onGetProjects({
        offset: offset,
        take: pageSize,
        contractId: selectedContractId,
      });
    }
  };

  const onSelectedContractChanged = (contractId: number) => {
    setSelectedContractId(contractId);
    onGetProjects({
      offset: offset,
      take: pageSize,
      contractId,
    });
  };

  return (
    <Projects
      teams={teams}
      contracts={contracts}
      selectedContractChanged={onSelectedContractChanged}
      pagedProjects={projects}
      projectLoading={projectLoading}
      createProject={onCreateProject}
      updateProject={onUpdateProject}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};
