import React from "react";
import { Route } from "react-router";

import { ReportContainer } from "./Report/Report.container";
import { TransactionsContainer } from "./Transactions/Transactions.container";
import { UploadDataContainer } from "./UploadData/UploadData.container";

interface IPayoneerRoutingProps {
  match: any;
  nestedPath: string;
}

export const PayoneerRouting: React.FC<IPayoneerRoutingProps> = ({
  match,
  nestedPath,
}) => {
  return (
    <>
      <Route
        path={`${match.url}/${nestedPath}/report`}
        component={ReportContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/transactions`}
        component={TransactionsContainer}
      />
      <Route
        path={`${match.url}/${nestedPath}/upload-data`}
        component={UploadDataContainer}
      />
    </>
  );
};
