import { createSlice } from "@reduxjs/toolkit";
import { EmployeeVacation } from "../types";
import {
  checkEmployeeVacation,
  checkEmployeeVacationError,
  checkEmployeeVacationSuccess,
  createEmployeeVacation,
  createEmployeeVacationError,
  createEmployeeVacationSuccess,
  getEmployeeVacations,
  getEmployeeVacationsError,
  getEmployeeVacationsSuccess,
  resetEmployeeVacations,
  updateEmployeeVacation,
  updateEmployeeVacationError,
  updateEmployeeVacationSuccess,
} from "./actions";
import { Paged } from "../../../../../../common/models";
import { BaseState } from "../../../../../../common/models/common";

type InitialState = BaseState & {
  vacations: Paged<EmployeeVacation>;
  vacationDays: number | null;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  vacations: {
    total: 0,
    entities: [],
  },
  vacationDays: 0,
  loaded: false,
};

const employeeVacationsSlice = createSlice({
  name: "vacations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeVacations, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getEmployeeVacationsSuccess, (state, action) => {
        state.vacations = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getEmployeeVacationsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(checkEmployeeVacation, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(checkEmployeeVacationSuccess, (state, action) => {
        state.vacationDays = action.payload.days;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(checkEmployeeVacationError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateEmployeeVacation, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateEmployeeVacationSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateEmployeeVacationError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createEmployeeVacation, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createEmployeeVacationSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createEmployeeVacationError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetEmployeeVacations, (state) => {
        state.vacations.entities = [];
      });
  },
});

export default employeeVacationsSlice.reducer;
