import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getCitiesDirectory,
  getCitiesDirectoryError,
  getCitiesDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/cities/Par`;

function* workerGetCities(action: ReturnType<typeof getCitiesDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getCitiesDirectorySuccess(result.value.entities));
    } else {
      yield put(getCitiesDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(getCitiesDirectoryError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchCitiesDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getCitiesDirectory.type, workerGetCities);
}
