import { createSlice } from "@reduxjs/toolkit";
import { Contact } from "../types";
import { BaseState, Paged } from "../../../common/models/common";
import {
  createContact,
  createContactError,
  createContactSuccess,
  getContact,
  getContactError,
  getContacts,
  getContactsError,
  getContactsSuccess,
  getContactSuccess,
  resetContacts,
  resetSelectedContact,
  updateContact,
  updateContactError,
  updateContactSuccess,
} from "./actions";

type InitialState = BaseState & {
  contacts: Paged<Contact>;
  selectedContact: Contact | {};
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  contacts: {
    total: 0,
    entities: [],
  },
  selectedContact: {},
  loaded: false,
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContacts, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContactsSuccess, (state, action) => {
        state.contacts = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getContactsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(getContact, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContactSuccess, (state, action) => {
        state.selectedContact = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getContactError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateContact, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateContactSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateContactError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createContact, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createContactSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createContactError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetContacts, (state) => {
        state.contacts.entities = [];
      })
      .addCase(resetSelectedContact, (state, action) => {
        state.selectedContact = initialState.selectedContact;
      });
  },
});

export default contactsSlice.reducer;
