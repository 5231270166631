import { createAction } from "@reduxjs/toolkit";
import { User } from "../types";
import { PagedRequest, Paged } from "../../../common/models";
import { PayloadError } from "../../../common/types";

export const getUsers = createAction<PagedRequest>("USERS/GET_USERS");

export const getUsersSuccess = createAction<Paged<User>>(
  "USERS/GET_USERS_SUCCESS"
);

export const getUsersError = createAction<PayloadError[]>(
  "USERS/GET_USERS_ERROR"
);

export const updateUser = createAction<{
  user: User;
  request: PagedRequest;
}>("USERS/UPDATE_USER");

export const updateUserSuccess = createAction("USERS/UPDATE_USER_SUCCESS");

export const updateUserError = createAction<PayloadError[]>(
  "USERS/UPDATE_USER_ERROR"
);

export const createUser = createAction<{
  user: User;
  request: PagedRequest;
}>("USERS/CREATE_USER");

export const createUserSuccess = createAction<User>(
  "USERS/CREATE_USER_SUCCESS"
);

export const createUserError = createAction<PayloadError[]>(
  "USERS/CREATE_USER_ERROR"
);

export const resetUsers = createAction("USERS/RESET");
