import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Partner, PartnerCreate, PartnerUpdate } from "../types";
import { HookDatePicker, HookSelect } from "../../../../common/components/libs";
import { useForm } from "react-hook-form";
import { DirectoryItem } from "../../../../common/types";
import {
  AuthImage,
  FileInput,
  ImageCropModal,
} from "../../../../common/components";
import { Contact, ContactUpdate } from "../../../Contacts/types";
import { formatDate, parseStringToDate } from "../../../../common/utils";

interface IPartnerEditModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedPartner: Partner | null;
  handleUpdate: (partner: PartnerUpdate) => void;
  countries: DirectoryItem[];
  partnerTypes: DirectoryItem[];
  partnerIndustries: DirectoryItem[];
  contacts: DirectoryItem[];
  addressTypes: DirectoryItem[];
}

export const PartnerEditModal: React.FC<IPartnerEditModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  selectedPartner,
  handleUpdate,
  countries,
  partnerTypes,
  partnerIndustries,
  contacts,
  addressTypes,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isOpen: isOpenImageCrop,
    onOpen: onOpenImageCrop,
    onClose: onCloseImageCrop,
  } = useDisclosure();

  const handleSubmitUpdate = (values: PartnerUpdate) => {
    handleUpdate({
      ...values,
      id: selectedPartner ? selectedPartner.id : null,
      logoData: fileForUpload ? fileForUpload : null,
    });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      ...selectedPartner,
      avatarData: fileForUpload ? fileForUpload : null,
    });
  }, [selectedPartner]);

  const [fileForUpload, setFileForUpload] = useState<any>(null);
  const [fileForCropper, setFileForCropper] = useState<any>(null);

  const handleSetFileForCropper = (file: File) => {
    if (file) {
      setFileForCropper(URL.createObjectURL(file));
      onOpenImageCrop();
    }
  };

  const handleImageCropped = (image: string) => {
    setFileForUpload(image);
  };

  const handleClose = () => {
    setFileForCropper(null);
    setFileForUpload(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit partner</ModalHeader>
          <form onSubmit={handleSubmit(handleSubmitUpdate)}>
            <ModalBody>
              <Flex>
                <Box w={"30%"}>
                  <Box pr={6}>
                    <Box mb={6}>
                      {fileForUpload ? (
                        <Avatar size="3xl" src={fileForUpload} />
                      ) : selectedPartner && selectedPartner.logoStorageHash ? (
                        <AuthImage
                          url={
                            `/api/storage/images/partner-logo/` +
                            selectedPartner.logoStorageHash
                          }
                          childComponent={<Avatar size="3xl" />}
                        />
                      ) : (
                        <Avatar size="3xl" />
                      )}
                    </Box>

                    <FileInput
                      register={register}
                      name={"avatarInput"}
                      fileChanged={(file) => handleSetFileForCropper(file)}
                    />
                  </Box>
                </Box>
                <Box w={"70%"}>
                  <Flex mb={3}>
                    <FormControl pr={3} isInvalid={errors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input {...register("name", { required: true })} />
                      <FormErrorMessage>
                        {errors.name ? "Name is required" : ""}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl pl={3} isInvalid={errors.responsibleContactId}>
                      <FormLabel>Responsible contact</FormLabel>
                      <HookSelect
                        control={control}
                        name={"responsibleContactId"}
                        options={contacts}
                        rules={{ required: true }}
                      />
                      <FormErrorMessage>
                        {errors.responsibleContactId
                          ? "Responsible contact is required"
                          : ""}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Email</FormLabel>
                      <Input type={"email"} {...register("email")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Phone</FormLabel>
                      <Input {...register("phone")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Additional phone</FormLabel>
                      <Input {...register("additionalPhone")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Fax</FormLabel>
                      <Input {...register("fax")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Address</FormLabel>
                      <Input {...register("address")} />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Zip code</FormLabel>
                      <Input {...register("zipCode")} />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Address type</FormLabel>
                      <HookSelect
                        control={control}
                        name={"addressTypeId"}
                        options={addressTypes}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Country</FormLabel>
                      <HookSelect
                        control={control}
                        name={"countryId"}
                        options={countries}
                      />
                    </FormControl>
                  </Flex>
                  <Flex mb={3}>
                    <FormControl pr={3}>
                      <FormLabel>Type</FormLabel>
                      <HookSelect
                        control={control}
                        name={"partnerTypeId"}
                        options={partnerTypes}
                      />
                    </FormControl>
                    <FormControl pl={3}>
                      <FormLabel>Industry</FormLabel>
                      <HookSelect
                        control={control}
                        name={"partnerIndustryId"}
                        options={partnerIndustries}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button type={"submit"} mr={3}>
                Update
              </Button>
              <Button colorScheme="gray" onClick={handleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <ImageCropModal
        handleImageCropped={handleImageCropped}
        sourceImage={fileForCropper}
        isOpen={isOpenImageCrop}
        onOpen={onOpenImageCrop}
        onClose={onCloseImageCrop}
      />
    </>
  );
};
