import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export const LazyLoadingSpinner: React.FC = () => {
  return (
    <Flex w="100%">
      <Flex
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Spinner size="xl" />
      </Flex>
    </Flex>
  );
};
