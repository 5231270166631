import { DirectoryItem } from "../../../../common/types";

export const CONTRACT_TYPE = {
  Fixed: 1,
  Hourly: 2,
};

export const CONTRACT_TYPE_OPTIONS: DirectoryItem[] = [
  { label: "Fixed", value: 1 },
  { label: "Hourly", value: 2 },
];
