import React, { useState } from "react";
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Team, TeamCreate, TeamUpdate } from "./types";
import { TeamEditModal, TeamsTable, TeamCreateModal } from "./components";
import { Paged } from "../../../common/models";
import { DirectoryItem } from "../../../common/types";

const CFaPlus = chakra(FaPlus);

interface ITeamsProps {
  pagedTeams: Paged<Team>;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  createTeam: (team: TeamCreate) => void;
  updateTeam: (team: TeamUpdate) => void;
  teamLoading: boolean;
  employees: DirectoryItem[];
}

export const Teams: React.FC<ITeamsProps> = ({
  pagedTeams,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  createTeam,
  updateTeam,
  teamLoading,
  employees,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const {
    isOpen: isOpenTeamEdit,
    onOpen: onOpenTeamEdit,
    onClose: onCloseTeamEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenTeamCreate,
    onOpen: onOpenTeamCreate,
    onClose: onCloseTeamCreate,
  } = useDisclosure();

  const handleOpenEdit = (team: Team) => {
    setSelectedTeam(team);
    onOpenTeamEdit();
  };

  return (
    <>
      <Flex mb={3}>
        <Heading size={"lg"}>Teams</Heading>
        <Spacer />
        <Box>
          <Button colorScheme="teal" onClick={() => onOpenTeamCreate()}>
            <CFaPlus mr={2} /> Add team
          </Button>
        </Box>
      </Flex>

      <TeamsTable
        pagedTeams={pagedTeams}
        currentPage={currentPage}
        offsetChanged={offsetChanged}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        showEdit={handleOpenEdit}
      />

      <TeamCreateModal
        employees={employees}
        handleCreate={createTeam}
        isOpen={isOpenTeamCreate}
        onOpen={onOpenTeamCreate}
        onClose={onCloseTeamCreate}
      />

      <TeamEditModal
        employees={employees}
        handleUpdate={updateTeam}
        selectedTeam={selectedTeam}
        isOpen={isOpenTeamEdit}
        onOpen={onOpenTeamEdit}
        onClose={onCloseTeamEdit}
      />
    </>
  );
};
