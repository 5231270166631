import { EmployeeSnapshots } from "./EmployeeSnapshots";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import React, { useEffect, useState } from "react";
import {
  getEmployeeUpworkSnapshots,
  resetEmployeeUpworkSnapshots,
} from "./store/actions";
import {
  UpworkEmployeeSnapshotsFilters,
  UpworkEmployeeSnapshotsRequest,
} from "./types";

export const EmployeeSnapshotsContainer: React.FC = () => {
  const [filters, setFilters] = useState<UpworkEmployeeSnapshotsFilters>({
    day: "",
  });

  // Queries
  const employeeSnapshots = useSelector(
    (state: RootStore) => state.employee.snapshots.employeeSnapshots
  );
  const employeeSnapshotsLoading = useSelector(
    (state: RootStore) => state.employee.snapshots.loading
  );
  const employeeProfile = useSelector(
    (state: RootStore) => state.employee.profile.profile
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetEmployeeUpworkSnapshots = (
    payload: UpworkEmployeeSnapshotsRequest
  ) => dispatch(getEmployeeUpworkSnapshots(payload));

  const onResetEmployeeSnapshots = () =>
    dispatch(resetEmployeeUpworkSnapshots());

  const filtersChanged = (filters: UpworkEmployeeSnapshotsFilters) => {
    setFilters(filters);
    onGetEmployeeUpworkSnapshots({
      employeeContactId: employeeProfile.employee.contactId,
      day: filters.day,
    });
  };

  // useEffect(() => {
  //   return () => {
  //     onResetEmployeeSnapshots();
  //   };
  // });

  return (
    <EmployeeSnapshots
      employeeSnapshots={employeeSnapshots}
      employeeSnapshotsLoading={employeeSnapshotsLoading}
      filtersChanged={filtersChanged}
    />
  );
};
