import { createSlice } from "@reduxjs/toolkit";
import { Contract } from "../types";
import { BaseState, Paged } from "../../../../common/models/common";
import {
  createContract,
  createContractError,
  createContractSuccess,
  getContracts,
  getContractsError,
  getContractsSuccess,
  resetContracts,
  updateContract,
  updateContractError,
  updateContractSuccess,
} from "./actions";

type InitialState = BaseState & {
  contracts: Paged<Contract>;
};

const initialState: InitialState = {
  loading: false,
  errors: [],
  contracts: {
    total: 0,
    entities: [],
  },
  loaded: false,
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContracts, (state) => {
        state.errors = [];
        state.loading = true;
        state.loaded = false;
      })
      .addCase(getContractsSuccess, (state, action) => {
        state.contracts = action.payload;
        state.loading = false;
        state.loaded = true;
      })
      .addCase(getContractsError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(updateContract, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(updateContractSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(updateContractError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(createContract, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(createContractSuccess, (state, action) => {
        state.loading = false;
        state.loaded = true;
      })
      .addCase(createContractError, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
        state.loaded = false;
      })
      .addCase(resetContracts, (state) => {
        state.contracts.entities = [];
      });
  },
});

export default contractsSlice.reducer;
