import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getPartnersDirectory = createAction("DIRECTORIES/GET_PARTNERS");

export const getPartnersDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_PARTNERS_SUCCESS"
);

export const getPartnersDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_PARTNERS_ERROR"
);
