import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  getContractsDirectory,
  getContractsDirectoryError,
  getContractsDirectorySuccess,
} from "../../actions/directories";
import { ApiResponse } from "../../../common/models/common";
import { DirectoryItem } from "../../../common/types";

const apiUrl = `/api/directories/contracts`;

function* workerGetContracts(action: ReturnType<typeof getContractsDirectory>) {
  try {
    const result: ApiResponse<{ entities: DirectoryItem[] }> = yield call(
      API.get,
      apiUrl,
      action.payload
    );
    if (result.success) {
      yield put(getContractsDirectorySuccess(result.value.entities));
    } else {
      yield put(getContractsDirectoryError(result.errors));
    }
  } catch (e) {
    yield put(
      getContractsDirectoryError([{ key: "", errorMessage: e.message }])
    );
  }
}

export default function* watchContractsDirectorySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContractsDirectory.type, workerGetContracts);
}
