import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import API from "../../../common/api/api.base";
import {
  createContact,
  createContactError,
  createContactSuccess,
  getContact,
  getContactError,
  getContacts,
  getContactsError,
  getContactsSuccess,
  getContactSuccess,
  updateContact,
  updateContactError,
  updateContactSuccess,
} from "./actions";
import { ApiResponse, Paged } from "../../../common/models/common";
import { Contact } from "../types";

function* workerGetContacts(action: ReturnType<typeof getContacts>) {
  try {
    const result: ApiResponse<Paged<Contact>> = yield call(
      API.get,
      `/api/contacts/`,
      action.payload
    );
    if (result.success) {
      yield put(getContactsSuccess(result.value));
    } else {
      yield put(getContactsError(result.errors));
    }
  } catch (e) {
    yield put(getContactsError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerGetContact(action: ReturnType<typeof getContact>) {
  try {
    const result: ApiResponse<Contact> = yield call(
      API.get,
      `/api/contacts/${action.payload}`
    );
    if (result.success) {
      yield put(getContactSuccess(result.value));
    } else {
      yield put(getContactError(result.errors));
    }
  } catch (e) {
    yield put(getContactError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerUpdateContact(action: ReturnType<typeof updateContact>) {
  try {
    const result: ApiResponse<Contact> = yield call(
      API.put,
      `/api/contacts`,
      action.payload.contact
    );
    if (result.success) {
      yield put(updateContactSuccess());
      yield put(getContacts(action.payload.request));
      yield put(getContact(action.payload.contact.id));
    } else {
      yield put(updateContactError(result.errors));
    }
  } catch (e) {
    yield put(getContactError([{ key: "", errorMessage: e.message }]));
  }
}

function* workerCreateContact(action: ReturnType<typeof createContact>) {
  try {
    const result: ApiResponse<Contact> = yield call(
      API.post,
      `/api/contacts`,
      action.payload.contact
    );
    if (result.success) {
      yield put(createContactSuccess(result.value));
      // Call update contacts list
      yield put(getContacts(action.payload.request));
    } else {
      yield put(createContactError(result.errors));
    }
  } catch (e) {
    yield put(createContactError([{ key: "", errorMessage: e.message }]));
  }
}

export default function* watchContactsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getContacts.type, workerGetContacts);
  yield takeEvery(getContact.type, workerGetContact);
  yield takeEvery(createContact.type, workerCreateContact);
  yield takeEvery(updateContact.type, workerUpdateContact);
}
