import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import { FaEye, FaPencilAlt, FaTrash } from "react-icons/fa";
import { Contact } from "../types";
import { Paged } from "../../../common/models";
import { AuthImage, SharedPagination } from "../../../common/components";

interface IContactsListProps {
  pagedContacts: Paged<Contact>;
  showEdit: (contact: Contact) => void;
  showView: (contact: Contact) => void;
  pageSize: number;
  offsetChanged: (offset: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const ContactsTable: React.FC<IContactsListProps> = ({
  pagedContacts,
  showEdit,
  showView,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Table>
        <Thead>
          <Tr variant={"solid"}>
            <Th>
              <Center>Avatar</Center>
            </Th>
            <Th>Full Name</Th>
            <Th>Phone</Th>
            <Th>Email</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pagedContacts.entities.map((contact, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Center>
                    {contact.avatarStorageHash ? (
                      <AuthImage
                        url={
                          `/api/storage/images/contact-avatar/` +
                          contact.avatarStorageHash
                        }
                        childComponent={
                          <Avatar
                            size={"lg"}
                            style={{ cursor: "pointer" }}
                            onClick={() => showView(contact)}
                          />
                        }
                      />
                    ) : (
                      <Avatar
                        size={"lg"}
                        style={{ cursor: "pointer" }}
                        onClick={() => showView(contact)}
                      />
                    )}
                  </Center>
                </Td>
                <Td>
                  {contact.firstName} {contact.lastName}
                </Td>
                <Td>{contact.phone}</Td>
                <Td>{contact.email}</Td>
                <Td isNumeric>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showEdit(contact)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    colorScheme="teal"
                    variant="ghost"
                    onClick={() => showView(contact)}
                  >
                    <FaEye />
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <SharedPagination
          totalRecords={pagedContacts.total}
          pageLimit={pageSize}
          pageNeighbours={2}
          setOffset={offsetChanged}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Table>
    </Box>
  );
};
