import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getCountriesDirectory = createAction("DIRECTORIES/GET_COUNTRIES");

export const getCountriesDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_COUNTRIES_SUCCESS"
);

export const getCountriesDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_COUNTRIES_ERROR"
);
