import { createAction } from "@reduxjs/toolkit";
import { DirectoryItem, PayloadError } from "../../../common/types";

export const getSalaryPaymentsDirectory = createAction<number>(
  "DIRECTORIES/GET_SALARY_PAYMENTS"
);

export const getSalaryPaymentsDirectorySuccess = createAction<DirectoryItem[]>(
  "DIRECTORIES/GET_SALARY_PAYMENTS_SUCCESS"
);

export const getSalaryPaymentsDirectoryError = createAction<PayloadError[]>(
  "DIRECTORIES/GET_SALARY_PAYMENTS_ERROR"
);
