import React from "react";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { PayoneerReport } from "../types";
import { formatCurrency } from "../../../../common/utils";

interface IReportTotalProps {
  aragornTotal: number;
  gandalfTotal: number;
  aragornOwesGandalf: number;
  gandalfOwesAragorn: number;
}

export const ReportTotal: React.FC<IReportTotalProps> = ({
  aragornTotal,
  gandalfTotal,
  aragornOwesGandalf,
  gandalfOwesAragorn,
}) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
      <Box mb={4}>
        <Heading size="md" mb={2}>
          Total
        </Heading>
        <Divider />
      </Box>

      <Box>
        <StatGroup>
          <Stat m={1} p={2}>
            <StatLabel>Aragon Total</StatLabel>
            <StatNumber>{formatCurrency(aragornTotal)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Gandalf Total</StatLabel>
            <StatNumber>{formatCurrency(gandalfTotal)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Aragorn owes Gandalf</StatLabel>
            <StatNumber>{formatCurrency(aragornOwesGandalf)}</StatNumber>
          </Stat>

          <Stat m={1} p={2}>
            <StatLabel>Gandalf owes Aragorn</StatLabel>
            <StatNumber>{formatCurrency(gandalfOwesAragorn)}</StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </Box>
  );
};
