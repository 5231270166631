import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import React, { useEffect } from "react";
import {
  getUserProfile,
  updateUserProfile,
} from "../../store/actions/profile/profile.actions";
import { UserProfileUpdate } from "../../common/types";
import { Profile } from "./Profile";
import {
  connectUpworkAccount,
  disconnectUpworkAccount,
} from "../../store/actions/auth";
import API from "../../common/api/api.base";

export const ProfileContainer: React.FC = () => {
  // Queries
  const profile = useSelector((state: RootStore) => state.userProfile.profile);
  const profileLoading = useSelector(
    (state: RootStore) => state.userProfile.loading
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetProfile = () => dispatch(getUserProfile());
  const onUpdateProfile = (profile: UserProfileUpdate) =>
    dispatch(updateUserProfile(profile));
  const onConnectUpworkAccount = (payload: any) =>
    dispatch(connectUpworkAccount(payload));
  const onDisconnectUpworkAccount = () => dispatch(disconnectUpworkAccount());

  useEffect(() => {
    onGetProfile();
  }, []);

  async function onGetUpworkLink() {
    const result = await API.get("/api/upwork/auth");
    window.open(result.value, "_blank");
  }

  return (
    <Profile
      profile={profile}
      profileLoading={profileLoading}
      updateProfile={onUpdateProfile}
      getUpworkLink={onGetUpworkLink}
      connectUpworkAccount={onConnectUpworkAccount}
      disconnectUpworkAccount={onDisconnectUpworkAccount}
    />
  );
};
