import React from "react";
import { Route } from "react-router";
import { ContactUsLeadsContainer } from "./ContactUsLeads/ContactUsLeads.container";

interface ILeadsRoutingProps {
  match: any;
  nestedPath: string;
}

export const LeadsRouting: React.FC<ILeadsRoutingProps> = ({
  match,
  nestedPath,
}) => {
  return (
    <>
      <Route
        path={`${match.url}/${nestedPath}/contact-us`}
        component={ContactUsLeadsContainer}
      />
    </>
  );
};
